import React, { useState, Fragment } from 'react';
import slugify from 'react-slugify';
import { Ballot, BallotSet } from './ballot.js';
import { formatNumber } from './utils';

class SimpleScenario {
  constructor({ name, notes, ballotSet }) {
    this.name = name;
    this.slug = slugify(name);
    this.notes = notes;
    this.ballotSet = ballotSet;
  }

  copy() {
    return new SimpleScenario({
      name: this.name,
      notes: this.notes,
      ballotSet: this.ballotSet.copy(),
    });
  }
}

class PairwiseAffinityByRoundScenario {
  constructor({
    name,
    notes,
    firstRankVotes, // A map of candidate-to-votes for the first round
    pairwiseAffinity, // A list of maps of candidate-to-candidate affinity values (see generateBallots)
    maxRankings = 4, // The number of ranked candidates should be small, to prevent combinatoric explosions
    pairwiseAffinityForm = PairwiseAffinityForm,
    firstRankVotesForm = FirstRankVotesForm,
  }) {
    this.name = name;
    this.slug = slugify(name);
    this.notes = notes;
    this.pairwiseAffinityForm = pairwiseAffinityForm;
    this.firstRankVotesForm = firstRankVotesForm;

    this.firstRankVotes = firstRankVotes;
    this.pairwiseAffinity = pairwiseAffinity;
    this.maxRankings = maxRankings;
  }

  copy() {
    return new PairwiseAffinityByRoundScenario({
      name: this.name,
      notes: this.notes,
      firstRankVotes: { ...this.firstRankVotes },
      pairwiseAffinity: JSON.parse(JSON.stringify(this.pairwiseAffinity)),
      maxRankings: this.maxRankings,
      pairwiseAffinityForm: this.pairwiseAffinityForm,
      firstRankVotesForm: this.firstRankVotesForm,
    });
  }

  get ballotSet() {
    return this.generateBallotSet();
  }

  _get_affinity_for_round(round) {
    // Get the affinity values for a specific round
    if (round >= this.pairwiseAffinity.length) {
      return this.pairwiseAffinity[this.pairwiseAffinity.length - 1];
    }
    return this.pairwiseAffinity[round];
  }

  generateBallotSet() {
    // Generate ballots based on the pairwise affinity dict
    // The pairwise affinity dict should be a dictionary of dictionaries, eg:
    // {
    //   'A': {
    //     'A': 0.03,  // Exhaustion percentage
    //     'B': 0.6,
    //     'C': 0.36,
    //     'D': 0.01,
    //   },
    //   'B': {
    //     'A': 0.25,
    //     'B': 0.01,
    //     'C': 0.54,
    //     'D': 0.2,
    //   },
    //   'C': {
    //     'A': 0.4,
    //     'B': 0.399,
    //     'C': 0.2,
    //     'D': 0.001,
    //   },
    //   'D': {
    //     'A': 0.4,
    //     'B': 0.3,
    //     'C': 0.3,
    //     'D': 0.0,
    //   },
    // }
    // The affinity values should be between 0 and 1, and the sum of the affinities for each candidate should be 1.
    //
    // For each candidate, we will generate a number of ballots based on the affinity values and firstRankVotes.

    const ballotSet = new BallotSet([]);
    const firstRoundAffinity = this._get_affinity_for_round(0);
    Object.entries(firstRoundAffinity).map(([candidate, affinityMap]) => {
      const totalVotes = this.firstRankVotes[candidate];
      for (const ballot of this.makeBallots(
        candidate,
        totalVotes,
        affinityMap,
        [],
        this.maxRankings - 1
      )) {
        ballotSet.add(ballot);
      }
    });
    console.log(ballotSet.firstRankVotes);
    return ballotSet;
  }

  makeBallots(fromCandidate, totalVotes, affinityMap, exclusions, level) {
    // Generate a ballot for a candidate based on the affinity map
    // This will recurse until level == 0 (to prevent combinatoric explosion), or there are no more candidates
    const ballots = [];
    if (level < 0) {
      return ballots;
    }
    let totalCounted = 0;
    const exc = [...exclusions, fromCandidate];
    // Sum the affinity values to make sure they sum to 1. If they don't, then normalize them
    const normalizer = Object.values(affinityMap).reduce((a, b) => a + b, 0);

    Object.entries(affinityMap).map(([candidate, value]) => {
      const votes = Math.round((totalVotes * value) / normalizer);
      if (votes > 0) {
        if (fromCandidate === candidate) {
          // Ballots are exhausted, don't recurse
          ballots.push(new Ballot(votes, [candidate]));
          totalCounted += votes;
        } else {
          // recurse
          // Exclude the current candidate, and previously seen candidates, from the next level
          const affinities = Object.fromEntries(
            Object.entries(
              this._get_affinity_for_round(this.maxRankings - level)[candidate]
            ).filter(([c, v]) => exc.indexOf(c) === -1)
          );
          const subBallots = this.makeBallots(candidate, votes, affinities, exc, level - 1);
          for (const subBallot of subBallots) {
            subBallot.rankings.unshift(fromCandidate);
            totalCounted += subBallot.count;
          }
          ballots.push(...subBallots);
        }
      }
    });
    // If we didn't account for all the votes, add the remainder to the current candidate to exhaust their votes
    // Alternate solution: normalize the affinity values to sum to 1 (ie: when candidates are removed, the affinities should be adjusted accordingly)
    if (totalCounted < totalVotes) {
      ballots.push(new Ballot(totalVotes - totalCounted, [fromCandidate]));
    }
    return ballots;
  }
}

class PairwiseAffinityScenario extends PairwiseAffinityByRoundScenario {
  constructor(props) {
    props.pairwiseAffinity = props.pairwiseAffinity;
    super(props);
  }

  _get_affinity_for_round(round) {
    // Get the affinity values for a specific round
    return this.pairwiseAffinity;
  }

  copy() {
    return new PairwiseAffinityScenario({
      name: this.name,
      notes: this.notes,
      firstRankVotes: { ...this.firstRankVotes },
      pairwiseAffinity: { ...this.pairwiseAffinity },
      maxRankings: this.maxRankings,
      pairwiseAffinityForm: this.pairwiseAffinityForm,
      firstRankVotesForm: this.firstRankVotesForm,
    });
  }
}

const PairwiseAffinityForm = ({ pairwiseAffinity, onChange }) => {
  const handleChangeAffinity = (fromCandidate, toCandidate, event) => {
    let pw = { ...pairwiseAffinity };
    pw[fromCandidate][toCandidate] = parseFloat(event.target.value) / 100;
    onChange(pw);
  };

  const stringifyAffinity = () => {
    // Convert the affinity matrix to a string, eg:
    // {c:['A','B','C',D'],a:{'A':'0.1,0.2,0.3,0.4','B':'0.2,0.3,0.4,0.1','C':'0.3,0.4,0.1,0.2','D':'0.4,0.1,0.2,0.3'}}
    const candidates = Object.keys(pairwiseAffinity);
    const affinityString = candidates.map((candidate) =>
      candidates.map((c) => pairwiseAffinity[candidate][c].toFixed(2)).join(',')
    );
    return JSON.stringify({
      c: candidates,
      a: Object.fromEntries(candidates.map((c, i) => [c, affinityString[i]])),
    });
  };

  return (
    <form>
      <h3>Affinities</h3>
      <table className="w-full">
        <thead>
          <tr>
            <th></th>
            {Object.keys(pairwiseAffinity).map((candidate, index) => (
              <th key={index}>{candidate}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(pairwiseAffinity).map((candidateRow, index) => (
            <tr key={index}>
              <td>{candidateRow}</td>
              {Object.keys(pairwiseAffinity).map((candidateCol, index) => (
                <td key={index}>
                  <input
                    type="number"
                    min="0"
                    max="100"
                    onChange={(event) => handleChangeAffinity(candidateRow, candidateCol, event)}
                    value={`${(pairwiseAffinity[candidateRow][candidateCol] * 100).toFixed(2)}`}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </form>
  );
};

const FirstRankVotesForm = ({ firstRankVotes, onChange }) => {
  const handleChangeVotes = (candidate, event) => {
    const votes = parseInt(event.target.value);
    firstRankVotes[candidate] = votes;
    onChange(firstRankVotes);
  };

  return (
    <form>
      <h3>First Rank Votes</h3>
      <table className="w-full">
        <thead>
          <tr>
            <th>Candidate</th>
            <th>Votes</th>
          </tr>
        </thead>
        <tbody>
          {firstRankVotes &&
            Object.keys(firstRankVotes).map((candidate, index) => (
              <tr key={index}>
                <td>{candidate}</td>
                <td>
                  <input
                    type="number"
                    min="0"
                    onChange={(event) => handleChangeVotes(candidate, event)}
                    value={firstRankVotes[candidate]}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </form>
  );
};

const ScenarioSelectionForm = ({ scenarios, scenario, onChange, showBallots = false }) => {
  const [selectedScenario, setSelectedScenario] = useState(scenario);

  const setScenario = (scenario) => {
    setSelectedScenario(scenario);
    onChange(scenario.copy());
  };

  return (
    <div>
      {scenarios.map((scenario, index) => (
        <Fragment>
          <button
            key={index}
            className="rounded-sm p-2 border border-transparent flex flex-col gap-2 text-left hover:border hover:border-brand-blue-3"
            onClick={() => {
              setScenario(scenario);
            }}
          >
            <div className="w-fit transition-all hover:scale-105 bg-gradient-to-r bg-size-200 bg-pos-0 hover:bg-pos-100 inline-block font-bold p-3 py-2 rounded-md break-inside-avoid text-white from-brand-blue-4 via-brand-blue-4 to-brand-green-3 focus:bg-transparent focus:bg-gradient-to-r hover:scale-110 ">
              {scenario.name}
            </div>
            <small>{scenario.notes}</small>
          </button>
        </Fragment>
      ))}
      {showBallots && (
        <Fragment>
          <h3>Ballots:</h3>
          <ul className="!p-1 max-h-64 overflow-y-scroll">
            {selectedScenario.ballotSet.ballots.map((ballot, index) => (
              <li className="flex flex-row gap-2 px-1" key={index}>
                {formatNumber(ballot.count)} ballots: {ballot.rankingString}
              </li>
            ))}
          </ul>
        </Fragment>
      )}
    </div>
  );
};

const PairwiseAffinityTableRenderer = ({ scenario, round, showDiagonal = true }) => {
  const cellColor = (affinity) => {
    return `rgba(100, 208, 156, ${affinity})`;
  };

  const exhaustedCellColor = (affinity) => {
    return `rgba(255, 128, 128, ${affinity})`;
  };

  const pairwiseAffinity = scenario._get_affinity_for_round(round);

  // Set diagonal to 0
  function zeroDiagonal(matrix) {
    const newMatrix = JSON.parse(JSON.stringify(matrix)); // Make a deep copy of the matrix
    for (const key in newMatrix) {
      if (newMatrix[key][key] !== undefined) {
        // Check if the diagonal element exists
        newMatrix[key][key] = 0; // Set the diagonal element to zero
      }
    }
    return newMatrix;
  }

  const pairwiseAffinityNoDiagonal = zeroDiagonal(pairwiseAffinity);
  const maxAffinity = Math.max(
    ...Object.values(pairwiseAffinityNoDiagonal).map((row) => Math.max(...Object.values(row)))
  );
  const minAffinity = Math.min(
    ...Object.values(pairwiseAffinityNoDiagonal).map((row) => Math.min(...Object.values(row)))
  );
  const scale = (affinity) => (affinity - minAffinity) / (maxAffinity - minAffinity);

  const [hoveredRow, setHoveredRow] = useState(null);

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th colSpan={Object.keys(pairwiseAffinity).length} className="text-center">
            Second Choice
          </th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          {Object.keys(pairwiseAffinity).map((candidate, index) => (
            <th className="text-left pl-2" key={index}>
              {candidate}
            </th>
          ))}
          {!showDiagonal && <th className="text-left pl-2">No 2nd Choice</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th
            className="text-center font-bold !opacity-100 -rotate-90 !text-nowrap overflow-visible w-4 h-4"
            rowSpan={Object.keys(pairwiseAffinity).length + 1}
          >
            First Choice
          </th>
        </tr>
        {Object.keys(pairwiseAffinity).map((candidateRow, index) => (
          <tr
            key={index}
            onMouseEnter={() => setHoveredRow(index)}
            onMouseLeave={() => setHoveredRow(null)}
            className={`transition-opacity duration-100 ${
              hoveredRow !== null && hoveredRow !== index ? 'opacity-25' : 'opacity-100'
            }`}
          >
            <td className="!pl-2 font-bold">{candidateRow}</td>
            {Object.keys(pairwiseAffinity).map((candidateCol, index) => (
              <td
                key={index}
                style={{
                  backgroundColor:
                    candidateRow == candidateCol
                      ? showDiagonal
                        ? exhaustedCellColor(pairwiseAffinity[candidateRow][candidateCol])
                        : 'bg-white'
                      : cellColor(scale(pairwiseAffinity[candidateRow][candidateCol])),
                }}
              >
                {!showDiagonal && candidateRow == candidateCol
                  ? '-'
                  : `${Math.round(pairwiseAffinity[candidateRow][candidateCol] * 100)}%`}
              </td>
            ))}
            {!showDiagonal && (
              <td
                style={{
                  backgroundColor: exhaustedCellColor(pairwiseAffinity[candidateRow][candidateRow]),
                }}
                className="text-left pl-2"
              >
                {' '}
                {`${Math.round(pairwiseAffinity[candidateRow][candidateRow] * 100)}%`}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export {
  SimpleScenario,
  PairwiseAffinityByRoundScenario,
  PairwiseAffinityScenario,
  ScenarioSelectionForm,
  FirstRankVotesForm,
  PairwiseAffinityForm,
  PairwiseAffinityTableRenderer,
};

import React, { Fragment, useState, memo } from 'react';
import { H3 } from '../layout/headings';
import { YesIcon, NoIcon } from '../helpers';
import { StickyContainer, TopToLink } from './candidate_info';
import { ENDORSEMENT, isYes, isNo } from './constants.js';

export const MeasureHeader = memo(({ hide, measure, base = '', infoOnly = false, lang = 'en' }) => {
  const emoji = (endorsement) => {
    if (isYes(endorsement)) {
      return <YesIcon />;
    }
    if (isNo(endorsement)) {
      return <NoIcon />;
    }
    return '🤷🏾‍♀️';
  };

  const topLink = base + '#' + measure.jurisdiction.toLowerCase() + '-measures';

  let endorsement = measure.endorsement({ lang: lang });
  if (lang == 'en') {
    endorsement += ' on ';
  }
  endorsement = (
    <Fragment>
      {emoji(measure.endorsement({ lang: lang }))} {endorsement}
    </Fragment>
  );
  let label = '';
  if (lang == 'zh') {
    label += '提案' + measure.label;
  } else {
    label += 'Proposition ' + measure.label;
  }
  const anchor = ('prop-' + measure.label).toLowerCase();
  return (
    <>
      <div className="flex justify-between items-center gap-2.5 lg:gap-8">
        <H3
          hide={hide}
          key={measure.name}
          idOverride={anchor}
          className="!m-0 font-black sticky-heading"
        >
          {!infoOnly && endorsement}
          {label}
        </H3>
        <span className="hidden lg:block">
          <TopToLink topLink={topLink} />
        </span>
      </div>
      <p className="text-gray-500 !m-0 capitalize text-sm font-medium leading-7 mt-2">
        {measure.names[lang] || measure.name}
      </p>
    </>
  );
});

export const MeasureInfo = memo(
  ({ hide = false, measure, children, base = '', infoOnly = false, lang = 'en', ...props }) => {
    return (
      <StickyContainer content={children}>
        <MeasureHeader hide={hide} measure={measure} base={base} infoOnly={infoOnly} lang={lang} />
      </StickyContainer>
    );
  }
);

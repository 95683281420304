import React, { useState } from 'react';

class Ballot {
  constructor(count, rankings) {
    this._count = count;
    this._rankings = rankings;
    this._id = null;
  }

  get count() {
    return this._count;
  }

  set count(count) {
    this._count = count;
    this._id = null;
  }

  get rankingString() {
    // Include the rank number before each candidate
    return this.rankings.map((candidate, index) => `${index + 1}. ${candidate}`).join(', ');
  }

  get rankings() {
    return this._rankings;
  }

  set rankings(rankings) {
    this._rankings = rankings;
    this._id = null;
  }

  get id() {
    if (!this._id) {
      this._id = this._serialize();
    }
    return this._id;
  }

  copy() {
    return new Ballot(this.count, [...this.rankings]);
  }

  _serialize() {
    // Placeholder hash generation logic
    return `${this.count}:${this.rankings.join(',')}`;
  }
}

class BallotSet {
  constructor(ballots, undervotes = 0) {
    this.ballots = ballots;
    this._memoizedFirstRankVotes = null;
    this.undervotes = undervotes;
  }

  /**
   * @param {list[Ballot]} ballots
   */
  set ballots(ballots) {
    // TODO: Transform ballots from a list of Ballots to a mapping of rankingKey -> Ballot
    this._ballots = ballots;
    this._memoizedFirstRankVotes = null;
  }

  get ballots() {
    return this._ballots;
  }

  add = (ballot) => {
    // Add a ballot to the set, combining ballots with the same rankings
    const newKey = ballot.rankings.join(',');
    for (const blt of this.ballots) {
      const key = blt.rankings.join(',');
      if (key === newKey) {
        blt.count += ballot.count;
        this._memoizedFirstRankVotes = null;
        return;
      }
    }
    this._ballots.push(ballot);
    this._memoizedFirstRankVotes = null;
  };

  remove = (ballot) => {
    if (this._memoizedFirstRankVotes) {
      this._memoizedFirstRankVotes[ballot.rankings[0]] -= ballot.count;
    }
    for (const blt of this.ballots) {
      console.log('blt: ', blt, ' id: ', blt.id);
    }
    this._ballots = this._ballots.filter((blt) => blt.id !== ballot.id);
  };

  get firstRankVotes() {
    if (this._memoizedFirstRankVotes) {
      return this._memoizedFirstRankVotes;
    }

    // Calculate the first rank votes for the current round
    const results = {};
    for (const ballot of this.ballots) {
      const candidate = ballot.rankings[0];
      if (candidate in results) {
        results[candidate] += ballot.count;
      } else {
        results[candidate] = ballot.count;
      }
    }

    this._memoizedFirstRankVotes = results;
    return results;
  }

  get candidates() {
    return Object.keys(this.firstRankVotes);
  }

  get totalVotes() {
    return Object.values(this.firstRankVotes).reduce((acc, count) => acc + count, 0);
  }

  copy() {
    return new BallotSet(
      this.ballots.map((ballot) => ballot.copy()),
      this.undervotes
    );
  }
}

const BallotForm = ({ onChange }) => {
  const [ballotCount, setBallotCount] = useState(0);
  const [rankings, setRankings] = useState('');

  const handleAddBallot = (event) => {
    event.preventDefault();
    const count = parseInt(ballotCount, 10) || 1;
    const rankingsArray = rankings
      .split(',')
      .map((s) => s.trim())
      .filter(Boolean);
    const ballot = new Ballot(count, rankingsArray);
    onChange(ballot);
  };

  return (
    <form onSubmit={handleAddBallot} className="w-full">
      <h3>Add Ballots Manually</h3>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full px-3 mb-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="rankings"
          >
            Candidates <small>(Comma-separated list of names)</small>
          </label>
          <input
            className="flex-grow block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="rankings"
            type="text"
            value={rankings}
            onChange={(event) => setRankings(event.target.value)}
            placeholder="Alice, Bob, Charlie"
          />
        </div>
        <div className="w-full px-3 mb-3">
          <label
            className="whitespace-nowrap block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="number"
          >
            # Ballots
          </label>
          <div className="w-full flex flex-row gap-2">
            <input
              className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              min="1"
              value={ballotCount}
              onChange={(event) => setBallotCount(event.target.value)}
            />
            <button
              className="shadow block bg-brand-blue-3 hover:bg-brand-blue-4 focus:shadow-outline focus:outline-none text-white font-bold py-1 px-4 rounded"
              type="submit"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export { Ballot, BallotSet, BallotForm };

import React, { useState, useEffect } from 'react';
import isValidEmail from '../../utils/validateEmail';
import { SubmitButton } from '../layout/submit_button';
import { trackPetitionSignature } from '../analytics';

export const Petition = ({ listId, goal, cta }) => {
  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    status: 'ready',
    isEmailValid: true,
    onBlurEmail: false,
    loadingList: true,
    listCount: 0,
  });

  useEffect(() => {
    fetch(`/.netlify/functions/list_count/${listId}`)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error(`contact count failed with status: ${res.status}`);
        }
      })
      .then((res) => {
        setState((state) => ({
          ...state,
          listCount: res.count,
          loadingList: false,
        }));
      });
  }, [listId]);

  const onSubmit = () => {
    if (!btnDisabled) {
      const { first_name, last_name, email } = state;
      setState({ ...state, status: 'loading' });
      fetch('/.netlify/functions/email', {
        method: 'PUT',
        body: JSON.stringify({
          first_name,
          last_name,
          email,
          list_id: listId,
          slug_origin,
        }),
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 400) {
            if (typeof window !== `undefined`) {
              trackPetitionSignature({
                petition: listId,
                path: window.location.pathname,
              });
            }
            return res.json();
          } else throw new Error(`Request failed with status ${res.status}: ${res.body}`);
        })
        .then((res) => {
          setState({
            first_name: '',
            last_name: '',
            email: '',
            status: 'done',
            isEmailValid: true,
            onBlurEmail: false,
            loadingList: false,
            listCount: state.listCount + 1,
          });
        })
        .catch((err) => {
          console.error(err);
          setState({
            ...state,
            status: 'ready',
          });
        });
    }
  };

  const updateValue = (inputName) => {
    return (e) => {
      const updatedState = { ...state, [inputName]: e.target.value };
      if (inputName === 'email') updatedState.isEmailValid = isValidEmail(updatedState.email);
      setState(updatedState);
    };
  };

  const btnDisabled = state.status !== 'ready' || state.email === '' || !state.isEmailValid;

  const slug_origin =
    typeof window !== `undefined` ? window.location.pathname.replace(/^\//, '') : '';

  const percentComplete = Math.min(100, Math.round((100 * state.listCount) / goal));

  return (
    <form className="w-full max-w-md border rounded border-gray-400 pt-8 pr-8 pl-8 pb-4">
      <div className="flex justify-center items-center flex-wrap -mx-3 mb-6">
        {!state.loadingList && (
          <div>
            <div className="text-2xl font-bold text-black">
              {state.listCount} People Support This
            </div>
            <div className="text-base">out of a current goal of {goal}</div>
            <div className="h-3 relative max-w-xl rounded-full overflow-hidden mt-2">
              <div className="w-full h-full bg-gray-200 absolute"></div>
              <div
                className="h-full bg-green-500 relative w-0"
                style={{ width: `${percentComplete}%` }}
              ></div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <input
            required
            autocomplete="email"
            value={state.email}
            onChange={updateValue('email')}
            onBlur={(e) => {
              setState({ ...state, onBlurEmail: true });
            }}
            onFocus={(e) => {
              setState({ ...state, onBlurEmail: false });
            }}
            className={`block w-full border ${
              state.isEmailValid
                ? 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                : 'border-red-500 focus:outline-none'
            } rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-sm`}
            id="signup-email"
            type="email"
            placeholder="Email"
          ></input>
          {!state.isEmailValid && state.onBlurEmail && (
            <p className="text-red-500 text-xs italic">Please use a valid email address.</p>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <input
            value={state.first_name}
            onChange={updateValue('first_name')}
            className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            id="signup-first-name"
            type="text"
            placeholder="First Name"
          ></input>
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <input
            value={state.last_name}
            onChange={updateValue('last_name')}
            className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            id="signup-last-name"
            type="text"
            placeholder="Last Name"
          ></input>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3 mb-6 md:mb-0">
          <SubmitButton
            label={cta}
            onSubmit={onSubmit}
            disabled={btnDisabled}
            className="shadow w-full bg-blue-500 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            hoverClass="hover:bg-blue-400"
            disabledClass="opacity-50 cursor-not-allowed"
            status={state.status}
          />
        </div>
      </div>
    </form>
  );
};

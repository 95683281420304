import React, { Fragment } from 'react';

export const StateConstitutionalAmendmentLegislative = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        根據
        <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CONS&division&title&part&chapter&article=XVIII">
          《加州憲法》第十八條
        </a>{' '}
        ，州立法機關發起的加州憲法修正案必須先得到州議會的修正案必須先得到州議會的修正案批准，然後再由選民以50%+1的簡單多數通過。
      </p>
    );
  }
  return (
    <p>
      According to{' '}
      <a href="https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?lawCode=CONS&division&title&part&chapter&article=XVIII">
        Article XVIII
      </a>{' '}
      of the California Constitution, amendments to the California Constitution initiated by the
      State Legislature must first be approved by a supermajority of both houses and the amendment
      must then be approved by voters with a simple majority of 50% + 1.
    </p>
  );
};

export const StateConstitutionalAmendmentInitiative = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return <p>TKTK</p>;
  }
  return (
    <p>
      According to{' '}
      <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
        Article II, Section 8
      </a>{' '}
      of the California Constitution, amendments to the California Constitution may be introduced by
      collecting signatures (8% of the votes cast in the most recent Governor's race). The amendment
      must then be approved by voters with a simple majority of 50% + 1.
    </p>
  );
};

export const StateInitiative = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        根據
        <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
          加州憲法第二條第 8 節
        </a>{' '}
        ，公民可以引入法規（法律）通過收集簽名（最近一次州長競選中所投選票的5%）。然後，該法規必須由選民以
        50% + 1 的簡單多數批准。
      </p>
    );
  }
  return (
    <p>
      According to{' '}
      <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
        Article II, Section 8
      </a>{' '}
      of the California Constitution, citizens may introduce statutes (laws) by collecting
      signatures (5% of the votes cast in the most recent Governor's race). The statute must then be
      approved by voters with a simple majority of 50% + 1.
    </p>
  );
};

export const StateInitiativeChange = ({
  existingLawLabel = '',
  existingLawLink = '',
  lang = 'en',
}) => {
  let ballotMeasure = 'a ballot measure';
  if (lang == 'zh') {
    ballotMeasure = '一項選票提案';
  }
  if (existingLawLabel) {
    ballotMeasure = <a href={existingLawLink}>{existingLawLabel}</a>;
  }
  if (lang == 'zh') {
    return (
      <Fragment>
        <p>
          根據{' '}
          <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
            加州憲法第二條第 8 節
          </a>{' '}
          ，公民可以引入法規（法律）通過收集簽名（最近一次州長競選中所投選票的5%）。然後，該法規必須由選民以
          50% + 1 的簡單多數批准。
        </p>
        <p>
          由於正在修改的法律最初是透過 {ballotMeasure},
          州立法機構無權自行更改。根據法律規定，法律的任何修改都必須得到選民的批准。
        </p>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <p>
        According to{' '}
        <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=CONS&sectionNum=SEC.%208.&article=II">
          Article II, Section 8
        </a>{' '}
        of the California Constitution, citizens may introduce statutes (laws) by collecting
        signatures (5% of the votes cast in the most recent Governor's race). The statute must then
        be approved by voters with a simple majority of 50% + 1.
      </p>
      <p>
        Since the law being changed was originally passed via {ballotMeasure}, the state legislature
        does not have the power to change it themselves. By law, any changes to the law must be
        approved by the voters.
      </p>
    </Fragment>
  );
};

export const CityCharterAmendmentLegislative = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        這是市參事會提出的城市憲章修正案。由於試市參事會不能直接修改章程，因此如果要修改章程就必須向選民提出。章程修正案需要六名或六名以上使參使的投票才能進行公投，並且選民必須以50%
        + 1多數通過。
      </p>
    );
  }
  return (
    <p>
      This is a City Charter amendment introduced by the Board of Supervisors. Since the Board of
      Supervisors cannot directly amend the Charter, if they want to change the Charter they must go
      to the voters. A vote of six or more Supervisors is required to place a Charter Amendment on
      the ballot, and voters must approve it by a 50% + 1 majority.
    </p>
  );
};

export const CityCharterAmendmentInitiative = () => (
  <p>
    Citizens may introduce charter amendments by collecting signatures from at least 10% of the
    registered voters in the City. The amendment must then be approved by voters with a simple
    majority of 50% + 1.
  </p>
);

export const CityMayorInitiative = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        這是由市長列入選票的。由於市長不能製定法律，如果市長想要修改法律，他們必須直接向選民提出。它需要
        50% + 1 的簡單多數才能通過。
      </p>
    );
  }
  return (
    <p>
      This was placed on the ballot by the Mayor. Since the Mayor cannot write laws, if the Mayor
      wants a law changed they must go directly to the voters. It needs simple majority of 50% + 1
      to pass.
    </p>
  );
};

export const CitySupervisorInitiativeMinority = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        這是由四名或四名以上市參事會成員進行投票，但少於六名簡單多數。由於該法沒有得到多數人的支持，少數派的市參事會直接將其提交給選民。它需要
        50% + 1 的簡單多數才能通過。
      </p>
    );
  }
  return (
    <p>
      This was placed on the ballot by four or more members of the Board of Supervisors, but fewer
      than a simple majority of six. Since the law didn't have majority support, the Supervisors in
      the minority are taking it directly to the voters. It needs simple majority of 50% + 1 to
      pass.
    </p>
  );
};

export const CitySupervisorInitiativeMajority = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        由六名或六名以上市參事會成員進行表決。本來可以由市參事會通過，但他們決定直接提交給選民。通常，這是由面臨連任並希望利用投票措施來提高自己的知名度的市參事完成的，並且通常是為了繞過自己競選活動的競選捐款限制。它需要
        50% + 1 的簡單多數才能通過。
      </p>
    );
  }
  return (
    <p>
      This was placed on the ballot by six or more members of the Board of Supervisors. It could
      have been passed by the Board of Supervisors, but they decided to take it directly to the
      voters. Typically, this is done by Supervisors who are facing re-election and want to use the
      ballot measure to boost their name recognition, and often to bypass campaign contribution
      limits for their own campaigns. It needs simple majority of 50% + 1 to pass.
    </p>
  );
};

export const CitySignatureInitiative = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return <p>這是透過收集選民簽名而將其列入選票的。它需要 50% + 1 的簡單多數才能通過。</p>;
  }
  return (
    <p>
      This was placed on the ballot by collecting signatures from voters. It needs simple majority
      of 50% + 1 to pass.
    </p>
  );
};

export const CitySchoolBond = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        《加州教育法》
        <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=EDC&sectionNum=15266.">
          第 15266 條
        </a>{' '}
        和《加州憲法》
        <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_18">
          第 XVI 條第 18 條
        </a>{' '}
        規定對學區債券進行投票。債券必須得到選民 55% + 1 多數的批准。
      </p>
    );
  }
  return (
    <p>
      California Education Code{' '}
      <a href="https://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=EDC&sectionNum=15266.">
        section 15266
      </a>{' '}
      and{' '}
      <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_18">
        Section 18 of Article XVI
      </a>{' '}
      of the California Constitution mandate that School District bonds be put to a vote. Bonds must
      be approved by the voters by a 55% + 1 majority.
    </p>
  );
};

export const CityBond = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        《加州政府法典》
        <a href="http://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=GOV&sectionNum=53506">
          第 53506 條
        </a>{' '}
        和《加州憲法》
        <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_1">
          第 XVI 條
        </a>{' '}
        要求，這類規模的普通債券必須提交投票。債券須由選民以 66.66% + 1 的多數通過。
      </p>
    );
  }
  return (
    <p>
      California Government Code{' '}
      <a href="http://leginfo.legislature.ca.gov/faces/codes_displaySection.xhtml?lawCode=GOV&sectionNum=53506">
        section 53506
      </a>{' '}
      and{' '}
      <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_1">
        Article XVI
      </a>{' '}
      of the California Constitution mandate that general obligation bonds of this size be put to a
      vote. Bonds must be approved by the voters by a 66.66% + 1 majority.
    </p>
  );
};

export const StateBond = ({ lang = 'en' }) => {
  if (lang == 'zh') {
    return (
      <p>
        <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_1">
          加州憲法第十六條
        </a>
        規定，超過 30
        萬美元的債務（包括州債券）必須提交公眾投票。債券必須先獲得州立法機關參眾兩院三分之二多數的批准，然後獲得簡單多數選民的批准。
      </p>
    );
  }
  return (
    <p>
      <a href="https://ballotpedia.org/Article_XVI,_California_Constitution#Section_1">
        Article XVI
      </a>{' '}
      of the California Constitution mandates that debt exceeding $300,000 (including state bonds)
      be submitted to the public for a vote. Bonds must first be approved by a two-thirds majority
      of both houses of the state Legislature, and then by a simple majority of the voters.
    </p>
  );
};

import React from 'react';
import Rank1 from '../../images/icons/icon-rank-vote-1.svg';
import Rank2 from '../../images/icons/icon-rank-vote-2.svg';
import Rank3 from '../../images/icons/icon-rank-vote-3.svg';
import Rank4 from '../../images/icons/icon-rank-vote-4.svg';

const rankIcons = [null, Rank1, Rank2, Rank3, Rank4];
const RankIcon = ({ rank, ...props }) => {
  return (
    <img className="object-scale-down" src={rankIcons[rank]} alt={`Rank ${rank}`} {...props} />
  );
};

export default RankIcon;

import { PetitionEmbed } from "../../../../src/components/ctas/petition-embed.js";
import { EmailCTA } from "../../../../src/components/ctas/email";
import { SharedSpacesCosponsors } from "../../../../src/components/shared_spaces_cosponsors.js";
import { PrimaryLinkCTA } from "../../../../src/components/ctas/cta";
import { SupervisorEmailCTA } from "../../../../src/components/ctas/supervisor_email";
import * as React from 'react';
export default {
  PetitionEmbed,
  EmailCTA,
  SharedSpacesCosponsors,
  PrimaryLinkCTA,
  SupervisorEmailCTA,
  React
};
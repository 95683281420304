import React from 'react';
import { twMerge } from 'tailwind-merge';

const BlueBgWrapper = ({ children, className }) => {
  return (
    <div
      className={twMerge(
        'bg-brand-blue-1 rounded-sm p-5 my-5 [&_p:last-of-type]:mb-0 lg:p-12 lg:my-12',
        className
      )}
      data-bg-blue
    >
      {children}
    </div>
  );
};

export default BlueBgWrapper;

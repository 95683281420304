import React from 'react';
import { twMerge } from 'tailwind-merge';

// Set virtual white background for section to take full width of Grid parent

const BgWhiteWrapper = ({ children, className = '', firstTemplateSection = false }) => {
  return (
    <div
      className={twMerge(
        'pt-5 relative before:block before:absolute before:z-0 before:top-0 before:left-0 before:-translate-x-1/3 before:w-[300vw] before:h-full before:pointer-events-none before:bg-white',
        className
      )}
    >
      <div
        className={`relative ${
          firstTemplateSection &&
          'before:hidden before:absolute before:z-0 before:-top-10 before:left-0 before:-translate-x-1/3 before:w-[300vw] before:h-10 before:pointer-events-none before:bg-white before:lg:block'
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default BgWhiteWrapper;

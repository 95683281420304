import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

export const PrimaryLinkCTA = ({ children, href, target, ...props }) => {
  return (
    <OutboundLink href={href || '#'} target={target}>
      <button
        className="px-6 py-3 border border-transparent text-base font-bold rounded-md shadow-md text-white bg-brand-blue-3 hover:bg-brand-blue-4 transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue-4"
        {...props}
      >
        {children}
      </button>
    </OutboundLink>
  );
};

export const SecondaryLinkCTA = ({ children, href, target, ...props }) => {
  return (
    <OutboundLink href={href || '#'} target={target} className="no-underline">
      <button
        className="flex items-center px-6 py-2 !font-bold !text-brand-blue-4 border-transparent hover:border-brand-blue-3 rounded-md border-2 duration-200 transition"
        {...props}
      >
        {children}
      </button>
    </OutboundLink>
  );
};

import Candidates from "../../../../src/components/candidates";
import BgWhiteWrapper from "../../../../src/components/election/bgWhiteWrapper";
import { TLDR } from "../../../../src/components/election/tldr";
import { CandidateInfo } from "../../../../src/components/election/candidate_info";
import { apr22_contests, aprTableHeaders, aprCandidateTableData } from "../../../../src/data/data-apr-2022.js";
import * as React from 'react';
export default {
  Candidates,
  BgWhiteWrapper,
  TLDR,
  CandidateInfo,
  apr22_contests,
  aprTableHeaders,
  aprCandidateTableData,
  React
};
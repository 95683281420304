import React from 'react';
import { Link } from 'gatsby';
import { DistrictByID } from '../districts/district';
import iconLinkChevronBlue from '../../images/icons/icon-link-chevron.svg';
import iconLinkChevronGreen from '../../images/icons/icon-link-chevron-green.svg';
import iconEmail from '../../images/icons/icon-email.svg';
import iconPhone from '../../images/icons/icon-phone.svg';
import { SupervisorsByName } from './supervisors.js';

export const ElectedInfo = ({ name }) => {
  // if name isn't in SupervisorsByName, return array of valid names
  if (!SupervisorsByName.hasOwnProperty(name)) {
    let validNames = Object.keys(SupervisorsByName).map((name) => {
      return name;
    });
    return (
      <div className="not-prose">
        <p className="text-xl text-slate-800 m-0 p-0">District Supervisor</p>
        <p className="text-2xl font-bold m-0 p-0">Invalid name: {name}</p>
        <p className="text-slate-500">Valid names are: {validNames.join(', ')}</p>
        <hr className="!m-1" />
      </div>
    );
  }

  const elected = SupervisorsByName[name];

  let reElection =
    elected.nextElection === null ? 'Termed out in ' + elected.termedOut : elected.nextElection;

  return (
    <div className="not-prose">
      <div className="flex flex-col gap-4 md:flex-row md:gap-6">
        <Link
          to="/sf-district-supervisor-map/"
          className="md:hidden tracking-wider uppercase text-brand-blue-4 font-bold flex items-center gap-1 text-xs w-fit"
        >
          <img src={iconLinkChevronBlue} alt="" className="inline" />
          Back to supervisor map
        </Link>
        <img
          src={`/supervisors/${elected.lastName.toLowerCase()}.jpg`}
          alt={elected.fullName}
          className="w-[236px] h-[313px] small-upper-left-clip mx-auto"
        />
        <div className="flex flex-col gap-4 w-auto">
          <Link
            to="/sf-district-supervisor-map/"
            className="hidden tracking-wider uppercase text-brand-blue-4 font-bold md:flex items-center gap-1 text-xs w-fit"
          >
            <img src={iconLinkChevronBlue} alt="" className="inline rotate-180" />
            Back to supervisor map
          </Link>
          <div className="grid justify-items-center md:grid-cols-2 gap-1 md:gap-4 md:justify-items-stretch">
            {elected.growSfEndorsed ? (
              <p className="text-xs text-brand-green-4 font-bold uppercase md:order-3 md:text-right">
                <span className="tracking-wider">
                  Endorsed <span className="hidden md:inline">By Growsf</span>
                </span>
                <img
                  src={iconLinkChevronGreen}
                  alt=""
                  className="inline -ml-[4px] -mt-[18px] -rotate-45 w-[15px] h-[15px]"
                />
              </p>
            ) : (
              ''
            )}
            <h1 className="text-brand-blue-5 font-bold text-2xl md:font-black md:text-5xl md:order-1 md:col-span-2">
              {elected.fullName}
            </h1>
            <p className="text-brand-gray-5 font-bold text-xs uppercase tracking-wider md:order-2">
              District {elected.district} Supervisor
            </p>
          </div>
          <hr className="border-brand-gray-2" />
          <div className="flex flex-row gap-4">
            <img
              src={`/supervisors/map-district-${elected.district}.png`}
              alt={`District ${elected.district}`}
              className="rounded-sm w-[75px] h-[67px] md:w-[150px] md:h-[134px]"
            />
            <p className="text-brand-gray-4 text-xs md:text-sm">
              {DistrictByID(elected.district).description}
            </p>
          </div>
          <hr className="border-brand-gray-2" />
          <div className="w-full flex flex-wrap gap-4 columns-2xs">
            {elected.wasAppointed ? (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Appointed
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{elected.tookOffice}</p>
                </div>
              </div>
            ) : (
              ''
            )}
            {elected.specialElection && (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Special Election
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{elected.specialElection}</p>
                  <p>
                    Won by <span className="font-bold">{elected.margin}</span> votes.
                  </p>
                </div>
              </div>
            )}
            {elected.elected ? (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Elected
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{elected.elected}</p>
                  {!elected.specialElection && (
                    <p>
                      Won by <span className="font-bold">{elected.margin}</span> votes.
                    </p>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {elected.reElected ? (
              <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
                <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                  Re-elected
                </h2>
                <div className="text-brand-gray-4 text-xs md:text-sm font-light">
                  <p className="">{elected.reElected}</p>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="w-auto min-w-[30%] flex-none break-inside-avoid space-y-1">
              <h2 className="uppercase text-brand-gray-5 text-xs font-bold tracking-wider">
                Up for Re-Election
              </h2>
              <p className="text-brand-gray-4 text-xs md:text-sm font-light">{reElection}</p>
            </div>
          </div>
          <hr className="border-brand-gray-2" />
          <div className="w-full flex flex-col items-center gap-3 md:items-start">
            <a
              href={`mailto:${elected.staffEmail}?bcc=act@growsf.org`}
              className="flex items-center font-bold text-xs md:text-sm text-brand-blue-4 gap-2"
            >
              <img src={iconEmail} alt="" className="w-[16px] h-[12px]" />
              <span>{elected.staffEmail}</span>
            </a>
            <a
              href={`tel:+1${elected.getRealPhone}`}
              className="flex items-center font-bold text-xs md:text-sm text-brand-blue-4 gap-2"
            >
              <img src={iconPhone} alt="" className="w-[16px] h-[16px]" />
              <span>{elected.phone}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

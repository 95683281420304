import Candidates from "../../../../src/components/candidates";
import People from "../../../../src/components/people";
import { recalls, assessor, assembly, assemblyCandidates } from "../../../../src/data/data-feb-2022.js";
import * as React from 'react';
export default {
  Candidates,
  People,
  recalls,
  assessor,
  assembly,
  assemblyCandidates,
  React
};
import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/solid';

// Div that expands when clicked on
// Props: title, children
// State: open
// onClick: toggle open
// Returns: div with title and children
export const Collapsible = ({ title = 'Expand details', children }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="border p-2 border-gray-300 mb-4 bg-white">
      <div
        className="flex items-center justify-between p-0 cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <h5 className="!text-gray-600 !font-normal">{title}</h5>
        <div className="w-6 h-6">{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</div>
      </div>
      {open && <div>{children}</div>}
    </div>
  );
};

import React from 'react';
import {
  PARTY,
  JURISDICTION,
  QS,
  ENDORSEMENT,
} from '../components/election/constants.js';
import { Contest, Candidate, Judge, Measure } from '../components/election/data_objs.js';

// These are listed in the order in which they'll appear on the ballot
export const nov22_contests = {
  Governor: Contest(JURISDICTION.STATE, 'Governor', [
    Candidate('Gavin Newsom', PARTY.DEM, 'Governor Of California', QS.NOT_SENT, /*endorsed=*/ true),
    Candidate('Brian Dahle', PARTY.REP, 'Senator / Farmer', QS.NOT_SENT),
  ]),
  'Lieutenant Governor': Contest(JURISDICTION.STATE, 'Lieutenant Governor', [
    Candidate(
      'Eleni Kounalakis',
      PARTY.DEM,
      'Lieutenant Governor',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Angela E. Underwood Jacobs', PARTY.REP, 'Businesswoman / Deputy Mayor', QS.NOT_SENT),
  ]),
  'Secretary of State': Contest(JURISDICTION.STATE, 'Secretary of State', [
    Candidate(
      'Shirley N. Weber',
      PARTY.DEM,
      'Appointed California Secretary Of State',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Rob Bernosky', PARTY.REP, 'Chief Financial Officer', QS.NOT_SENT),
  ]),
  Controller: Contest(JURISDICTION.STATE, 'Controller', [
    Candidate(
      'Malia M. Cohen',
      PARTY.DEM,
      'California State Board Of Equalization Member',
      'https://drive.google.com/file/d/16Nz_BkHORKpd_W5UrQPOmDOXGO-RNiHh/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Lanhee Chen',
      PARTY.REP,
      'Fiscal Advisor / Educator',
      'https://drive.google.com/file/d/15a2AG0lt4HH9gEcrs4DYc0OynsEZvOtW/view?usp=sharing'
    ),
  ]),
  Treasurer: Contest(JURISDICTION.STATE, 'Treasurer', [
    Candidate('Fiona Ma', PARTY.DEM, 'State Treasurer / Cpa', QS.NOT_SENT, /*endorsed=*/ true),
    Candidate('Jack M. Guerrero', PARTY.REP, 'Councilmember / Cpa / Economist', QS.NOT_SENT),
  ]),
  'Attorney General': Contest(JURISDICTION.STATE, 'Attorney General', [
    Candidate(
      'Rob Bonta',
      PARTY.DEM,
      'Appointed Attorney General Of The State Of California',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Nathan Hochman', PARTY.REP, 'General Counsel', QS.NOT_SENT),
  ]),
  'Insurance Commissioner': Contest(JURISDICTION.STATE, 'Insurance Commissioner', [
    Candidate(
      'Ricardo Lara',
      PARTY.DEM,
      'Insurance Commissioner',
      QS.NO_RESPONSE,
      /*endorsed=*/ true
    ),
    Candidate('Robert Howell', PARTY.REP, 'Cybersecurity Equipment Manufacturer', QS.NO_RESPONSE),
  ]),
  'Board of Equalization, District 2': Contest(
    JURISDICTION.STATE,
    'Board of Equalization, District 2',
    [
      Candidate(
        'Sally J. Lieber',
        PARTY.DEM,
        'Councilwoman / Environmental Advocate',
        QS.NO_RESPONSE
      ),
      Candidate('Peter Coe Verbica', PARTY.REP, 'Investment Advisor', QS.NO_RESPONSE),
    ]
  ),
  'Senate (Full Term)': Contest(JURISDICTION.FEDERAL, 'Senate (Full Term)', [
    Candidate(
      'Alex Padilla',
      PARTY.DEM,
      'Appointed United States Senator',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Mark P. Meuser', PARTY.REP, 'Constitutional Attorney', QS.NOT_SENT),
  ]),
  'Senate (Partial Term)': Contest(JURISDICTION.FEDERAL, 'Senate (Partial Term)', [
    Candidate(
      'Alex Padilla',
      PARTY.DEM,
      'Appointed United States Senator',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Mark P. Meuser', PARTY.REP, 'Constitutional Attorney', QS.NOT_SENT),
  ]),
  'Congress, District 11': Contest(JURISDICTION.FEDERAL, 'Congress, District 11', [
    Candidate('Nancy Pelosi', PARTY.DEM, 'Member Of Congress', QS.NO_RESPONSE, /*endorsed=*/ true),
    Candidate(
      'John Dennis',
      PARTY.REP,
      'Independent Businessman',
      'https://drive.google.com/file/d/18NL3zfPpBJfyThZ8Bir79j3tVYAMTMgH/view?usp=sharing'
    ),
  ]),
  'Congress, District 15': Contest(JURISDICTION.FEDERAL, 'Congress, District 15', [
    Candidate(
      'David Canepa',
      PARTY.DEM,
      'San Mateo County Supervisor',
      'https://drive.google.com/file/d/1eECAuRs5XFCI9fpZv8D6lWTtzbnYSQEq/view?usp=sharing'
    ),
    Candidate(
      'Kevin Mullin',
      PARTY.DEM,
      'California State Assemblymember',
      'https://drive.google.com/file/d/14U0UxDtICRhK6EBpXCFEbdTP3i8TETBo/view?usp=sharing',
      /*endorsed=*/ true
    ),
  ]),
  'Assembly, District 17': Contest(JURISDICTION.STATE, 'Assembly, District 17', [
    Candidate(
      'Matt Haney',
      PARTY.DEM,
      'Assemblymember',
      'https://drive.google.com/file/d/1KTEYxH1j_Io9sIp7mrxw4v1PJ_XjW57d/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate('David Campos', PARTY.DEM, 'Criminal Justice Administrator', QS.NO_RESPONSE),
  ]),
  'Assembly, District 19': Contest(JURISDICTION.STATE, 'Assembly, District 19', [
    Candidate(
      'Phil Ting',
      PARTY.DEM,
      'Assembly Budget Chair',
      'https://drive.google.com/file/d/1F-A769uRVxb7MHd0LF8pW3MFTNZ1YstS/view?usp=sharing'
    ),
    Candidate(
      'Karsten Weide',
      PARTY.REP,
      'Industry Analyst',
      'https://drive.google.com/file/d/1yK5eViHIqTwFO2nWDuPLiOZENmUtTiRQ/view?usp=sharing'
    ),
  ]),
  'Superintendent of Public Instruction': Contest(
    JURISDICTION.STATE,
    'Superintendent of Public Instruction',
    [
      Candidate(
        'Tony K. Thurmond',
        PARTY.NP,
        'Superintendent Of Public Instruction',
        QS.NO_RESPONSE,
        /*endorsed=*/ true
      ),
      Candidate(
        'Lance Ray Christensen',
        PARTY.NP,
        'Education Policy Executive',
        'https://drive.google.com/file/d/1vbVkx1cd5vfBm2AIwnIAmeEYnUiPnM0n/view?usp=sharing'
      ),
    ]
  ),
  'Chief Justice of California': Judge(JURISDICTION.STATE, 'Chief Justice of California', [
    Candidate(
      'Patricia Guerrero',
      PARTY.NP,
      'Associate Justice of the California Supreme Court',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
  ]),
  'Associate Justice of the California Supreme Court': Judge(
    JURISDICTION.STATE,
    'Associate Justice of the California Supreme Court',
    [
      Candidate(
        'Goodwin Liu',
        PARTY.NP,
        'Associate Justice of the California Supreme Court',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
      Candidate(
        'Martin J. Jenkins',
        PARTY.NP,
        'Associate Justice of the California Supreme Court',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
      Candidate(
        'Joshua P. Groban',
        PARTY.NP,
        'Associate Justice of the California Supreme Court',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Associate Justice, Court of Appeal, First District, Division Two': Judge(
    JURISDICTION.STATE,
    'Associate Justice, Court of Appeal, First District, Division Two',
    [
      Candidate(
        'Therese M. Stewart',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Two',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Presiding Justice, Court of Appeal, First District, Division Three': Judge(
    JURISDICTION.STATE,
    'Presiding Justice, Court of Appeal, First District, Division Three',
    [
      Candidate(
        'Alison M. Tucher',
        PARTY.NP,
        'Presiding Justice, Court of Appeal, First District, Division Three',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Associate Justice, Court of Appeal, First District, Division Three': Judge(
    JURISDICTION.STATE,
    'Associate Justice, Court of Appeal, First District, Division Three',
    [
      Candidate(
        'Victor A. Rodriguez',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Three',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
      Candidate(
        'Ioana Petrou',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Three',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
      Candidate(
        'Carin T. Fujisaki',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Three',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Associate Justice, Court of Appeal, First District, Division Four': Judge(
    JURISDICTION.STATE,
    'Associate Justice, Court of Appeal, First District, Division Four',
    [
      Candidate(
        'Tracie L. Brown',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Four',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
      Candidate(
        'Jeremy M. Goldman',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Four',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Presiding Justice, Court of Appeal, First District, Division Five': Judge(
    JURISDICTION.STATE,
    'Presiding Justice, Court of Appeal, First District, Division Five',
    [
      Candidate(
        'Teri L. Jackson',
        PARTY.NP,
        'Presiding Justice, Court of Appeal, First District, Division Five',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Associate Justice, Court of Appeal, First District, Division Five': Judge(
    JURISDICTION.STATE,
    'Associate Justice, Court of Appeal, First District, Division Five',
    [
      Candidate(
        'Gordon B. Burns',
        PARTY.NP,
        'Associate Justice, Court of Appeal, First District, Division Five',
        QS.NOT_SENT,
        /*endorsed=*/ true
      ),
    ]
  ),
  'Board of Education': Contest(JURISDICTION.LOCAL, 'Board of Education', [
    Candidate(
      'Ann Hsu',
      PARTY.DEM,
      'Appointed Member, Board of Education',
      'https://drive.google.com/file/d/1tjo8VNEJ9DFk4vb8EjndOgMvXnbCS0G6/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Lainie Motamedi',
      PARTY.DEM,
      'Appointed Member, Board of Education',
      'https://drive.google.com/file/d/1IpS9Y4_yudEzoqoSD6NUUl7PbwSnaJZ3/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Lisa Weissman-Ward',
      PARTY.DEM,
      'Appointed Member, Board of Education',
      'https://drive.google.com/file/d/1SbXbEUun45T9lpJwnRc1cuKrcRMpgPD-/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Alida Fisher',
      PARTY.DEM,
      'Special Education Advocate',
      'https://drive.google.com/file/d/1s_JmmTk2BeeDaIeFigj9WssQ_jotnM1V/view?usp=sharing',
      /*endorsed=*/ false
    ),
    Candidate(
      'Karen Fleshman',
      PARTY.DEM,
      'Diversity Inclusion Educator',
      'https://drive.google.com/file/d/1r4phCEF3uDalhBHjt6b4g8KkAJATf7tO/view?usp=sharing',
      /*endorsed=*/ false
    ),
    Candidate(
      'Gabriela López',
      PARTY.DEM,
      'Teacher Educator, Recalled Board of Education Commissioner',
      QS.NO_RESPONSE,
      /*endorsed=*/ false
    ),
  ]),
  'BART Director, District 8': Contest(JURISDICTION.LOCAL, 'BART Director, District 8', [
    Candidate('Janice Li', PARTY.DEM, 'BART Board Director', QS.NOT_SENT, /*endorsed=*/ true),
  ]),
  'Community College Board (4 year term)': Contest(
    JURISDICTION.LOCAL,
    'Community College Board (4 year term)',
    [
      Candidate(
        'Jill Yee',
        PARTY.DEM,
        'Academic Dean',
        'https://drive.google.com/file/d/1FWqF38IBLerVWXkmpN2Bm8MHxpw2dWDn/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate(
        'Marie Hurabiell',
        PARTY.DEM,
        'University Regent / Entrepreneur',
        'https://drive.google.com/file/d/1hd3pVCVg-DdMdRcutMXSOVosHeqtFqHU/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate(
        'Jason Zeng',
        PARTY.REP,
        'Data Engineer',
        'https://drive.google.com/file/d/1KNpwCZPmkqbygMISYi2E8MiDYCdotTqj/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate(
        'John Rizzo',
        PARTY.DEM,
        'Vice President, Community College Board',
        'https://drive.google.com/file/d/17FDZdn6yTOHdmXy3aFalEMyNaNdK5lX8/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate(
        'Brigitte Davila',
        PARTY.DEM,
        'President, City College Board',
        'https://drive.google.com/file/d/1Q4GIWBGU1uIX1lNXmbT-tWrS1So1uTG9/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate(
        'Thea Selby',
        PARTY.DEM,
        'Trustee, City College of San Francisco',
        'https://drive.google.com/file/d/1wpTGrRGcuK_w0NvGL6rNkOEZJtkfe1Xs/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate('Susan Solomon', PARTY.DEM, 'Retired Teacher', QS.DECLINED, /*endorsed=*/ false),
      Candidate(
        'Anita Martinez',
        PARTY.DEM,
        'Retired Teacher / Administrator',
        QS.NO_RESPONSE,
        /*endorsed=*/ false
      ),
      Candidate(
        'William Walker',
        PARTY.DEM,
        'Public School Teacher',
        'https://drive.google.com/file/d/11BaCot9aCd7-thlUA0UwjfDLjWUbZZxL/view?usp=sharing',
        /*endorsed=*/ false
      ),
      Candidate(
        'Vickie Van Chung',
        PARTY.DEM,
        'Community Organizer',
        QS.NO_RESPONSE,
        /*endorsed=*/ false
      ),
    ]
  ),
  'Community College Board (2 year term)': Contest(
    JURISDICTION.LOCAL,
    'Community College Board (2 year term)',
    [
      Candidate(
        'Murrell Green',
        PARTY.DEM,
        'Appointed Trustee, Community College Board',
        QS.NO_RESPONSE,
        /*endorsed=*/ true
      ),
      Candidate(
        'Adolfo Velasquez',
        PARTY.DEM,
        'Retired Chair / Counselor',
        QS.NO_RESPONSE,
        /*endorsed=*/ false
      ),
      Candidate(
        'Daniel Landry',
        PARTY.DEM,
        'Director, Arts Nonprofit',
        QS.NO_RESPONSE,
        /*endorsed=*/ false
      ),
    ]
  ),
  'Assessor-Recorder': Contest(JURISDICTION.LOCAL, 'Assessor-Recorder', [
    Candidate(
      'Joaquín Torres',
      PARTY.DEM,
      'Assessor-Recorder, City and County of San Francisco',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
  ]),
  'District Attorney': Contest(JURISDICTION.LOCAL, 'District Attorney', [
    Candidate(
      'Brooke Jenkins',
      PARTY.DEM,
      'Appointed District Attorney',
      'https://drive.google.com/file/d/15IWK40971eOXVK-CWUyWBx7bhAHsDZk7/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Maurice Chenier',
      PARTY.DEM,
      'Attorney at Law',
      'https://drive.google.com/file/d/1UtxzGq2z9rwug0J924nMD-xHlHB-spC0/view?usp=sharing',
      /*endorsed=*/ false
    ),
    Candidate(
      'Joe Alioto Veronese',
      PARTY.DEM,
      'Civil Rights Attorney',
      'https://drive.google.com/file/d/1QU4MqLtbK90NsiTvKI90GiFlPttkwGGU/view?usp=sharing',
      /*endorsed=*/ false
    ),
    Candidate(
      'John Hamasaki',
      PARTY.DEM,
      'Civil Rights Attorney',
      QS.NO_RESPONSE,
      /*endorsed=*/ false
    ),
  ]),
  'Public Defender': Contest(JURISDICTION.LOCAL, 'Public Defender', [
    Candidate(
      'Manohar (Mano) Raju',
      PARTY.DEM,
      'Incumbent',
      'https://drive.google.com/file/d/14hmXa5RrB8v4gSkHbEH6hLqX-3xlXbWH/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Rebecca Young',
      PARTY.DEM,
      'Criminal Justice Attorney',
      'https://drive.google.com/file/d/1E7tnRXfFzFyOcB8cmpudYL4t6yyCwsiD/view?usp=sharing',
      /*endorsed=*/ false
    ),
  ]),
  'Supervisor, District 2': Contest(JURISDICTION.LOCAL, 'Supervisor, District 2', [
    Candidate(
      'Catherine Stefani',
      PARTY.DEM,
      'District 2 Supervisor',
      'https://drive.google.com/file/d/1S3gPLMmpMXtNbOPG95iZMXQu1zat517u/view?usp=sharing',
      /*endorsed=*/ true
    ),
  ]),
  'Supervisor, District 4': Contest(JURISDICTION.LOCAL, 'Supervisor, District 4', [
    Candidate(
      'Joel Engardio',
      PARTY.DEM,
      'Nonprofit Director / Journalist',
      'https://drive.google.com/file/d/1DVlB9kboxzlF5u4j5vYdj8ZP4bfBngk6/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Gordon Mar',
      PARTY.DEM,
      'Member, Board of Supervisors',
      QS.DECLINED,
      /*endorsed=*/ false
    ),
  ]),
  'Supervisor, District 6': Contest(JURISDICTION.LOCAL, 'Supervisor, District 6', [
    Candidate(
      'Matt Dorsey',
      PARTY.DEM,
      'Appointed Member, Board of Supervisors',
      'https://drive.google.com/file/d/10k52GRtnSgrU3vkltZ26PQvPMqY_QdC4/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Honey Mahogany',
      PARTY.DEM,
      'Social Worker',
      'https://drive.google.com/file/d/1QuwGbiwPdCZ1XA3uSDeIM0UD6-PI5Zbw/view?usp=sharing',
      /*endorsed=*/ false
    ),
    Candidate(
      'Billie Cooper',
      PARTY.DEM,
      'Peer Educator Organizer',
      QS.NO_RESPONSE,
      /*endorsed=*/ false
    ),
    Candidate(
      'Cherelle Jackson',
      PARTY.DEM,
      'Director of Communications',
      QS.NO_RESPONSE,
      /*endorsed=*/ false
    ),
  ]),
  'Supervisor, District 8': Contest(JURISDICTION.LOCAL, 'Supervisor, District 8', [
    Candidate(
      'Rafael Mandelman',
      PARTY.DEM,
      'District 8 Supervisor',
      'https://drive.google.com/file/d/1c91bz_YwyCTLXRDpee4gOcvPn0KzKXQI/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'Kate Stoia',
      PARTY.DEM,
      'Lawyer',
      'https://drive.google.com/file/d/1FtwgwiULwvab2rhgsA6SQgVSSMc5g8O5/view?usp=sharing',
      /*endorsed=*/ false
    ),
  ]),
  'Supervisor, District 10': Contest(JURISDICTION.LOCAL, 'Supervisor, District 10', [
    Candidate(
      'Shamann Walton',
      PARTY.DEM,
      'President, San Francisco County Supervisors',
      QS.NO_RESPONSE,
      /*endorsed=*/ false
    ),
    Candidate(
      'Brian Sam Adam',
      PARTY.DEM,
      'Public Information Officer',
      'https://drive.google.com/file/d/1hO-GjnVmqKQ3gW7jbx4uUpUIE2O2TpTf/view?usp=sharing',
      /*endorsed=*/ false
    ),
  ]),
  'Proposition 1': Measure(
    JURISDICTION.STATE,
    'Constitutional Right to Reproductive Freedom',
    '1',
    ENDORSEMENT.YES,
    'Proposition 1 (November 2022) would add the right to an abortion to the California Constitution'
  ),
  'Proposition 26': Measure(
    JURISDICTION.STATE,
    'Allow sports gambling, but only by Native American tribes',
    '26',
    ENDORSEMENT.NO,
    'Proposition 26 (November 2022) would legalize sports gambling, roulette, and dice games on Native American tribal land.'
  ),
  'Proposition 27': Measure(
    JURISDICTION.STATE,
    'Online gambling, but only with a $100 million licensing fee',
    '27',
    ENDORSEMENT.NO,
    'Proposition 27 (November 2022) would legalize online sports gambling for organizations which partner with a Native American tribe and pay a $100 million license fee.'
  ),
  'Proposition 28': Measure(
    JURISDICTION.STATE,
    'Additional funding for arts & music education in public schools',
    '28',
    ENDORSEMENT.YES,
    'Proposition 28 (November 2022) would require the state to fund arts education programs equal to 1% of the constitutionally mandated state and local funding that public schools received in the prior year. At schools with more than 500 students, 80% of these funds will be used for teacher salaries, and the remainder used for training and materials.'
  ),
  'Proposition 29': Measure(
    JURISDICTION.STATE,
    'Require expensive doctors in kidney dialysis clinics',
    '29',
    ENDORSEMENT.NO,
    'Proposition 29 (November 2022) would require that kidney dialysis clinics enact staffing requirements that force clinics to have at least one physician, nurse practitioner, or physician assistant with "at least six months of experience providing care to end-stage renal disease" onsite during patient treatments. It also adds some mandatory disclosure requirements to the clinics.'
  ),
  'Proposition 30': Measure(
    JURISDICTION.STATE,
    'Investments in fighting and preventing wildfires, and reducing carbon emissions and air pollution from vehicles',
    '30',
    ENDORSEMENT.YES,
    'Proposition 30 (November 2022) would fund investments in fighting and preventing wildfires and reduce climate emissions and air pollution from vehicles by subsidizing installation of electric vehicle charging infrastructure, hydrogen fueling infrastructure, and purchases of electric and other zero-emissions vehicles. This will be funded by a new 1.75% income tax on people earning more than $2 million per year.'
  ),
  'Proposition 31': Measure(
    JURISDICTION.STATE,
    'Uphold flavored tobacco ban',
    '31',
    ENDORSEMENT.YES,
    'Proposition 31 (November 2022) is a referendum on California Senate Bill 793 of 2020, which banned sales of flavored tobacco vaping products except for hookah tobacco, loose-leaf tobacco, and premium cigars. A Yes vote maintains the ban on flavored tobacco products (the original law stays in effect).'
  ),

  'Proposition A': Measure(
    JURISDICTION.LOCAL,
    'Retiree cost of living adjustment',
    'A',
    ENDORSEMENT.YES,
    'Proposition A (San Francisco, November 2022) will allow city employees who retired before November 6, 1996 to receive supplemental cost of living adjustments to their retirement benefits. This brings pre-1996 retiree benefits in line with current benefits.'
  ),
  'Proposition B': Measure(
    JURISDICTION.LOCAL,
    'Reunify Public Works & Street Sanitation departments',
    'B',
    ENDORSEMENT.YES,
    'Proposition B (San Francisco, November 2022) would re-unify the Department of Public Works and the Department of Sanitation and Streets by partially repealing a ballot measure from 2020 which split the Department of Public Works in two.'
  ),
  'Proposition C': Measure(
    JURISDICTION.LOCAL,
    'More red tape around homeless services',
    'C',
    ENDORSEMENT.NO,
    'Proposition C (San Francisco, November 2022) would create a new Homelessness Oversight Commission that would be tasked with overseeing the Department of Homelessness and Supportive Housing.'
  ),
  'Proposition D': Measure(
    JURISDICTION.LOCAL,
    'Make it easier to build homes',
    'D',
    ENDORSEMENT.YES,
    'Proposition D (San Francisco, November 2022) will streamline and speed up approvals of subsidized Affordable housing.'
  ),
  'Proposition E': Measure(
    JURISDICTION.LOCAL,
    'Board of Supervisors anti-housing measure',
    'E',
    ENDORSEMENT.NO,
    'Proposition E (San Francisco, November 2022) is an anti-housing measure that will maintain the anti-housing status quo in the San Francisco Board of Supervisors.'
  ),
  'Proposition F': Measure(
    JURISDICTION.LOCAL,
    'Library Preservation Fund',
    'F',
    ENDORSEMENT.YES,
    'Proposition F (San Francisco, November 2022) would renew the Library Preservation Fund for an additional 25 years. The Library Preservation Fund is currently set to expire in June 2023.'
  ),
  'Proposition G': Measure(
    JURISDICTION.LOCAL,
    'Student Success Fund',
    'G',
    ENDORSEMENT.YES,
    'Proposition G (San Francisco, November 2022) would create a new city fund (initially $11 million, growing to $60 million by 2037) that will be used for grants intended to support academic achievement and social & emotional wellness of students.'
  ),

  'Proposition H': Measure(
    JURISDICTION.LOCAL,
    'Move off-year elections to presidential election years',
    'H',
    ENDORSEMENT.YES,
    'Proposition H (San Francisco, November 2022) would change the election for Mayor, Sheriff, District Attorney, City Attorney, and Treasurer to be in the same election as President of the United States.'
  ),
  'Proposition I': Measure(
    JURISDICTION.LOCAL,
    'Close JFK Promenade, spend $80 million to move sand',
    'I',
    ENDORSEMENT.NO,
    'Proposition I (San Francisco, November 2022) would close the popular JFK Promenade park and instead allow car traffic on the pedestrian-only JFK Promenade in Golden Gate Park and end the popular Great Highway "weekend compromise", instead mandating vehicle traffic use the pedestrian right-of-way. It may cost the city as much as $80 million.'
  ),
  'Proposition J': Measure(
    JURISDICTION.LOCAL,
    'Keep JFK Promenade open',
    'J',
    ENDORSEMENT.YES,
    'Proposition J (San Francisco, November 2022) would make the popular JFK Promenade Park permanent.'
  ),
  'Proposition L': Measure(
    JURISDICTION.LOCAL,
    'Reauthorize existing tax for transportation improvements',
    'L',
    ENDORSEMENT.YES,
    'Proposition L (San Francisco, November 2022) would continue an existing sales tax that funds Muni and other transit projects, maintenance, paratransit services, among others.'
  ),
  'Proposition M': Measure(
    JURISDICTION.LOCAL,
    'New property tax on unoccupied housing',
    'M',
    ENDORSEMENT.NO,
    'Proposition M (San Francisco, November 2022) would impose a new tax on apartments which are vacant for longer than 6 months.'
  ),
  'Proposition N': Measure(
    JURISDICTION.LOCAL,
    'Golden Gate Park underground parking garage',
    'N',
    ENDORSEMENT.YES,
    'Proposition N (San Francisco, November 2022) would dissolve the Golden Gate Park Concourse Authority (a public entity created via a 1998 ballot measure) and transfer ownership and operational duties of the underground parking garage to the City.'
  ),
  'Proposition O': Measure(
    JURISDICTION.LOCAL,
    'New parcel tax for City College',
    'O',
    ENDORSEMENT.NO,
    'Proposition O (San Francisco, November 2022) would create a new parcel tax on real estate in order to send additional funds to City College.'
  ),
};

export const nov22_vacancy_table_headers = [
  {
    Header: 'Vacancy reason',
    accessor: 'reason',
  },
  {
    Header: '# of homes',
    accessor: 'val',
  },
];

export const nov22_vacancy_table_data = [
  {
    reason: 'For rent',
    val: 9040,
  },
  {
    reason: 'Rented, not occupied',
    val: 2123,
  },
  {
    reason: 'For sale only',
    val: 1072,
  },
  {
    reason: 'Sold, not occupied',
    val: 5589,
  },
  {
    reason: 'For seasonal, recreational, or occasional use',
    val: 7188,
  },
  {
    reason: 'For migrant workers',
    val: 13,
  },
  {
    reason: 'Other vacant',
    val: 11447,
  },
];

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const YesIcon = ({ alt, ...props }) => {
  const { Yes } = useStaticQuery(
    graphql`
      query {
        Yes: file(relativePath: { eq: "icons/icon-yes.svg" }) {
          publicURL
        }
      }
    `
  );
  return (
    <img className="inline !my-0 object-scale-down" {...props} src={Yes.publicURL} alt="yes" />
  );
};

export default YesIcon;

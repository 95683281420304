import React from 'react';
import HAC from '../../../images/partners/hac.png';

export const JFKCosponsors = () => {
  return (
    <div>
      <h2>Co-sponsors</h2>
      <a href="https://sfhac.org/" target="_blank">
        <img src={HAC} className="sm:block rounded-xl" />
      </a>
    </div>
  );
};

export default JFKCosponsors;

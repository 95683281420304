import React, { useState } from 'react';
import { LinkIcon } from '@heroicons/react/outline';
import slugify from 'react-slugify';
import { twMerge } from 'tailwind-merge';
import { useSiteMetadata } from '../../hooks/use-site-metadata';
import ArrowDecorator from '../../images/icons/icon-arrows-decorator.svg';

const HeaderLink = 'no-underline font-bold text-gray-900 hover:underline';

// Inspired by https://tomekdev.com/posts/anchors-for-headings-in-mdx

/**
 * This function is used to create the heading components in markdown files. It
 * transforms markdown style headings (eg # My Heading) into HTML headings with
 * anchor links (eg <h1 id="my-heading">My Heading<a href="#my-heading">...</a></h1>).
 * You can specify custom anchor links by adding {#custom-id} to the end of the
 * heading (eg # My Heading {#custom-id}).
 *
 * @param {int} level
 * @param {string or react nodes or whatever. html} children
 * @param {bool} makeLink whether or not to make an anchor link
 * @param {*} props
 * @returns a Heading component
 */
function h(level, children, makeLink, idOverride, hide, props) {
  const Tag = `h${level}`;

  let textContent = '';
  if (typeof children === 'string' || children instanceof String) {
    textContent = children;
  } else {
    textContent = slugify(children);
  }

  // This regex pulls out the custom ID specified between {# and } in the heading
  // e.g. # My Heading {#custom-id} => custom-id
  var anchor;
  const customId = textContent.match(/{#([^}]+)}/);
  if (customId) {
    anchor = customId[1].trim();
    children = textContent.replace('{#' + anchor + '}', '').trim();
  } else if (idOverride) {
    // If idOverride is provided, use that
    anchor = idOverride;
  } else {
    anchor = slugify(textContent);
  }
  if (makeLink) {
    return (
      <Tag
        id={hide ? `${anchor}-data-hidden` : anchor}
        {...props}
        className={`${twMerge(props.className, 'group')}`}
      >
        <span className="hidden mr-1 lg:inline lg:-ml-7">
          <HLinkButton anchor={hide ? `${anchor}-data-hidden` : anchor} />
        </span>
        {children}
        {props.arrowDecorator && (
          <img src={ArrowDecorator} className="!my-0 !mt-1" alt="Arrow Decorator" />
        )}
      </Tag>
    );
  } else {
    return (
      <Tag id={anchor} {...props}>
        {children}
      </Tag>
    );
  }
}

export const HLinkButton = ({ anchor, showOnRight }) => {
  const [copied, setCopied] = useState(false);
  const { siteUrl } = useSiteMetadata();
  const pathName = typeof window !== 'undefined' ? window.location.pathname : '';
  // ensure anchor starts with a #
  if (anchor.charAt(0) !== '#') {
    anchor = `#${anchor}`;
  }
  return (
    <button
      className="relative no-underline hover:underline inline items-center z-20"
      onClick={() => {
        const link = `${siteUrl + pathName + anchor}`;
        navigator?.clipboard.writeText(link);
        window.history.replaceState({}, '', link);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1000);
        const element = document?.getElementById(anchor.split('#')[1]);
        const target = element.classList.contains('sticky-heading')
          ? element?.parentNode.parentNode
          : element;
        const offset = target.matches('h5,h4') ? target.parentNode.firstChild.clientHeight : 0;
        window?.scrollTo({
          behavior: 'instant',
          top:
            target.getBoundingClientRect().top +
            window?.scrollY -
            (window?.innerWidth < 1024 ? 56 : 0) -
            offset,
        });
      }}
      aria-label="copy"
      role="button"
    >
      <span
        className={`absolute border border-brand-blue-4 rounded-sm bg-white text-left text-xs italic p-1 font-light text-brand-blue-4 whitespace-nowrap w-max ${
          copied ? 'block' : 'hidden'
        } ${
          showOnRight
            ? 'right-0 top-full lg:right-auto lg:left-full lg:top-1/2 lg:-translate-y-1/2'
            : 'left-full top-0 translate-y-full'
        }`}
      >
        {copied ? 'Link copied' : ''}
      </span>
      <LinkIcon
        className={`opacity-100 h-6 w-6 text-gray-300 hover:text-brand-blue-3 inline hover:opacity-100 focus:opacity-100 group-hover:opacity-100 group-focus:opacity-100 ${
          copied ? 'lg:opacity-100' : 'lg:opacity-0'
        }`}
      />
    </button>
  );
};

export const H1 = ({ idOverride, children, className = '', hide, ...props }) =>
  h(1, children, true, idOverride, hide, { ...props, className: className });
export const H2 = ({ idOverride, children, className = '', hide, ...props }) =>
  h(2, children, true, idOverride, hide, { ...props, className: className });
export const H3 = ({ idOverride, children, className = '', hide, ...props }) =>
  h(3, children, true, idOverride, hide, { ...props, className: className });
export const H4 = ({ idOverride, children, className = '', hide, ...props }) =>
  h(4, children, true, idOverride, hide, { ...props, className: className });
export const H5 = ({ idOverride, children, className = '', hide, ...props }) =>
  h(5, children, true, idOverride, hide, { ...props, className: className });
export const H6 = ({ idOverride, children, className = '', hide, ...props }) =>
  h(6, children, true, idOverride, hide, { ...props, className: className });

import { board, boe, bart, sfProps, trustees, measures, stateCandidates, federalCandidates } from "../../../../src/data/data-2020.js";
import * as React from 'react';
export default {
  board,
  boe,
  bart,
  sfProps,
  trustees,
  measures,
  stateCandidates,
  federalCandidates,
  React
};
import React from 'react';
import {
  PARTY,
  JURISDICTION,
  QS,
  ENDORSEMENT,
} from '../components/election/constants.js';
import { Contest, Candidate, Measure } from '../components/election/data_objs.js';

export const june_contests = {
  'Congress, District 11': Contest(JURISDICTION.FEDERAL, 'Congress, District 11', [
    Candidate('Nancy Pelosi', PARTY.DEM, 'Member Of Congress', QS.NO_RESPONSE, /*endorsed=*/ true),
    Candidate(
      'John Dennis',
      PARTY.REP,
      'Independent Businessman',
      'https://drive.google.com/file/d/1Xx276N2yh0Umw8_YacG3VndNacEugwdJ/view?usp=sharing'
    ),
    Candidate(
      'Shahid Buttar',
      PARTY.DEM,
      'Public Interest Advocate',
      'https://drive.google.com/file/d/1Tmj6tPXuPr_VkchYEkLf3ePou8pAYU8z/view?usp=sharing'
    ),
    Candidate(
      'Jeffrey Phillips',
      PARTY.DEM,
      'Gig Worker',
      'https://drive.google.com/file/d/1ysNzMHZG-Yn-8khvQXfyIWVrFixfBQca/view?usp=sharing'
    ),
    Candidate('Bianca Von Krieg', PARTY.DEM, 'Actress / Community Organizer', QS.NO_RESPONSE),
    Candidate('Eve Del Castello', PARTY.REP, 'Business Consultant', QS.NO_CONTACT),
  ]),
  'Congress, District 15': Contest(JURISDICTION.FEDERAL, 'Congress, District 15', [
    Candidate(
      'Emily Beach',
      PARTY.DEM,
      'Burlingame City Councilwoman',
      'https://drive.google.com/file/d/1k7-VqNMdNiR_sQHeANkfoY8UQ5GM6P5G/view?usp=sharing'
    ),
    Candidate(
      'Gus Mattammal',
      PARTY.REP,
      'Small Businessman / Educator',
      'https://drive.google.com/file/d/1Eg7VBjk6COc0-9zrsbxTeXRzcISCH88v/view?usp=sharing'
    ),
    Candidate(
      'David Canepa',
      PARTY.DEM,
      'San Mateo County Supervisor',
      'https://drive.google.com/file/d/1_1kLRxjf9eHn-G8Ti9cAfq3MUI1iyxEf/view?usp=sharing'
    ),
    Candidate('Jim Garrity', PARTY.NPP, 'Security Safety Manager', QS.NO_CONTACT),
    Candidate(
      'Kevin Mullin',
      PARTY.DEM,
      'California State Assemblymember',
      'https://drive.google.com/file/d/1iXNB9V5kTtPM3esrqzUGuDuRXFuo4_2L/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate('Ferenc Pataki', PARTY.REP, 'Realtor', QS.NO_CONTACT),
    Candidate('Andrew Watters', PARTY.DEM, 'Attorney / Software Developer', QS.NO_RESPONSE),
  ]),
  Senate: Contest(JURISDICTION.FEDERAL, 'Senate', [
    Candidate('John Thompson Parker', PARTY.PF, 'Social Justice Advocate', QS.NOT_SENT),
    Candidate('Don J. Grundmann', PARTY.NPP, 'Doctor Of Chiropractic', QS.NOT_SENT),
    Candidate('Eleanor Garcia', PARTY.NPP, 'Industrial Worker', QS.NOT_SENT),
    Candidate('Sarah Sun Liew', PARTY.REP, 'Entrepreneur / Non-profit Director', QS.NOT_SENT),
    Candidate('Akinyemi Agbede', PARTY.DEM, 'Mathematician', QS.NOT_SENT),
    Candidate(
      'Alex Padilla',
      PARTY.DEM,
      'Appointed United States Senator',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Obaidul Huq Pirjada', PARTY.DEM, 'Attorney', QS.NOT_SENT),
    Candidate('Yvonne R. Girard', PARTY.REP, 'Retired Government Employee', QS.NOT_SENT),
    Candidate('Mark P. Meuser', PARTY.REP, 'Constitutional Attorney', QS.NOT_SENT),
    Candidate('Cordie Williams', PARTY.REP, 'Doctor / Business Owner', QS.NOT_SENT),
    Candidate('Deon D. Jenkins', PARTY.NPP, 'None', QS.NOT_SENT),
    Candidate("Dan O'Dowd", PARTY.DEM, 'None', QS.NOT_SENT),
    Candidate('Douglas Howard Pierce', PARTY.DEM, "Missing Children's Advocate", QS.NOT_SENT),
    Candidate('Timothy J. Ursich', PARTY.DEM, 'Doctor', QS.NOT_SENT),
    Candidate('James P. Bradley', PARTY.REP, 'Ceo / Business Owner', QS.NOT_SENT),
    Candidate('Jon Elist', PARTY.REP, 'Small Business Owner', QS.NOT_SENT),
    Candidate('Myron L. Hall', PARTY.REP, 'Podiatric Physician', QS.NOT_SENT),
    Candidate('Robert George Lucero Jr.', PARTY.REP, 'Executive Business Consultant', QS.NOT_SENT),
    Candidate('Enrique Petris', PARTY.REP, 'Businessman / Investor', QS.NOT_SENT),
    Candidate('Chuck Smith', PARTY.REP, 'Retired Law Enforcement', QS.NOT_SENT),
    Candidate('Carlos Guillermo Tapia', PARTY.REP, 'Business Owner / Realtor', QS.NOT_SENT),
    Candidate('James "Henk" Conn', PARTY.GRN, 'Teacher', QS.NOT_SENT),
    Candidate('Pamela Elizondo', PARTY.GRN, 'Marijuana Plastic Entrepreneur', QS.NOT_SENT),
    Candidate('Daphne Bradford', PARTY.NPP, 'Education Consultant', QS.NOT_SENT),
  ]),
  Governor: Contest(JURISDICTION.STATE, 'Governor', [
    Candidate('Joel Ventresca', PARTY.DEM, 'Retired Airport Analyst', QS.NOT_SENT),
    Candidate('Luis Javier Rodriguez', PARTY.GRN, 'Writer / Poet', QS.NOT_SENT),
    Candidate('Ronald A. Anderson', PARTY.REP, 'Contractor / Inspector / Businessman', QS.NOT_SENT),
    Candidate('Gavin Newsom', PARTY.DEM, 'Governor Of California', QS.NOT_SENT, /*endorsed=*/ true),
    Candidate('Anthony "Tony" Fanara', PARTY.DEM, 'Owner Of Restaurant', QS.NOT_SENT),
    Candidate('Anthony Trimino', PARTY.REP, 'Entrepepreneur / CEO', QS.NOT_SENT),
    Candidate('Robert C. Newman II', PARTY.REP, 'Farmer / Psychologist', QS.NOT_SENT),
    Candidate('Jenny Rae Le Roux', PARTY.REP, 'Entrepreneur / Mom / Businesswoman', QS.NOT_SENT),
    Candidate('Shawn Collins', PARTY.REP, 'Military Officer / Attorney', QS.NOT_SENT),
    Candidate('Armando "Mando" Perez-Sarrato', PARTY.DEM, 'None', QS.NOT_SENT),
    Candidate('Cristian Raul Morales', PARTY.REP, 'Director Of Operations', QS.NOT_SENT),
    Candidate('Daniel R. Mercuri', PARTY.REP, 'Father / Business Owner', QS.NOT_SENT),
    Candidate('Brian Dahle', PARTY.REP, 'Senator / Farmer', QS.NOT_SENT),
    Candidate('Ron Jones', PARTY.REP, 'None', QS.NOT_SENT),
    Candidate('Lonnie Sortor', PARTY.REP, 'Business Owner', QS.NOT_SENT),
    Candidate('Major Williams', PARTY.REP, 'Entrepreneur / Businessman', QS.NOT_SENT),
    Candidate('Leo S. Zacky', PARTY.REP, 'Businessman / Farmer / Broadcaster', QS.NOT_SENT),
    Candidate('Heather Collins', PARTY.GRN, 'Small Business Owner', QS.NOT_SENT),
    Candidate('Serge Fiankan', PARTY.NPP, 'Small Business Owner', QS.NOT_SENT),
    Candidate('James G. Hanink', PARTY.NPP, 'Retired Educator', QS.NOT_SENT),
    Candidate('David Lozano', PARTY.REP, 'Executive Officer / Attorney', QS.NOT_SENT),
    Candidate(
      'Woodrow "Woody" Sanders III',
      PARTY.NPP,
      'Entrepreneur / Director / Engineer',
      QS.NOT_SENT
    ),
    Candidate('Frederic C. Schultz', PARTY.NPP, 'Human Rights Attorney', QS.NOT_SENT),
    Candidate('Reinette Senum', PARTY.NPP, 'None', QS.NOT_SENT),
    Candidate('Michael Shellenberger', PARTY.NPP, 'Homelessness Policy Advocate', QS.NOT_SENT),
    Candidate('Bradley Zink', PARTY.NPP, "Children's Book Author", QS.NOT_SENT),
  ]),
  'Lieutenant Governor': Contest(JURISDICTION.STATE, 'Lieutenant Governor', [
    Candidate('Mohammad Arif', PARTY.PF, 'Businessman', QS.NOT_SENT),
    Candidate('David Hillberg', PARTY.NPP, 'Aviation Mechanic / Actor', QS.NOT_SENT),
    Candidate(
      'Eleni Kounalakis',
      PARTY.DEM,
      'Lieutenant Governor',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('William Cavett "Skee" Saacke', PARTY.DEM, 'California Trial Attorney', QS.NOT_SENT),
    Candidate('Jeffrey Highbear Morgan', PARTY.DEM, 'Businessman / Engineer', QS.NOT_SENT),
    Candidate('David Fennell', PARTY.REP, 'Entrepreneur', QS.NOT_SENT),
    Candidate('Angela E. Underwood Jacobs', PARTY.REP, 'Businesswoman / Deputy Mayor', QS.NOT_SENT),
    Candidate('Clint W. Saunders', PARTY.REP, 'Mental Health Worker', QS.NOT_SENT),
  ]),
  'Secretary of State': Contest(JURISDICTION.STATE, 'Secretary of State', [
    Candidate('Gary N. Blenner', PARTY.GRN, 'Teacher', QS.NOT_SENT),
    Candidate(
      'Shirley N. Weber',
      PARTY.DEM,
      'Appointed California Secretary Of State',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Rob Bernosky', PARTY.REP, 'Chief Financial Officer', QS.NOT_SENT),
    Candidate('Rachel Hamm', PARTY.REP, 'Author', QS.NOT_SENT),
    Candidate('James "JW" Paine', PARTY.REP, 'Teamster Truck Driver', QS.NOT_SENT),
    Candidate('Raul Rodriguez Jr.', PARTY.REP, 'Retired Warehouseman', QS.NOT_SENT),
    Candidate('Matthew D. Cinquanta', PARTY.NPP, 'Private Investigator', QS.NOT_SENT),
  ]),
  Controller: Contest(JURISDICTION.STATE, 'Controller', [
    Candidate(
      'Laura Wells',
      PARTY.GRN,
      'Financial Analyst',
      'https://drive.google.com/file/d/17eu73V9SIDiMuLXP_ml9crjZ12RcFvk5/view?usp=sharing'
    ),
    Candidate(
      'Malia M. Cohen',
      PARTY.DEM,
      'California State Board Of Equalization Member',
      QS.NO_RESPONSE,
      /*endorsed=*/ true
    ),
    Candidate(
      'Ron Galperin',
      PARTY.DEM,
      'Controller, City Of Los Angeles / Attorney',
      QS.NO_RESPONSE
    ),
    Candidate('Steve Glazer', PARTY.DEM, 'California State Senator', QS.NO_RESPONSE),
    Candidate('Yvonne Yiu', PARTY.DEM, 'Chief Financial Officer', QS.NO_RESPONSE),
    Candidate(
      'Lanhee Chen',
      PARTY.REP,
      'Fiscal Advisor / Educator',
      'https://drive.google.com/file/d/1vkfLkqfCHsTUIhTa8cHASi1KS5iwXgYX/view?usp=sharing'
    ),
  ]),
  Treasurer: Contest(JURISDICTION.STATE, 'Treasurer', [
    Candidate('Fiona Ma', PARTY.DEM, 'State Treasurer / Cpa', QS.NOT_SENT, /*endorsed=*/ true),
    Candidate('Meghann Adams', PARTY.PF, 'School Bus Driver', QS.NOT_SENT),
    Candidate('Andrew Do', PARTY.REP, 'Chief Financial Officer', QS.NOT_SENT),
    Candidate('Jack M. Guerrero', PARTY.REP, 'Councilmember / Cpa / Economist', QS.NOT_SENT),
  ]),
  'Attorney General': Contest(JURISDICTION.STATE, 'Attorney General', [
    Candidate('Dan Kapelovitz', PARTY.GRN, 'Criminal Defense Attorney', QS.NOT_SENT),
    Candidate(
      'Rob Bonta',
      PARTY.DEM,
      'Appointed Attorney General Of The State Of California',
      QS.NOT_SENT,
      /*endorsed=*/ true
    ),
    Candidate('Eric Early', PARTY.REP, 'Attorney / Business Owner', QS.NOT_SENT),
    Candidate('Nathan Hochman', PARTY.REP, 'Attorney / General Counsel', QS.NOT_SENT),
    Candidate('Anne Marie Schubert', PARTY.NPP, 'District Attorney', QS.NOT_SENT),
  ]),
  'Insurance Commissioner': Contest(JURISDICTION.STATE, 'Insurance Commissioner', [
    Candidate('Nathalie Hrizi', PARTY.PF, 'Teacher / Union Officer', QS.NO_RESPONSE),
    Candidate('Veronika Fimbres', PARTY.GRN, 'Nurse', QS.NO_RESPONSE),
    Candidate(
      'Jasper "Jay" Jackson',
      PARTY.DEM,
      'Paralegal',
      'https://drive.google.com/file/d/112xj6AOJ8tbgdDkMEy4l6YfnlWIg0L3J/view?usp=sharing'
    ),
    Candidate('Marc Levine', PARTY.DEM, 'Member, California State Assembly', QS.NO_RESPONSE),
    Candidate('Vinson Eugene Allen', PARTY.DEM, 'Medical Doctor / Businessman', QS.NO_RESPONSE),
    Candidate(
      'Ricardo Lara',
      PARTY.DEM,
      'Insurance Commissioner',
      QS.NO_RESPONSE,
      /*endorsed=*/ true
    ),
    Candidate('Greg Conlon', PARTY.REP, 'Businessman / Cpa', QS.NO_RESPONSE),
    Candidate('Robert Howell', PARTY.REP, 'Cybersecurity Equipment Manufacturer', QS.NO_RESPONSE),
    Candidate('Robert J. Molnar', PARTY.NPP, 'Healthcare Advocate / Businessman', QS.NO_RESPONSE),
  ]),
  'Board of Equalization, District 2': Contest(
    JURISDICTION.STATE,
    'Board of Equalization, District 2',
    [
      Candidate(
        'Michela Alioto-Pier',
        PARTY.DEM,
        'Small Business Owner',
        'https://drive.google.com/file/d/1KAnHpVpI7a6fdNkQ1JETcJmcBqkno5vJ/view?usp=sharing',
        /*endorsed=*/ true
      ),
      Candidate(
        'Sally J. Lieber',
        PARTY.DEM,
        'Councilwoman / Environmental Advocate',
        QS.NO_RESPONSE
      ),
      Candidate('Peter Coe Verbica', PARTY.REP, 'Investment Advisor', QS.NO_RESPONSE),
    ]
  ),
  'Superintendent of Public Instruction': Contest(
    JURISDICTION.STATE,
    'Superintendent of Public Instruction',
    [
      Candidate(
        'Marco Amaral',
        PARTY.NP,
        'Teacher / Trustee',
        'https://drive.google.com/file/d/1G3fVWeMfWGY_SthbgPoN035KGDjaY-h-/view?usp=sharing'
      ),
      Candidate('George Yang', PARTY.NP, 'Software Architect / Father', QS.NO_CONTACT),
      Candidate(
        'Tony K. Thurmond',
        PARTY.NP,
        'Superintendent Of Public Instruction',
        QS.NO_RESPONSE
      ),
      Candidate('Joseph Guy Campbell', PARTY.NP, 'Montessori Education Publisher', QS.NO_CONTACT),
      Candidate('Lance Ray Christensen', PARTY.NP, 'Education Policy Executive', QS.NO_CONTACT),
      Candidate('Jim Gibson', PARTY.NP, 'Cyber Security Professional', QS.NO_CONTACT),
      Candidate('Ainye E. Long', PARTY.NP, 'Public School Teacher', QS.NO_CONTACT),
    ]
  ),
  'Assembly, District 17': Contest(JURISDICTION.STATE, 'Assembly, District 17', [
    Candidate(
      'Bill Shireman',
      PARTY.REP,
      'Environmental Solutions Entrepreneur',
      'https://drive.google.com/file/d/1b41t3l9Dg0ze31QC8pp6_A9R_R-lxkaO/view?usp=sharing'
    ),
    Candidate('David Campos', PARTY.DEM, 'Criminal Justice Administrator', QS.NO_RESPONSE),
    Candidate(
      'Matt Haney',
      PARTY.DEM,
      'Supervisor, City And County Of San Francisco',
      'https://drive.google.com/file/d/1v1YZHlp4kjDvrknV2cKiCB227N7V-IV_/view?usp=sharing',
      /*endorsed=*/ true
    ),
  ]),
  'Assembly, District 19': Contest(JURISDICTION.STATE, 'Assembly, District 19', [
    Candidate(
      'Phil Ting',
      PARTY.DEM,
      'Assembly Budget Chair',
      'https://drive.google.com/file/d/1ViPrhmSV7ehnVOHdQ7xcBPeD6TUSZPQW/view?usp=sharing'
    ),
    Candidate(
      'Karsten Weide',
      PARTY.REP,
      'Industry Analyst',
      'https://drive.google.com/file/d/1Br6_odmW9fUeXYrQyzOliu00kLDMUhuy/view?usp=sharing'
    ),
  ]),
  'City Attorney': Contest(JURISDICTION.LOCAL, 'City Attorney', [
    Candidate(
      'David Chiu',
      PARTY.NP,
      'Appointed City Attorney',
      'https://drive.google.com/file/d/1Nur62TiYh8wQK1mCAHgnAhAyEMiNnlZz/view?usp=sharing',
      /*endorsed=*/ true
    ),
  ]),
  'Proposition A': Measure(
    JURISDICTION.LOCAL,
    'Muni Reliability and Street Safety Bond',
    'A',
    ENDORSEMENT.YES
  ),
  'Proposition B': Measure(
    JURISDICTION.LOCAL,
    'Building Inspection Commission',
    'B',
    ENDORSEMENT.NO
  ),
  'Proposition C': Measure(
    JURISDICTION.LOCAL,
    'Recall Timelines and Vacancy Appointments',
    'C',
    ENDORSEMENT.NO
  ),
  'Proposition D': Measure(
    JURISDICTION.LOCAL,
    'Office of Victim and Witness Rights; Legal Services for Domestic Violence Victims',
    'D',
    ENDORSEMENT.YES
  ),
  'Proposition E': Measure(JURISDICTION.LOCAL, 'Behested Payments', 'E', ENDORSEMENT.NO),
  'Proposition F': Measure(
    JURISDICTION.LOCAL,
    'Refuse Collection and Disposal',
    'F',
    ENDORSEMENT.NO
  ),
  'Proposition G': Measure(
    JURISDICTION.LOCAL,
    'Public Health Emergency Leave',
    'G',
    ENDORSEMENT.NO
  ),
  'Proposition H': Measure(
    JURISDICTION.LOCAL,
    'Recall Measure Regarding Chesa Boudin',
    'H',
    ENDORSEMENT.YES
  ),
};

import React, { useState, useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { sanitizedPathForSlugOrigin } from '../helpers';
import { SuperButton } from '../layout/buttons';
import classNames from 'classnames';

let API_KEY = 'AIzaSyDzvx-dBuMNHdeqrIpmZXsQNBqMNFGuPBM';
let CENTER_OF_SAN_FRANCISCO = [37.754512, -122.4435708];
// This is about 6 miles and should completely encompass SF proper.
let BOUNDS_METERS = 9500;

export const AddressToDistrict = ({
  onAddressSelected = null,
  onDistrictChanged,
  onLoadError,
  onFetchError,
  changeCenterLoading = false,
  toggleCenterLoading = null,
}) => {
  const [state, setState] = useState({
    address: '',
    haveDistrict: false,
    loadedMaps: false,
    addressSelected: false,
  });
  let inputRef = useRef();

  useEffect(async () => {
    let loader = new Loader({
      apiKey: API_KEY,
      versin: 'weekly',
      libraries: ['places'],
    });

    try {
      await loader.load();
      setState((prevState) => ({ ...prevState, loadedMaps: true }));
    } catch (e) {
      if (onLoadError) {
        onLoadError(e);
      }
    }
  }, []);

  const handleChange = (addr) => {
    if (state.haveDistrict) {
      setState((prevState) => ({
        ...prevState,
        haveDistrict: false,
        address: addr,
      }));
      onDistrictChanged(null);
    } else {
      setState((prevState) => ({ ...prevState, address: addr, addressSelected: false }));
    }
  };

  const handleSelect = (addr) => {
    setState((prevState) => ({
      ...prevState,
      address: addr,
      addressSelected: true,
    }));
    if (toggleCenterLoading !== null) {
      toggleCenterLoading();
    }
    if (onAddressSelected !== null) {
      onAddressSelected();
    }
    geocodeByAddress(addr)
      .then((results) => {
        getLatLng(results[0]).then((latLng) => {
          fetch(
            `/.netlify/functions/district?context=${encodeURIComponent(
              sanitizedPathForSlugOrigin()
            )}&geocodedData=${encodeURIComponent(JSON.stringify(results[0]))}`,
            {
              method: 'GET',
            }
          ).then((response) => {
            response.json().then((json) => {
              setState((prevState) => ({
                ...prevState,
                address: addr,
                haveDistrict: true,
                addressSelected: true,
              }));
              json['chosenAddress'] = addr;
              json['position'] = {
                lat: latLng.lat,
                lng: latLng.lng,
              };
              onDistrictChanged(json);
            });
          });
        });
      })
      .catch((e) => {
        if (onFetchError) {
          onFetchError(e);
        }
      });

    if (inputRef.current) {
      // Unfocus the text box upon choosing a selection. This is important on mobile,
      // because otherwise the keyboard will hide the card.
      inputRef.current.blur();
    }
  };

  if (!state.loadedMaps) {
    return <div></div>;
  }

  let searchOpts = {
    location: new window.google.maps.LatLng(CENTER_OF_SAN_FRANCISCO[0], CENTER_OF_SAN_FRANCISCO[1]),
    radius: BOUNDS_METERS,
  };
  return (
    <>
      <PlacesAutocomplete
        value={state.address}
        searchOptions={searchOpts}
        onChange={handleChange.bind(this)}
        onSelect={handleSelect.bind(this)}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <>
            <div className="relative w-full mx-auto flex gap-2">
              <input
                ref={inputRef}
                {...getInputProps({
                  id: 'addressFinder',
                  placeholder: 'Your Street Address',
                  className:
                    'placeholder:text-brand-gray-4 placeholder:opacity-50 border-brand-gray-3 bg-indigo-50 focus:border-brand-blue-4 focus:ring-brand-blue-4 rounded-sm w-full',
                })}
              />
              {state.address && !state.addressSelected ? (
                <div className="mt-12 absolute mb-2 min-w-full bg-white rounded-md border-gray-300 shadow-xl flex flex-col border p-5 z-10">
                  {loading && (
                    <svg
                      className="animate-spin h-5 w-5 text-blue self-center my-12"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? 'cursor-pointer flex items-center px-3 py-2 text-sm font-medium rounded-md bg-gray-100 text-gray-900'
                      : 'cursor-pointer flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-600 hover:bg-gray-50 hover:text-gray-900';
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style: {},
                        })}
                        key={index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
              <SuperButton
                size="sm"
                color="blue"
                className="max-w-[77px] !w-full transition-all ease-in-out duration-500"
                onClick={() => handleSelect(state.address)}
                disabled={!state.address || changeCenterLoading}
              >
                {changeCenterLoading ? (
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 text-white animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                ) : (
                  'Find'
                )}
              </SuperButton>
            </div>
          </>
        )}
      </PlacesAutocomplete>
    </>
  );
};

export default AddressToDistrict;

import React from 'react';
import { twMerge } from 'tailwind-merge';
import { SignupForm } from '../signup';
import BlueBgWrapper from './blueBgWrapper';
import arrowDecorator from '../../images/icons/icon-arrows-decorator.svg';

const CampaignSignup = ({ children, title, id, className }) => {
  return (
    <BlueBgWrapper className={twMerge('not-prose flex flex-col gap-2 lg:p-5 lg:my-0', className)}>
      <div>
        <span className="font-bold text-brand-gray-5 text-lg lg:text-xl">
          {title || 'Follow along'}
        </span>
        <img src={arrowDecorator} width={41} height={13} alt="Arrow Decorator" />
      </div>
      {children && <span className="text-base leading-6 text-gray-700">{children}</span>}
      <SignupForm formStyle="home" list_id={id || ''} />
    </BlueBgWrapper>
  );
};

export default CampaignSignup;

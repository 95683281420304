import React from 'react';
import { Helmet } from 'react-helmet';

export const VoterGuideFAQ = ({ contests, permalink }) => {
  let measures = Object.entries(contests).filter(([key, contest]) => contest.type == 'Measure');

  var questions = measures.map(([key, contest]) => ({
    '@type': 'Question',
    name: 'What is Proposition ' + contest.label,
    acceptedAnswer: {
      '@type': 'Answer',
      text:
        '<p>' +
        contest.faq +
        '</p><p>Read more at <a href="' +
        permalink +
        '#' +
        contest.slug +
        '">' +
        permalink +
        '#' +
        contest.slug +
        '</a><p>',
    },
  }));
  const data = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: questions,
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(data, null, 2)}</script>
    </Helmet>
  );
};

import React from 'react';
import loadable from '@loadable/component';
const Table = loadable(() => import('./layout/table.js'));
import { Bold } from './helpers';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const Candidates = ({ contest, candidates }) => {
  const tableHeaders = [
    {
      Header: 'Candidate',
      accessor: 'candidate',
    },
    {
      Header: 'Biography',
      accessor: 'biography',
    },
    {
      Header: 'Questionnaire',
      accessor: 'questionnaire',
    },
  ];

  const tableContent = candidates.map((candidate) => ({
    candidate: candidate.endorsed ? <Bold>✅ {candidate.name}</Bold> : candidate.name,
    biography: candidate.bio,
    questionnaire: candidate.questionnaire ? (
      <OutboundLink className="text-blue-600 underline" href={candidate.questionnaire}>
        Read it
      </OutboundLink>
    ) : (
      'Did not complete the GrowSF Questionnaire'
    ),
  }));

  return <Table tableData={tableContent} columnData={tableHeaders} />;
};

export default Candidates;

import React from 'react';
import { shuffle } from '../../src/utils/sort.js';

export const RandomOrderList = ({ listItems }) => {
  const shuffledList = shuffle(listItems);
  return (
    <ul>
      {shuffledList.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ul>
  );
};

export const RandomOrderString = ({ listItems }) => {
  const shuffledList = shuffle(listItems);
  const lf = new Intl.ListFormat('en');
  return lf.format(shuffledList);
};

export const RandomMayorList = () => (
  <RandomOrderList listItems={['London Breed', 'Mark Farrell', 'Daniel Lurie']} />
);

export const RandomMayorString = () => (
  <RandomOrderString listItems={['London Breed', 'Mark Farrell', 'Daniel Lurie']} />
);
import React, { useEffect } from 'react';
import { trackPetitionSignature } from '../analytics';
import './petition-embed.css';

function petitionHTMLId(petitionId) {
  return `can-petition-area-${petitionId}`;
}

// `petitionId` is the slug-ified title of the petition. The easiest way to get it
// is via the url. Example:
// https://actionnetwork.org/petitions/save-some-very-important-thing/
// => `save-some-very-important-thing` is the id.
export const PetitionEmbed = ({ petitionId, showImage }) => {
  useEffect(() => {
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.src = `https://actionnetwork.org/widgets/v4/petition/${petitionId}?format=js&source=widget&style=full`;
    head.appendChild(script);

    let link = document.createElement('link');
    link.href = 'https://actionnetwork.org/css/style-embed-whitelabel-v3.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    head.appendChild(link);

    document.addEventListener(`${petitionHTMLId(petitionId)}_submitted`, () => {
      trackPetitionSignature({
        petition: petitionId,
        path: window.location.pathname,
      });
    });
  });

  let className = showImage ? 'show-image' : 'no-image';
  return (
    <div className={`petition-embed ${className}`}>
      <div id={petitionHTMLId(petitionId)} style={{ width: '100%' }}></div>
    </div>
  );
};

import React, { useEffect } from 'react';

export const trackSignUp = ({ method, path, email }) => {
  // Track a mailing list sign up
  const data = {
    method: method,
    path: path,
    email: email,
  };
  track('sign_up', data);
};

export const trackPetitionSignature = ({ petition, path }) => {
  // Track a petition signature
  const data = {
    petition: petition,
    path: path,
  };
  track('petition_signature', data);
};

const track = (type, data) => {
  // https://github.com/gatsbyjs/gatsby/issues/12680#issuecomment-474785770
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    const event = { event: type, ...data };
    window.dataLayer.push(event);
  } else {
    console.log('No window found');
  }
};

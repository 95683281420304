import { TLDR } from "../../../../src/components/election/tldr";
import PhotoWrapper from "../../../../src/components/election/photoWrapper";
import BgWhiteWrapper from "../../../../src/components/election/bgWhiteWrapper";
import { MeasureInfo } from "../../../../src/components/election/measure";
import { CandidateInfo, JudgeInfo } from "../../../../src/components/election/candidate_info";
import { VoterGuideFAQ } from "../../../../src/components/election/voter-guide-faq";
import * as supervisorData from "../../../../common/data/supervisor_data.json";
import { nov22_contests, nov22_vacancy_table_data, nov22_vacancy_table_headers } from "../../../../src/data/data-nov-2022.js";
import * as React from 'react';
export default {
  TLDR,
  PhotoWrapper,
  BgWhiteWrapper,
  MeasureInfo,
  CandidateInfo,
  JudgeInfo,
  VoterGuideFAQ,
  supervisorData,
  nov22_contests,
  nov22_vacancy_table_data,
  nov22_vacancy_table_headers,
  React
};
import React from 'react';

const Burger = ({ show }) => {
  return (
    <svg
      className="w-full h-full overflow-hidden"
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${show ? 'hidden' : 'block'}`}
        fill="#1271A7"
        d="M 24.999 125 C 11.196 125 0 113.804 0 99.999 C 0 86.193 11.196 75 24.999 75 L 474.999 75 C 488.806 75 500 86.193 500 99.999 C 500 113.804 488.806 125 474.999 125 L 24.999 125 Z"
      />
      <path
        className={`origin-center ${show ? 'rotate-45' : ''}`}
        fill="#1271A7"
        d="M 24.999 273.148 C 11.193 273.148 0 261.954 0 248.147 C 0 234.341 11.193 223.148 24.999 223.148 L 475.001 223.148 C 488.806 223.148 500 234.341 500 248.147 C 500 261.954 488.806 273.148 475.001 273.148 L 24.999 273.148 Z"
      />
      <path
        className={`origin-center ${show ? '-rotate-45' : ''}`}
        fill="#1271A7"
        d="M 24.999 275 C 11.193 275 0 263.806 0 249.999 C 0 236.193 11.193 225 24.999 225 L 475.001 225 C 488.806 225 500 236.193 500 249.999 C 500 263.806 488.806 275 475.001 275 L 24.999 275 Z"
      />
      <path
        className={`${show ? 'hidden' : 'block'}`}
        fill="#1271A7"
        d="M 24.999 424.854 C 11.194 424.854 0 413.662 0 399.855 C 0 386.049 11.194 374.854 24.999 374.854 L 475.001 374.854 C 488.807 374.854 500 386.049 500 399.855 C 500 413.662 488.807 424.854 475.001 424.854 L 24.999 424.854 Z"
      />
    </svg>
  );
};

export default Burger;

export const strongSupport = '#64D09C';
export const weakSupport =
  'linear-gradient(0deg, rgba(210, 219, 220, 0.50) 0%, rgba(210, 219, 220, 0.50) 100%), #64D09C';
export const weakOppose =
  'linear-gradient(0deg, rgba(210, 219, 220, 0.50) 0%, rgba(210, 219, 220, 0.50) 100%), #FEDC6B';
export const strongOppose = '#FEDC6B';
export const neutral = '#D2DBDC';

export const goodPalette = [
  'linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.20) 0%, rgba(210, 219, 220, 0.20) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.40) 0%, rgba(210, 219, 220, 0.40) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.60) 0%, rgba(210, 219, 220, 0.60) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.80) 0%, rgba(210, 219, 220, 0.80) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
];

export const extendedGoodPalette = (range) => {
  const palette = [];
  for (let i = 0; i < range; i++) {
    palette.push(
      `linear-gradient(0deg, rgba(210, 219, 220, ${i / range}) 0%, rgba(210, 219, 220, ${
        i / range
      }) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B`
    );
  }
  return palette;
};

export const reversedGoodPalette = [
  'linear-gradient(0deg, rgba(210, 219, 220, 0.80) 0%, rgba(210, 219, 220, 0.80) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.60) 0%, rgba(210, 219, 220, 0.60) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.40) 0%, rgba(210, 219, 220, 0.40) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.20) 0%, rgba(210, 219, 220, 0.20) 100%), linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
  'linear-gradient(0deg, #64D09C 0%, #64D09C 100%), #FEDC6B',
];

export const badPalette = [
  '#FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.20) 0%, rgba(210, 219, 220, 0.20) 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.40) 0%, rgba(210, 219, 220, 0.40) 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.60) 0%, rgba(210, 219, 220, 0.60) 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.80) 0%, rgba(210, 219, 220, 0.80) 100%), #FEDC6B',
];

export const reversedBadPalette = [
  'linear-gradient(0deg, rgba(210, 219, 220, 0.80) 0%, rgba(210, 219, 220, 0.80) 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.60) 0%, rgba(210, 219, 220, 0.60) 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.40) 0%, rgba(210, 219, 220, 0.40) 100%), #FEDC6B',
  'linear-gradient(0deg, rgba(210, 219, 220, 0.20) 0%, rgba(210, 219, 220, 0.20) 100%), #FEDC6B',
  '#FEDC6B',
];

export const bluePalette = [
  '#4BADE4',
  'linear-gradient(0deg, rgba(23, 40, 65, 0.40) 0%, rgba(23, 40, 65, 0.40) 100%), #4BADE4',
  'linear-gradient(0deg, rgba(23, 40, 65, 0.66) 0%, rgba(23, 40, 65, 0.66) 100%), #4BADE4',
  '#172841',
];

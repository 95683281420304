import { JURISDICTION, ENDORSEMENT } from '../components/election/constants.js';
import { Measure } from '../components/election/data_objs.js';

export const da_recall_contests = {
  'Proposition H': Measure(
    JURISDICTION.LOCAL,
    'Recall Measure Regarding Chesa Boudin',
    'H',
    ENDORSEMENT.YES
  ),
};

import React from 'react';
import { RedText, Bold } from '../components/helpers';

export const people = [
  {
    name: 'Kevin Paffrath',
    title: 'Registered Democrat',
    description: (
      <>
        Kevin Paffrath is a{' '}
        <a href="https://archive.curbed.com/2019/11/15/20963196/youtube-video-advice-real-estate-landlord">
          'landlord youtuber'
        </a>{' '}
        and registered Democrat who has zero political experience and has never held office.
        Paffrath says he will use emergency powers and the national guard to build homeless shelters
        and other housing. Paffrath opposes both mask and vaccine mandates.
      </>
    ),
  },
  {
    name: 'Kevin Faulconer',
    title: 'Moderate Republican',
    description: (
      <>
        Kevin Faulconer, a Republican, is the former mayor of San Diego, where he served two terms.
        He was elected in a majority-Democratic city and worked across the aisle with the
        Democratic-controlled city hall to legalize new high-density housing, build homeless
        shelters,{' '}
        <a href="https://www.kevinfaulconer.com/reducing-homelessness">
          decrease homelessness by 12%
        </a>
        , and support the rights of LGBTQ people. Faulconer opposes both mask mandates and vaccine
        mandates, though he is pro-vaccine and thinks everyone should get it.
      </>
    ),
  },
  {
    name: 'Kevin Kiley',
    title: 'Conservative Republican',
    description: (
      <>
        Kevin Kiley is a conservative Republican who currently holds office in the State Assembly.
        He sits on the Housing and Community Development committee, where he voted in favor of
        legalizing 4-plexes state-wide, among other committees. Kiley co-authored SB50 with Senator
        Scott Wiener, which would have legalized apartments near transit, statewide. Kiley opposes
        both mask and vaccine mandates.
      </>
    ),
  },
  {
    name: 'Larry Elder',
    title: 'Right-Libertarian',
    description: (
      <>
        Larry Elder is currently in first place and is a right-wing talk radio host who wants to{' '}
        <a href="https://www.kqed.org/news/11881314/zero-political-experience-makes-me-a-great-candidate-for-governor-says-talk-show-host">
          ban abortions
        </a>
        ,{' '}
        <a href="https://www.mercurynews.com/2021/07/29/5-things-to-know-about-recall-candidate-larry-elder/">
          abolish gun regulations, abolish the IRS, and end welfare
        </a>
        . If you just vote No on the recall without voting for a backup option, he'll be our next governor. Elder opposes
        both mask and vaccine mandates.
      </>
    ),
  },
  {
    name: 'John Cox',
    title: 'Conservative Republican',
    description: (
      <>
        John Cox (you know...{' '}
        <a href="https://www.nbcsandiego.com/news/national-international/john-cox-campaign-being-investigated-for-bringing-1k-pound-bear-to-san-diego/2602998/">
           the guy with the bear
        </a>
        ) is polling in second place. He is a conservative Republican who ran for governor in 2018 with a{' '}
        <a href="https://www.nbcsandiego.com/news/national-international/john-cox-campaign-being-investigated-for-bringing-1k-pound-bear-to-san-diego/2602998/">
           platform
        </a>{' '}
        of building a border wall and banning abortion, and was endorsed by President Trump. Cox opposes both mask
        and vaccine mandates.
      </>
    ),
  },
  {
    name: 'Caitlyn Jenner',
    title: 'Republican',
    description: (
      <>
        Caitlyn Jenner isn't polling well, but she has a decent shot of winning on name recognition alone. She has
        zero governing experience, and has pledged to{' '}
        <a href="https://www.nydailynews.com/entertainment/caitlyn-jenner-gop-shooting-liberals-shoot-straight-article-1.3257178">
           leave the Paris Climate Accords
        </a>
        . Jenner opposes mask mandates but has no clear position on vaccines.
      </>
    ),
  },
];

export const tableHeaders = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: 'Kevin Paffrath',
    accessor: 'paffrath',
  },
  {
    Header: 'Kevin Faulconer',
    accessor: 'faulconer',
  },
  {
    Header: 'Kevin Kiley',
    accessor: 'kiley',
  },
  {
    Header: 'Larry Elder',
    accessor: 'elder',
  },
  {
    Header: 'John Cox',
    accessor: 'cox',
  },
  {
    Header: 'Caitlyn Jenner',
    accessor: 'jenner',
  },
];

export const candidateTableData = [
  {
    issue: <Bold>Party</Bold>,
    paffrath: 'Democratic',
    faulconer: 'Republican',
    kiley: 'Republican',
    elder: 'Republican',
    cox: 'Republican',
    jenner: 'Republican',
  },
  {
    issue: <Bold>Ideology</Bold>,
    paffrath: (
      <a href="https://archive.curbed.com/2019/11/15/20963196/youtube-video-advice-real-estate-landlord">
        Landlord YouTuber
      </a>
    ),
    faulconer: 'Center-right',
    kiley: 'Conservative',
    elder: 'Right Libertarian',
    cox: 'Right',
    jenner: 'Right',
  },
  {
    issue: <Bold>Mask Mandate</Bold>,
    paffrath: (
      <a href="https://www.abc10.com/article/news/politics/recall-candidates-mask-vaccine-mandates/103-c6f1d240-8b55-4a46-ba4f-ee34c7762e41">
        Anti state-level
      </a>
    ),
    faulconer: (
      <a href="https://www.mercurynews.com/2021/07/27/recall-candidate-kevin-faulconer-says-no-to-mask-mandates-during-san-francisco-campaign-stop/">
        Anti
      </a>
    ),
    kiley: (
      <a href="https://www.abc10.com/article/news/politics/recall-candidates-mask-vaccine-mandates/103-c6f1d240-8b55-4a46-ba4f-ee34c7762e41">
        Anti state-level
      </a>
    ),
    elder: (
      <a href="https://www.mercurynews.com/2021/07/29/5-things-to-know-about-recall-candidate-larry-elder/">
        Anti
      </a>
    ),
    cox: (
      <a href="https://www.kusi.com/gubernatorial-candidate-john-cox-criticizes-mask-mandates-and-recommendations/">
        Anti
      </a>
    ),
    jenner: <a href="https://mobile.twitter.com/Caitlyn_Jenner/status/1420099507245572101">Anti</a>,
  },
  {
    issue: <Bold>Vaccine Mandate</Bold>,
    paffrath: (
      <a href="https://www.abc10.com/article/news/politics/recall-candidates-mask-vaccine-mandates/103-c6f1d240-8b55-4a46-ba4f-ee34c7762e41">
        Anti state-level
      </a>
    ),
    faulconer: (
      <a href="https://www.sacbee.com/news/politics-government/capitol-alert/article253038588.html">
        Anti
      </a>
    ),
    kiley: (
      <a href="https://www.abc10.com/article/news/politics/recall-candidates-mask-vaccine-mandates/103-c6f1d240-8b55-4a46-ba4f-ee34c7762e41">
        Anti state-level
      </a>
    ),
    elder: (
      <a href="https://www.mercurynews.com/2021/07/29/5-things-to-know-about-recall-candidate-larry-elder/">
        Anti
      </a>
    ),
    cox: (
      <a href="https://www.sacbee.com/news/politics-government/capitol-alert/article253038588.html">
        Anti
      </a>
    ),
    jenner: <RedText>unknown</RedText>,
  },
  {
    issue: <Bold>Pro-vaccine?</Bold>,
    paffrath: <RedText>unknown</RedText>,
    faulconer: <a href="https://twitter.com/emily_hoeven/status/1423088912495304708">Yes</a>,
    kiley: (
      <a href="https://www.latimes.com/business/story/2021-08-10/gop-wants-california-to-fight-covid-like-florida-more-deaths">
        Unclear
      </a>
    ),
    elder: (
      <a href="https://www.yahoo.com/now/ca-recall-candidate-larry-elder-231956451.html">Yes</a>
    ),
    cox: (
      <a href="https://www.latimes.com/business/story/2021-08-10/gop-wants-california-to-fight-covid-like-florida-more-deaths">
        No?
      </a>
    ),
    jenner: <RedText>unknown</RedText>,
  },
  {
    issue: <Bold>Abortion</Bold>,
    paffrath: <RedText>unknown</RedText>,
    faulconer: (
      <a href="https://www.sfchronicle.com/politics/article/This-pro-choice-Mexico-embracing-mayor-could-be-14416685.php">
        Pro-choice
      </a>
    ),
    kiley: (
      <a href="https://fox40.com/news/political-connection/planned-parenthood-pro-choice-advocates-show-support-for-newsom/">
        Anti-choice?
      </a>
    ),
    elder: (
      <a href="https://www.kqed.org/news/11881314/zero-political-experience-makes-me-a-great-candidate-for-governor-says-talk-show-host">
        Anti-choice
      </a>
    ),
    cox: (
      <a href="https://abc7news.com/california-governor-gov-guide-voter/4463965/">Anti-choice</a>
    ),
    jenner: <RedText>unknown</RedText>,
  },
  {
    issue: <Bold>Climate Change</Bold>,
    paffrath: <RedText>unknown</RedText>,
    faulconer: (
      <a href="https://www.sfchronicle.com/politics/article/This-pro-choice-Mexico-embracing-mayor-could-be-14416685.php">
        Believes
      </a>
    ),
    kiley: (
      <a href="https://goldcountrymedia.com/news/146928/4-questions-for-senate-district-1-candidates/">
        Believes
      </a>
    ),
    elder: (
      <a href="https://abc7news.com/10923239/">
        Believes it's happening, but isn't causing disasters
      </a>
    ),
    cox: (
      <a href="https://www.sfchronicle.com/politics/article/Gavin-Newsom-John-Cox-a-world-apart-on-13232931.php">
        Believes
      </a>
    ),
    jenner: (
      <a href="https://www.nydailynews.com/entertainment/caitlyn-jenner-gop-shooting-liberals-shoot-straight-article-1.3257178">
        Leave Paris Accords
      </a>
    ),
  },
  {
    issue: <Bold>Education</Bold>,
    paffrath: (
      <a href="https://www.meetkevin.com/">
        "Future Schools" for job training for high-income fields
      </a>
    ),
    faulconer: (
      <a href="https://www.kevinfaulconer.com/classroom-education-should-be-norm-not-exception">
        Funding for summer school, tutoring, healthcare. More choice
      </a>
    ),
    kiley: (
      <a href="https://www.politico.com/states/california/story/2021/07/21/kiley-focuses-on-school-vouchers-in-california-recall-campaign-1388829">
        Education savings accounts, vouchers for private shools
      </a>
    ),
    elder: <a href="https://www.electelder.com/vision/">Fan of Charters</a>,
    cox: (
      <a href="https://www.desertsun.com/story/news/education/2018/10/25/candidates-governor-answer-questions-coachella-valley-educators/1755319002/">
        Supports charters, pay teachers more
      </a>
    ),
    jenner: <RedText>unknown</RedText>,
  },
  {
    issue: <Bold>Immigration</Bold>,
    paffrath: (
      <a href="https://www.meetkevin.com/">
        Unclear what he means by "We have a massive immigration problem. Much of this is due to an
        antiquated “legal” immigration process."
      </a>
    ),
    faulconer: (
      <a href="https://twitter.com/Kevin_Faulconer/status/1102997427781423104">
        Pro-immigrant, wants immigration reform and border security
      </a>
    ),
    kiley: (
      <a href="https://justfacts.votesmart.org/candidate/key-votes/169303/kevin-kiley/40/immigration">
        Punish undocumented immigrants, end Sanctuary State
      </a>
    ),
    elder: <RedText>unknown</RedText>,
    cox: (
      <a href="https://abc7news.com/california-governor-gov-guide-voter/4463965/">
        End Sanctuary State, Build border wall
      </a>
    ),
    jenner: (
      <a href="https://www.cnn.com/2021/05/09/politics/caitlyn-jenner-immigration-cnntv/index.html">
        Path to citizenship for undocumented immigrants
      </a>
    ),
  },
  {
    issue: <Bold>Housing</Bold>,
    paffrath: (
      <a href="https://www.meetkevin.com/">
        Use Emergency Powers to build 500k homes per year for 5 years. State control of building and
        safety codes
      </a>
    ),
    faulconer: (
      <a href="https://calmatters.org/housing/2019/03/housing-san-diego-mayor-became-yimby/">
        Generally YIMBY, but would veto statewide fourplex zoning and opposes ending exclusionary
        zoning
      </a>
    ),
    kiley: (
      <div>
        <a href="https://cayimby.org/sb-50/">
          YIMBY, co-authored SB50 & voted Yes on SB9 in committee
        </a>
        . Calls housing "core issue".
      </div>
    ),
    elder: (
      <a href="https://www.yahoo.com/news/larry-elder-says-implement-ben-025034649.html">
        Suspend CEQA and decrease regulations to lower cost of market-rate development
      </a>
    ),
    cox: (
      <a href="https://www.desertsun.com/story/opinion/columnists/2019/05/24/we-need-more-than-sb-50-address-californias-housing-crisis-john-cox-column/1223905001/">
        Tacitly supported SB50 but thinks CEQA reform and reducing labor/environmental regulations
        are more important. Mainly reduce cost of construction.
      </a>
    ),
    jenner: (
      <a href="https://caitlynjenner.com/solutions">
        Vague language around "reducing regulations," supports both affordable and market housing
      </a>
    ),
  },
  {
    issue: <Bold>Homelessness</Bold>,
    paffrath: (
      <a href="https://www.meetkevin.com/">
        Use the national guard to build shelters and forcibly move people into them
      </a>
    ),
    faulconer: (
      <a href="https://www.kevinfaulconer.com/reducing-homelessness">
        Supports building shelters and forcing treatment when available. Decreased homelessness in
        San Diego as mayor
      </a>
    ),
    kiley: (
      <a href="https://blog.electkevinkiley.com/why-gov-newsom-killed-my-homelessness-spending-audit/">
        Supports drug & mental illness treatment, job training, and permanent housing for homeless
      </a>
    ),
    elder: (
      <div>
        <a href="https://www.yahoo.com/news/larry-elder-says-implement-ben-025034649.html">
          Institutionalize mentally ill.
        </a>{' '}
        Says most homeless are "probably alcoholics, mentally ill, or have substance abuse
        problems".{' '}
        <a href="https://calmatters.org/projects/newsom-recall-candidates-larry-elder/">
          Churches instead of government to serve homeless.
        </a>
      </div>
    ),
    cox: (
      <div>
        <a href="https://www.latimes.com/california/story/2021-06-27/gubenatorial-candidate-john-fox-proposes-plan-to-deal-with-states-homeless">
          Opposes "housing first," which offers housing regardless of drug, alcohol, or mental
          health issues.
        </a>{' '}
        Supports greater enforcement, would ignore 9th Circuit court order requiring shelters be
        available before clearing encampments
      </div>
    ),
    jenner: (
      <a href="https://fox40.com/inside-california-politics/caitlyn-jenner-suggests-moving-homeless-to-big-open-fields/">
        Move homeless to "big open fields"
      </a>
    ),
  },
  {
    issue: <Bold>Tenant Protections</Bold>,
    paffrath: (
      <a href="https://archive.curbed.com/2019/11/15/20963196/youtube-video-advice-real-estate-landlord">
        Against
      </a>
    ),
    faulconer: (
      <div>
        <a href="https://www.millionacres.com/real-estate-market/articles/cities-and-states-that-have-paused-evictions-due-to-covid-19/">
          Supported COVID eviction pause
        </a>
        .{' '}
        <a href="https://timesofsandiego.com/opinion/2020/11/02/prop-21s-radical-rent-control-will-make-californias-housing-crisis-worse/">
          Against rent control'
        </a>
      </div>
    ),
    kiley: (
      <a href="https://justfacts.votesmart.org/candidate/169303/kevin-kiley?categoryId=39&type=V,S,R,E,F,P">
        Against
      </a>
    ),
    elder: <a href="https://www.brainyquote.com/quotes/larry_elder_480832">Opposes rent control</a>,
    cox: (
      <div>
        <a href="https://timesofsandiego.com/politics/2018/06/17/republican-governor-hopeful-john-cox-opposes-rent-control-initiative/">
          Opposes rent control
        </a>{' '}
        and likely other tenant protections
      </div>
    ),
    jenner: <RedText>unknown</RedText>,
  },
  /*{
      'issue': 'Crime',
      'paffrath': <RedText>unknown</RedText>,
      'faulconer': <RedText>unknown</RedText>,
      'kiley': <RedText>unknown</RedText>,
      'elder': <RedText>unknown</RedText>,
      'cox': <RedText>unknown</RedText>,
      'jenner': <RedText>unknown</RedText>,
    },*/
  {
    issue: <Bold>US Senate if Feinstein vacates</Bold>,
    paffrath: <RedText>unknown</RedText>,
    faulconer: <RedText>unknown</RedText>,
    kiley: 'Would not make appointment, leave it up to voters',
    elder: <RedText>unknown</RedText>,
    cox: <RedText>unknown</RedText>,
    jenner: <RedText>unknown</RedText>,
  },
  {
    issue: <Bold>Prior Government Experience?</Bold>,
    paffrath: 'No',
    faulconer: 'Yes, former mayor of San Diego',
    kiley: 'Yes, sitting Assemblymember',
    elder: 'No',
    cox: 'No',
    jenner: 'No',
  },
];

import React from 'react';
import {
  PairwiseAffinityByRoundScenario,
  SimpleScenario,
} from '../../../src/components/rcv/scenarios';
import { Ballot, BallotSet } from '../../../src/components/rcv/ballot';

/*
NOTES:
SF Voting population: 500,000
Expected turnout: 80%
*/
const voterUniverse = 500000 * 0.8;
const Mayor0926 = new PairwiseAffinityByRoundScenario({
  name: 'San Francisco Mayoral Election 2024 RCV Simulation',
  notes:
    'GrowSF polled on the San Francisco Mayoral election for November 2024, and this is how the election would unfold if the election were held today.',
  firstRankVotes: {
    Breed: voterUniverse * 0.3339,
    Farrell: voterUniverse * 0.1697,
    Lurie: voterUniverse * 0.1821,
    Peskin: voterUniverse * 0.1633,
    Safai: voterUniverse * 0.0368,
    Other: voterUniverse * 0.0052,
  },
  // Each round of voting gets its own affinity matrix. If you input fewer than the max number of rounds, the last affinity matrix will be used for the remaining rounds.
  pairwiseAffinity: [
    {
      // 1st to 2nd
      Breed: {
        Breed: 0.2304,
        Farrell: 0.1929,
        Lurie: 0.305,
        Peskin: 0.1196,
        Safai: 0.1484,
        Other: 0.0037,
      },
      Farrell: {
        Breed: 0.169,
        Farrell: 0.1136,
        Lurie: 0.5521,
        Peskin: 0.0983,
        Safai: 0.0418,
        Other: 0.0252,
      },
      Lurie: {
        Breed: 0.2764,
        Farrell: 0.3067,
        Lurie: 0.1779,
        Peskin: 0.0853,
        Safai: 0.1535,
        Other: 0.0,
      },
      Other: {
        Breed: 0.519,
        Farrell: 0.3408,
        Lurie: 0.1402,
        Peskin: 0.0,
        Safai: 0.0,
        Other: 0.0,
      },
      Peskin: {
        Breed: 0.1285,
        Farrell: 0.1173,
        Lurie: 0.1538,
        Peskin: 0.1385,
        Safai: 0.4555,
        Other: 0.0064,
      },
      Safai: {
        Breed: 0.1566,
        Farrell: 0.0,
        Lurie: 0.1812,
        Peskin: 0.5067,
        Safai: 0.1555,
        Other: 0.0,
      },
    },
    {
      // 2nd to 3rd
      Breed: {
        Breed: 0.5464,
        Farrell: 0.0802,
        Lurie: 0.1811,
        Peskin: 0.0606,
        Safai: 0.1182,
        Other: 0.0135,
      },
      Farrell: {
        Breed: 0.0889,
        Farrell: 0.2201,
        Lurie: 0.332,
        Peskin: 0.1103,
        Safai: 0.2119,
        Other: 0.0368,
      },
      Lurie: {
        Breed: 0.1242,
        Farrell: 0.2052,
        Lurie: 0.3034,
        Peskin: 0.0339,
        Safai: 0.3019,
        Other: 0.0314,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.0,
        Other: 0.627,
        Safai: 0.373,
        Peskin: 0.0,
      },
      Peskin: {
        Breed: 0.112,
        Farrell: 0.0826,
        Lurie: 0.4238,
        Peskin: 0.1906,
        Safai: 0.1595,
        Other: 0.0316,
      },
      Safai: {
        Breed: 0.1239,
        Farrell: 0.0844,
        Lurie: 0.335,
        Peskin: 0.1855,
        Safai: 0.2266,
        Other: 0.0446,
      },
    },
    {
      Breed: {
        Breed: 0.2128,
        Farrell: 0.1277,
        Lurie: 0.0886,
        Peskin: 0.0623,
        Safai: 0.3951,
        Other: 0.1135,
      },
      Farrell: {
        Breed: 0.1076,
        Farrell: 0.2088,
        Lurie: 0.1039,
        Peskin: 0.1835,
        Safai: 0.3663,
        Other: 0.03,
      },
      Lurie: {
        Breed: 0.1349,
        Farrell: 0.1982,
        Lurie: 0.2581,
        Peskin: 0.0395,
        Safai: 0.2599,
        Other: 0.1093,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.0,
        Peskin: 0.0,
        Safai: 0.0,
        Other: 1.0,
      },
      Peskin: {
        Breed: 0.1022,
        Farrell: 0.0775,
        Lurie: 0.1659,
        Peskin: 0.4356,
        Safai: 0.2188,
        Other: 0.0,
      },
      Safai: {
        Breed: 0.1505,
        Farrell: 0.1992,
        Lurie: 0.2407,
        Peskin: 0.1467,
        Safai: 0.1562,
        Other: 0.1068,
      },
    },
  ],
  maxRankings: 4,
});

const weightSum = 369.74448; // sum of WTS column for every row with mayoral rankings
const normalizer = 375 / weightSum; // 375 is the number of valid ballots
const SCALE_FACTOR = (voterUniverse / 416) * normalizer; // 416 is the number of people who answered the question
const bs = new BallotSet(
  [
    new Ballot(Math.round(SCALE_FACTOR * 1.40531), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.80831), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.37702), ['Lurie', 'Farrell', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99864), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.93455), ['Breed', 'Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.52467), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.40969), ['Peskin', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.68839), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64882), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.61943), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.99842), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.94992), ['Breed', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04362), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.36542), ['Farrell', 'Lurie', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.37973), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.92358), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.83122), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.76584), ['Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.12522), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.92643), ['Breed', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.33095), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.28276), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85416), ['Peskin', 'Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.42211), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.33578), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.20478), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.05414), ['Farrell', 'Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.34254), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.87555), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.35189), ['Peskin', 'Safai', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64779), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.25785), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.59377), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.37048), ['Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.3046), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64244), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.9406), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96581), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.3565), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.18479), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.14149), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.4597), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.89606), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53287), ['Peskin', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.51934), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.17697), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.02441), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.92115), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.51779), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64937), ['Peskin', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.38177), ['Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.41902), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 2.09643), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.79496), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.12543), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.08834), ['Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.37086), ['Peskin', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.20449), ['Breed', 'Lurie', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.12765), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.72261), ['Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.18619), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.73146), ['Peskin', 'Breed', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.27185), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.70595), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.87357), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01402), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.80829), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.95447), ['Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04964), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.29386), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.46234), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84808), ['Safai', 'Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.72246), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.67022), ['Lurie', 'Farrell', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.7543), ['Lurie', 'Safai', 'Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.7543), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.30524), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.25479), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04912), ['Lurie', 'Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75761), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.87273), ['Lurie', 'Farrell', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.89695), ['Lurie', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.31344), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.05923), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.49824), ['Breed', 'Lurie', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.52317), ['Breed', 'Lurie', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.32207), ['Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.58835), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.78764), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.22705), ['Farrell', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96838), ['Lurie', 'Safai', 'Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53091), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.03523), ['Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.54408), ['Lurie', 'Breed', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.60999), ['Peskin', 'Safai', 'Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.72848), ['Farrell', 'Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.52836), ['Lurie', 'Farrell', 'Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.14926), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21142), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.29922), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.41258), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.90386), ['Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.49209), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.24472), ['Farrell', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53408), ['Breed', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.49214), ['Peskin', 'Lurie', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84451), ['Breed', 'Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.41794), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.56554), ['Breed', 'Safai', 'Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.77197), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.61548), ['Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64958), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.91865), ['Farrell', 'Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.60814), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.23705), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.70426), ['Breed', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.0129), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99381), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6881), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.24872), ['Peskin', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57693), ['Breed', 'Farrell', 'Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.868), ['Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 2.34018), ['Farrell', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.80644), ['Lurie', 'Farrell', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.73897), ['Peskin', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04149), ['Breed', 'Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.11673), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.02773), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64196), ['Peskin', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.59078), ['Farrell', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.12087), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.06551), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.72763), ['Lurie', 'Safai', 'Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.28201), ['Peskin', 'Safai', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.37966), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99971), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.15393), ['Peskin', 'Safai', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50494), ['Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.18639), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.51293), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01621), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01621), ['Peskin', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.38161), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.51277), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 3.56578), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86382), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.32185), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.29016), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.30161), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.61894), ['Lurie', 'Farrell', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.43997), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.88635), ['Lurie', 'Safai', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.77147), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.91212), ['Farrell', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44255), ['Safai', 'Lurie', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.51422), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.45478), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 2.39578), ['Breed', 'Peskin', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.41178), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75688), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.59143), ['Safai', 'Peskin', 'Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57531), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.68379), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04158), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.65303), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.09974), ['Breed', 'Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.50157), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.65854), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.37516), ['Farrell', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.93357), ['Lurie', 'Farrell', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.00006), ['Peskin', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.31393), ['Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.17778), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.69883), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.69629), ['Farrell', 'Peskin', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.54305), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50679), ['Safai', 'Peskin', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.12556), ['Lurie', 'Breed', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.59958), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.77818), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.30011), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40042), ['Farrell', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.95548), ['Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.59015), ['Lurie', 'Safai', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.94566), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.50693), ['Peskin', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 4.00359), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.15276), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21351), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 2.33649), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21138), ['Breed', 'Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.26646), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.39445), ['Breed', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.31174), ['Lurie', 'Safai', 'Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.94517), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.81313), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.20643), ['Farrell', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63585), ['Lurie', 'Peskin', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.08664), ['Lurie', 'Peskin', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.73885), ['Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.68073), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27389), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.46211), ['Breed', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.9825), ['Peskin', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.67331), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.25739), ['Breed', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.18407), ['Breed', 'Lurie', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.24224), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.55702), ['Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85746), ['Peskin', 'Breed', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.6961), ['Breed', 'Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.43058), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.47191), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.99228), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.13026), ['Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.06926), ['Peskin', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.35496), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84699), ['Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.57743), ['Safai', 'Peskin', 'Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.58236), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.60901), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.7379), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.94583), ['Farrell', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44107), ['Peskin', 'Breed', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.5177), ['Breed', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.3592), ['Peskin', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53033), ['Lurie', 'Breed', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.58047), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.70253), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.12058), ['Peskin', 'Safai', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84493), ['Breed', 'Safai', 'Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84493), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.6101), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.71818), ['Breed', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 2.43898), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.28887), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 2.35659), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.49303), ['Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21015), ['Breed', 'Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 2.79506), ['Farrell', 'Peskin', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.39366), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.67274), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.787), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.4334), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63363), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.54917), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.56681), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.28464), ['Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27504), ['Lurie', 'Breed', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 2.66171), ['Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84847), ['Lurie', 'Farrell', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 3.28671), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.07299), ['Peskin', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.35646), ['Lurie', 'Breed', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.29312), ['Lurie', 'Farrell', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.90708), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.0362), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.43361), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.80486), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.35964), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.2927), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.21209), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.78081), ['Breed', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.46553), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.06194), ['Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99059), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.57515), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.515), ['Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.18514), ['Breed', 'Safai', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.18253), ['Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.49717), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27814), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.03093), ['Breed', 'Lurie', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.32499), ['Breed', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.71068), ['Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75564), ['Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.35442), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21494), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96245), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.78806), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.84019), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82555), ['Lurie', 'Breed', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.48725), ['Farrell', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40413), ['Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.17009), ['Breed', 'Farrell', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.42368), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.30701), ['Peskin', 'Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.72405), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.49854), ['Farrell', 'Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.36118), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.56189), ['Breed', 'Safai', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.55028), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.43558), ['Lurie', 'Farrell', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.16258), ['Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.08646), ['Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.22876), ['Peskin', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.45733), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.22024), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 4.20033), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85499), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.90497), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.3881), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.27593), ['Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53974), ['Breed', 'Safai', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75078), ['Breed', 'Farrell', 'Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 3.91436), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.43875), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.70544), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01642), ['Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40692), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.74336), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.88996), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01436), ['Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.39221), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.85152), ['Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.4439), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.31774), ['Peskin', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53813), ['Breed', 'Peskin', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.32567), ['Peskin', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.64565), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.33533), ['Breed', 'Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.33533), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.45471), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.25123), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.41872), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.16649), ['Peskin', 'Safai', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.17638), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.37894), ['Peskin', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.20595), ['Peskin', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40227), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.4146), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.36147), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40385), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.24503), ['Breed', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.14088), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.11362), ['Farrell', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.83501), ['Farrell', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.72734), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.45609), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.33091), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57172), ['Lurie', 'Safai', 'Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.16332), ['Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.73769), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.22846), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.41492), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.80356), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 2.03622), ['Peskin', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.03345), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.49605), ['Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.6492), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.57482), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 4.71419), ['Breed', 'Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.18365), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.4294), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.37744), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.33008), ['Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50102), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.2248), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27272), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.94951), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.42862), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.47885), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.81833), ['Breed', 'Peskin', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.20047), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.73318), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.5988), ['Breed', 'Peskin', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.75698), ['Farrell', 'Breed', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.22891), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.19404), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.66758), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.70328), ['Breed', 'Safai', 'Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.28961), ['Safai', 'Peskin', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40848), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96338), ['Safai', 'Peskin', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96052), ['Peskin', 'Safai']),
  ],
  0
);
// 11% undecided
bs.undervotes = Math.round(bs.totalVotes / (1 - 0.11) - bs.totalVotes);
const SimpleWeightedMayor0926 = new SimpleScenario({
  name: 'Simple Mayor 0926',
  notes: 'These are the raw (but weighted) ballots from FM3.',
  ballotSet: bs,
});

export { Mayor0926, SimpleWeightedMayor0926 };

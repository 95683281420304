import { goodPalette, extendedGoodPalette, bluePalette, badPalette, reversedGoodPalette, reversedBadPalette, strongSupport, weakSupport, weakOppose, strongOppose } from "../../../../src/utils/pulsePalette.js";
import direction from "../../../../src/images/icons/direction.svg";
import mayor from "../../../../src/images/icons/mayor.svg";
import government from "../../../../src/images/icons/government.svg";
import updown from "../../../../src/images/icons/updown.svg";
import homeless from "../../../../src/images/icons/homeless.svg";
import drugs from "../../../../src/images/icons/drugs.svg";
import crime from "../../../../src/images/icons/crime.svg";
import transit from "../../../../src/images/icons/transit.svg";
import education from "../../../../src/images/icons/education.svg";
import covid from "../../../../src/images/icons/covid.svg";
import tech from "../../../../src/images/icons/tech.svg";
import { H1 } from "../../../../src/components/layout/headings";
import { SaveAsIcon } from '@heroicons/react/outline';
import { OldLineChart } from "../../../../src/components/pulse/lineChart";
import { RCVCalculator, RCVTableRenderer, RCVSeveralTablesWithExplanationRenderer } from "../../../../src/components/rcv/rcv";
import { PairwiseAffinityTableRenderer } from "../../../../src/components/rcv/scenarios";
import { Mayor0524, SimpleWeightedMayor0524 } from "../../../../content/pulse/growsf-pulse-may-2024-mayor/rcv-scenarios";
import * as React from 'react';
export default {
  goodPalette,
  extendedGoodPalette,
  bluePalette,
  badPalette,
  reversedGoodPalette,
  reversedBadPalette,
  strongSupport,
  weakSupport,
  weakOppose,
  strongOppose,
  direction,
  mayor,
  government,
  updown,
  homeless,
  drugs,
  crime,
  transit,
  education,
  covid,
  tech,
  H1,
  SaveAsIcon,
  OldLineChart,
  RCVCalculator,
  RCVTableRenderer,
  RCVSeveralTablesWithExplanationRenderer,
  PairwiseAffinityTableRenderer,
  Mayor0524,
  SimpleWeightedMayor0524,
  React
};
import React from 'react';

export const recalls = [
  { name: '✅ Yes on Recall Alison Collins' },
  { name: '✅ Yes on Recall Gabriela López' },
  { name: '✅ Yes on Recall Faauuga Moliga' },
];

export const assessor = [
  {
    name: 'Joaquín Torres',
    title: 'Assessor-Recorder',
  },
];

export const assembly = [
  {
    name: 'Bilal Mahmood',
    title: 'Assembly, District 17',
  },
];

export const assemblyCandidates = {
  contest: 'Assembly, District 17',
  candidates: [
    {
      name: 'Bilal Mahmood',
      endorsed: true,
      bio: 'Entrepreneur',
      questionnaire:
        'https://drive.google.com/file/d/1W4zuXUDWcjlT9ZsifWDR2yVLQW-wVtka/view?usp=sharing',
    },
    {
      name: 'Matt Haney',
      bio: 'Supervisor, District 6',
      questionnaire:
        'https://drive.google.com/file/d/1z2a72Kl47LtoFD28bvNAZlywpBz4z4TL/view?usp=sharing',
    },
    {
      name: 'David Campos',
      bio: 'Chief of Staff to District Attorney Chesa Boudin, Former Supervisor of District 9',
    },
    {
      name: 'Thea Selby',
      bio: 'San Francisco Community College Board member',
    },
  ],
};

import React from 'react';
import { PARTY, JURISDICTION, QS, ENDORSEMENT } from '../components/election/constants.js';
import { Contest, Candidate } from '../components/election/data_objs.js';
import { GreenText, RedText, Bold } from '../components/helpers';

export const apr22_contests = {
  'Assembly, District 17': Contest(JURISDICTION.STATE, 'Assembly, District 17', [
    Candidate(
      'Matt Haney',
      PARTY.DEM,
      'Supervisor, District 6',
      'https://drive.google.com/file/d/1z2a72Kl47LtoFD28bvNAZlywpBz4z4TL/view?usp=sharing',
      /*endorsed=*/ true
    ),
    Candidate(
      'David Campos',
      PARTY.DEM,
      'Chief of Staff to District Attorney Chesa Boudin, Former Supervisor of District 9',
      QS.NO_RESPONSE
    ),
  ]),
};

export const aprTableHeaders = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: 'Matt Haney',
    accessor: 'haney',
  },
  {
    Header: 'David Campos',
    accessor: 'campos',
  },
];

export const aprCandidateTableData = [
  {
    issue: <Bold>Pro-housing?</Bold>,
    haney: (
      <div>
        <GreenText>Yes</GreenText>: Haney is endorsed by{' '}
        <a className="text-blue-600 underline" href="https://yimbyaction.org/endorsements/">
          YIMBY Action
        </a>
      </div>
    ),
    campos: (
      <div>
        <RedText>No</RedText>: pushed for{' '}
        <a
          className="text-blue-600 underline"
          href="https://www.spur.org/news/2015-09-18/new-report-says-mission-moratorium-will-only-make-housing-crisis-worse"
        >
          homebuilding moratorium in the Mission
        </a>
        , regularly opposes market-rate housing.
      </div>
    ),
  },
  {
    issue: <Bold>CEQA Reform?</Bold>,
    haney: (
      <div>
        <GreenText>Yes</GreenText>:{' '}
        <a
          className="text-blue-600 underline"
          href="https://mikechensf.medium.com/david-campos-vs-matt-haney-on-ceqa-reform-a8db7bfbe245"
        >
          In favor of reform
        </a>
      </div>
    ),
    campos: (
      <div>
        <RedText>No</RedText>:{' '}
        <a
          className="text-blue-600 underline"
          href="https://mikechensf.medium.com/david-campos-vs-matt-haney-on-ceqa-reform-a8db7bfbe245"
        >
          Against reform
        </a>
        .{' '}
        <a
          className="text-blue-600 underline"
          href="https://docs.google.com/document/d/1wEnq6pETY-5EFzU5Nj-hztyPLE-v1TUapvcZP8SUwno/edit"
        >
          Opposed CEQA exemptions
        </a>{' '}
        for transit and bikes.
      </div>
    ),
  },
  {
    issue: <Bold>Pro-business?</Bold>,
    haney: (
      <div>
        <GreenText>Yes</GreenText>: Supported{' '}
        <a
          className="text-blue-600 underline"
          href="https://www.spur.org/voter-guide/san-francisco-2020-11/prop-h-small-business-initiative"
        >
          Save Our Small Businesses
        </a>{' '}
        ballot prop,{' '}
        <a
          className="text-blue-600 underline"
          href="https://growsf.org/issues/make-shared-spaces-permanent"
        >
          key ally
        </a>{' '}
        to GrowSF for outdoor dining.
      </div>
    ),
    campos: <RedText>Unknown, no recent statements or actions</RedText>,
  },
  {
    issue: <Bold>Pro-bike?</Bold>,
    haney: (
      <div>
        <GreenText>Yes</GreenText>: Created a protected bike lane on Howard, just weeks after
        assuming office. Endorsed by the{' '}
        <a
          className="text-blue-600 underline"
          href="https://sfbike.org/news/our-endorsement-february-15-2022-elections/"
        >
          SF Bike Coalition
        </a>
        .
      </div>
    ),
    campos: <RedText>Unknown, no recent statements or actions</RedText>,
  },
  {
    issue: <Bold>Pro-transit?</Bold>,
    haney: (
      <div>
        <GreenText>Yes</GreenText>: Supports better transit infrastructure including{' '}
        <a
          className="text-blue-600 underline"
          href="https://twitter.com/matthaneysf/status/1326262287020163073"
        >
          bus priority lanes
        </a>{' '}
        and{' '}
        <a
          className="text-blue-600 underline"
          href="https://sanfrancisco.cbslocal.com/2022/03/01/ferry-service-to-san-franciscos-treasure-island-begins-as-thousands-of-new-homes-set-to-be-built/"
        >
          more ferries
        </a>
        . Haney has also worked with Mayor Breed to{' '}
        <a
          className="text-blue-600 underline"
          href="https://www.spur.org/news/2020-12-06/how-san-francisco-can-cut-red-tape-blocks-green-projects"
        >
          speed up transit projects
        </a>
        .
      </div>
    ),
    campos: (
      <div>
        <RedText>No</RedText>: Campos{' '}
        <a
          className="text-blue-600 underline"
          href="https://sfist.com/2016/06/14/despite_safety_gains_effort_to_remo/"
        >
          opposed
        </a>{' '}
        Mission Street bus priority lanes
      </div>
    ),
  },
];

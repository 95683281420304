import React from 'react';

const Times = {
  DEFAULT: '12pm - 4pm',
  SUNDAY_FM: "Sundays during Farmer's market hours",
  HQ: 'Daily 9am - 8pm',
};
const locations = {
  Richmond: [
    { name: 'Laurel Village', address: '3585 California St, San Francisco CA 94118' },
    { name: 'New Lien Hing Supermarket', address: '400 Clement St, San Francisco, CA  94118' },
    { name: 'New May Wah Supermarket', address: '707 Clement St, San Francisco, CA 94118' },
    {
      name: 'Clement Street Farmers Market',
      address: 'Clement St & 3rd Ave, San Francisco, CA 94118',
      times: Times.SUNDAY_FM,
    },
  ],
  Sunset: [
    { name: 'Andronico’s', address: '1200 Irving St, San Francisco, CA 94122' },
    { name: 'Sunset Super: Irving', address: '2425 Irving St, San Francisco, CA 94122' },
    { name: 'Safeway: Noriega', address: '2350 Noriega, San Francisco, CA 94122' },
    { name: 'Ortega Library', address: '3223 Ortega St, San Francisco, CA 94122' },
    { name: 'Safeway: Taraval ', address: '30 Taraval St, San Francisco, CA 94116' },
    { name: 'Sunset Super: Ulloa', address: '2801 Vicente St, San Francisco, CA 94116' },
    { name: 'Lucky: Sloat', address: '1515 Sloat Blvd, San Francisco, CA 94132' },
    { name: 'Stonestown Mall', address: '3251 20th Ave, San Francisco, CA 94132' },
    {
      name: 'Outer Sunset Farmers Market:',
      address: '1994 37th Ave, San Francisco, CA 94116',
      times: Times.SUNDAY_FM,
    }, // Sundays 9am - 3pm,
  ],
  Ingleside: [
    { name: 'H-Mart', address: '3995 Alemany Blvd, 94132' },
    { name: 'Walgreens: Mid-Ocean Ave', address: '1630 Ocean Ave, San Francisco, CA 94112' },
  ],
  'Outer Mission': [
    { name: 'Walgreens: Geneva ', address: '965 Geneva Ave, San Francisco, CA 94112' },
    { name: 'Manila Oriental Market', address: '4175 Mission St, San Francisco, CA 94112' },
    { name: 'Safeway: Outer Mission', address: '4950 Mission St, San Francisco, CA 94112' },
    { name: 'Pacific Supermarket', address: '2900 Alemany Blvd, San Francisco, CA 9411' },
  ],
  Portola: [
    { name: 'Grocery Outlet Portola', address: '1390 Silver Ave, San Francisco, CA 94134' },
    { name: 'Walgreens San Bruno Ave', address: '2494 San Bruno Ave, San Francisco, CA 94134' },
    { name: 'Fancy Wheat Field Bakery', address: '2684 San Bruno Ave, San Francisco, CA 94134' },
  ],
  Chinatown: [
    { name: 'Walgreens Chinatown', address: '1334 Stockton St, 94133' },
    { name: 'AA Bakery', address: '1068 Stockton St, 94108' },
    { name: 'Stockton & Pacific', address: 'Stockton & Pacific, San Francisco, CA' },
    { name: 'Stockton & Sacramento', address: 'Stockton & Sacramento, San Francisco, CA' },
  ],
  'North Beach': [
    { name: 'North Beach Library ', address: '850 Columbus Ave, San Francisco, CA 94133' },
    {
      name: 'Fort Mason Farmers Market:',
      address: 'Fort Mason Center for Arts & Culture, Marina Blvd, 94109',
      times: Times.SUNDAY_FM,
    }, // Sundays 9:30a-1:30p
  ],
  'Lower Haight': [
    {
      name: 'Campaign Headquarters',
      address: '572 Haight St, San Francisco, CA 94117',
      times: Times.HQ,
    }, // Daily 9am - 8pm
  ],
};

export const RecallBoeLocations = ({}) => {
  return (
    <div>
      {Object.keys(locations).map((hood) => {
        const locs = locations[hood];
        return (
          <div>
            <h4>{hood}</h4>
            <ol>
              {locs.map((loc) => {
                const timeText = loc.times || Times.DEFAULT;
                return (
                  <li>
                    <div>
                      <b>{loc.name}</b>
                    </div>
                    <div>
                      <i>{timeText}</i> @{' '}
                      <a
                        target="_blank"
                        href={
                          'https://www.google.com/maps/search/?api=1&query=' +
                          encodeURIComponent(loc.address)
                        }
                      >
                        {loc.address.substr(0, loc.address.indexOf('San Francisco') - 2)}
                      </a>
                    </div>
                  </li>
                );
              })}
            </ol>
          </div>
        );
      })}
    </div>
  );
};

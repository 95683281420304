import React, { Fragment, useRef } from 'react';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  ComposedChart,
  Text,
} from 'recharts';
import {
  strongSupport,
  weakSupport,
  weakOppose,
  strongOppose,
  bluePalette,
} from '../../../src/utils/pulsePalette.js';

const favorables = [
  {
    name: 'Feb 2024',
    Breed: 12 + 28,
    Farrell: 9 + 15,
    Lurie: 8 + 17,
    Peskin: 10 + 24,
  },
  {
    name: 'May 2024',
    Breed: 7 + 25,
    Farrell: 10 + 21,
    Lurie: 6 + 24,
    Peskin: 5 + 19,
  },
  {
    name: 'July 2024',
    Breed: 8 + 28,
    Farrell: 17 + 28,
    Lurie: 11 + 37,
    Peskin: 7 + 23,
  },
  {
    name: 'Sept 2024',
    Breed: 12 + 28,
    Farrell: 10 + 30,
    Lurie: 12 + 39,
    Peskin: 9 + 26,
  },
];

const unfavorables = [
  {
    name: 'Feb 2024',
    Breed: 21 + 33,
    Farrell: 9 + 9,
    Lurie: 9 + 8,
    Peskin: 14 + 25,
  },
  {
    name: 'May 2024',
    Breed: 29 + 34,
    Farrell: 9 + 9,
    Lurie: 12 + 6,
    Peskin: 15 + 26,
  },
  {
    name: 'July 2024',
    Breed: 24 + 31,
    Farrell: 12 + 12,
    Lurie: 14 + 8,
    Peskin: 11 + 33,
  },
  {
    name: 'Sept 2024',
    Breed: 21 + 34,
    Farrell: 17 + 22,
    Lurie: 19 + 9,
    Peskin: 14 + 36,
  },
];

// calculate net favorables
const netFavorables = favorables.map((d, i) => {
  const net = {};
  Object.keys(d).forEach((key) => {
    if (key === 'name') {
      net[key] = d[key];
      return;
    }
    net[key] = d[key] - unfavorables[i][key];
  });
  return net;
});

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}
      >
        <p className="label">{`${label}`}</p>
        <p className="desc">{`Breed: ${payload[0].value}%`}</p>
        <p className="desc">{`Farrell: ${payload[1].value}%`}</p>
        <p className="desc">{`Lurie: ${payload[2].value}%`}</p>
        <p className="desc">{`Peskin: ${payload[3].value}%`}</p>
      </div>
    );
  }

  return null;
};

// Custom tick component
const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        dy={16}
        textAnchor="middle" // This will align the text to the left
        fill="#4B5563" // This is the Tailwind CSS 'text-gray-700' color
        fontSize="0.675rem" // This corresponds to 'text-sm' in Tailwind CSS
        fontWeight="600" // This corresponds to 'font-semibold' in Tailwind CSS
      >
        {payload.value.toUpperCase()}
      </Text>
    </g>
  );
};

const MayorLineChart = ({ title, data }) => {
  const BreedColor = '#1271A7';
  const FarrellColor = '#64D09C';
  const LurieColor = '#EBAB5A';
  const PeskinColor = '#FFA794';

  return (
    <Fragment>
      <p className="text-left text-gray-700 text-sm font-semibold uppercase">{title}</p>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data} margin={{ top: 5, right: 30, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis axisLine={false} dataKey="name" tickLine={false} tick={<CustomizedAxisTick />} />
          <YAxis axisLine={false} tickLine={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="Breed" stroke={BreedColor} strokeWidth={3} />
          <Line type="monotone" dataKey="Farrell" stroke={FarrellColor} strokeWidth={3} />
          <Line type="monotone" dataKey="Lurie" stroke={LurieColor} strokeWidth={3} />
          <Line type="monotone" dataKey="Peskin" stroke={PeskinColor} strokeWidth={3} />
        </ComposedChart>
      </ResponsiveContainer>
    </Fragment>
  );
};

export { favorables, unfavorables, netFavorables, MayorLineChart };

import slugify from 'react-slugify';
import { ENDORSEMENT, ENDORSEMENT_TRANSLATIONS, ENDORSEMENT_ORDER, ALPHABET } from './constants.js';
import { shuffle } from '../../utils/sort.js';

const nameSort = ({ list, sortOrder = ALPHABET }) => {
  list.sort((a, b) => {
    const as = a.name.split(' ');
    const bs = b.name.split(' ');
    const lastNameA = as[as.length - 1].toUpperCase();
    const lastNameB = bs[bs.length - 1].toUpperCase();
    const firstNameA = as[0].toUpperCase();
    const firstNameB = bs[0].toUpperCase();

    if (lastNameA !== lastNameB) {
      // If the names are identical, then skip sorting by last name
      for (let i = 0; i < sortOrder.length; i++) {
        if (lastNameA[i] !== lastNameB[i]) {
          let ret = sortOrder.indexOf(lastNameA[i]) - sortOrder.indexOf(lastNameB[i]);
          if (lastNameA == 'Lee') {
            console.log('ret: ', ret);
          }
          return ret;
        }
      }
    }
    for (let i = 0; i < sortOrder.length; i++) {
      if (firstNameA[i] !== firstNameB[i]) {
        let ret = sortOrder.indexOf(firstNameA[i]) - sortOrder.indexOf(firstNameB[i]);
        if (lastNameA == 'Lee') {
          console.log('ret: ', ret);
        }
        return ret;
      }
    }

    return 0;
  });
  return list;
};

export const Contest = (
  jurisdiction,
  name,
  candidates,
  sortOrder = null,
  tldr_annotation = '',
  endorsement_order = ENDORSEMENT_ORDER.DEFAULT
) => {
  // Copy the original candidates array, since we will be sorting the candidates array
  const candidatesOrig = candidates.slice();
  if (sortOrder !== null) {
    // sortOrder is a randomized alphabet
    // Sorting is done by last name, then first name
    // If both words have the same first letter, look at the second letter, and so on. All letters follow sortOrder
    candidates = nameSort({ list: candidates, sortOrder: sortOrder });
  }

  let nameEn = name;
  let names = {};
  if (typeof name === 'object') {
    names = name;
    nameEn = names.en;
  }

  const has_endorsement = candidates.some((candidate) => candidate.endorsed);
  let endorsement = ({ lang = 'en' }) => {
    if (!has_endorsement) {
      return ENDORSEMENT_TRANSLATIONS.NO_ENDORSEMENT[lang];
    }
    const nameFn = (candidate) => {
      if (lang == 'zh' && candidate.names[lang]) {
        return `${candidate.names[lang]} (${candidate.name})`;
      }
      return candidate.names[lang] || candidate.name;
    };
    // If there are multiple candidates, and none of their endorsements are integers, then it's a vote-for-all/any
    const endorsedCandidates = candidatesOrig.filter((candidate) => candidate.endorsed);
    if (endorsedCandidates.length == 1) {
      return endorsedCandidates.map((candidate) => nameFn(candidate));
    } else if (endorsedCandidates.every((candidate) => !Number.isInteger(candidate.endorsed))) {
      // Vote-for-all/any
      if (endorsement_order === ENDORSEMENT_ORDER.RANDOM) {
        shuffle(endorsedCandidates);
      } else if (endorsement_order === ENDORSEMENT_ORDER.ALPHABETICAL) {
        nameSort({ list: endorsedCandidates, sortOrder: ALPHABET });
      } else if (endorsement_order === ENDORSEMENT_ORDER.DATA_ORDER) {
        // Do nothing; keep the same order as data
      } else {
        nameSort({
          list: endorsedCandidates,
          sortOrder: sortOrder || ALPHABET,
        });
      }
      return endorsedCandidates.map((candidate) => nameFn(candidate));
    } else {
      // Sort candidates by integer endorsement
      return endorsedCandidates
        .sort((a, b) => a.endorsed - b.endorsed)
        .map((candidate) => `#${candidate.endorsed}. ${nameFn(candidate)}`);
    }
  };
  return {
    type: 'Contest',
    jurisdiction: jurisdiction,
    name: nameEn,
    names: names,
    slug: slugify(nameEn),
    candidates: candidates,
    endorsement: endorsement,
    has_endorsement: has_endorsement,
    sortOrder: sortOrder,
    tldr_annotation: tldr_annotation,
  };
};

export const Judge = (jurisdiction, name, candidates, tldr_annotation = '') => {
  const has_endorsement = candidates.some((candidate) => candidate.endorsed);
  let endorsement = ({ lang = 'en' }) => {
    if (!has_endorsement) {
      return ENDORSEMENT_TRANSLATIONS.NO_ENDORSEMENT[lang];
    }
    const nameFn = (candidate) => {
      if (lang == 'zh' && candidate.names[lang]) {
        return `${candidate.names[lang]} (${candidate.name})`;
      }
      return candidate.names[lang] || candidate.name;
    };
    return candidates
      .filter((candidate) => candidate.endorsed)
      .map((candidate) => nameFn(candidate));
  };

  let nameEn = name;
  let names = {};
  if (typeof name === 'object') {
    names = name;
    nameEn = names.en;
  }
  return {
    type: 'Judge',
    jurisdiction: jurisdiction,
    name: nameEn,
    names: names,
    slug: slugify(nameEn),
    candidates: candidates,
    endorsement: endorsement,
    has_endorsement: has_endorsement,
    tldr_annotation: tldr_annotation,
  };
};

export const Candidate = (
  name,
  party,
  profession,
  questionnaire,
  endorsed = false,
  headshot = null
) => {
  let nameEn = name;
  let names = {};
  if (typeof name === 'object') {
    names = name;
    nameEn = names.en;
  }
  return {
    type: 'Candidate',
    name: nameEn,
    names: names,
    slug: slugify(nameEn),
    party: party,
    profession: profession,
    questionnaire: questionnaire,
    endorsed: endorsed,
    headshot: headshot,
  };
};

export const Measure = (jurisdiction, name, label, endorsement, faq = null, summary = null) => {
  let end = ({ lang = 'en' }) => {
    if (endorsement === ENDORSEMENT.NO_ENDORSEMENT) {
      return ENDORSEMENT_TRANSLATIONS.NO_ENDORSEMENT[lang];
    } else if (endorsement === ENDORSEMENT.YES) {
      return ENDORSEMENT_TRANSLATIONS.YES[lang];
    } else if (endorsement === ENDORSEMENT.NO) {
      return ENDORSEMENT_TRANSLATIONS.NO[lang];
    } else if (endorsement === ENDORSEMENT.UNDECIDED) {
      return ENDORSEMENT_TRANSLATIONS.UNDECIDED[lang];
    }
  };
  let nameEn = name;
  let names = {};
  if (typeof name === 'object') {
    names = name;
    nameEn = names.en;
  }
  return {
    type: 'Measure',
    jurisdiction: jurisdiction,
    name: nameEn,
    names: names,
    label: label,
    slug: slugify('Prop ' + label),
    endorsement: end,
    faq: faq,
    summary: summary,
  };
};

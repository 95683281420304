import React, { Fragment } from 'react';
import { Ballot, BallotSet, BallotForm } from './ballot';

import { formatNumber } from './utils';

const EXHAUSTED = 'Exhausted';

class RCVRound {
  // Given a list of ballots, perform a single round of RCV
  constructor(ballotSet) {
    this.ballotSet = ballotSet;
  }

  roundWinner(ballotSet) {
    return Object.entries(ballotSet.firstRankVotes).reduce((a, b) => (a[1] > b[1] ? a : b))[0];
  }

  // If any candidate has > 50% of the vote, they win
  majorityWinner(ballotSet) {
    const totalVotes = Object.values(ballotSet.firstRankVotes).reduce((a, b) => a + b, 0);
    const votesNeededToWin = totalVotes / 2;
    for (const [candidate, voteCount] of Object.entries(ballotSet.firstRankVotes)) {
      if (voteCount > votesNeededToWin) {
        return candidate; // Winner found
      }
    }
    return null; // No winner yet
  }

  // Eliminate the candidate with the fewest votes
  eliminatedCandidate() {
    const sortedCandidates = Object.entries(this.ballotSet.firstRankVotes).sort(
      ([, votesA], [, votesB]) => votesA - votesB
    );
    const lowestCandidate = sortedCandidates[0][0];
    return lowestCandidate;
  }

  get results() {
    // If there's already a majority winner, don't bother with the rest of the round
    const majorityWinner = this.majorityWinner(this.ballotSet);
    if (majorityWinner) {
      return new RCVRoundResult({
        winner: majorityWinner,
        roundWinner: majorityWinner,
        ballotSet: this.ballotSet,
        nextBallotSet: null,
      });
    }

    const eliminatedCandidate = this.eliminatedCandidate();
    // Find all ballots with the eliminated candidate as their first choice
    // and redistribute the votes to the next highest ranked candidate

    const transfer = (eliminatedCandidate, recipientCandidate, count) => {
      if (eliminatedCandidate in transfers) {
        transfers[eliminatedCandidate] -= count;
      } else {
        transfers[eliminatedCandidate] = -1 * count;
      }
      if (recipientCandidate in transfers) {
        transfers[recipientCandidate] += count;
      } else {
        transfers[recipientCandidate] = count;
      }
    };

    let transfers = {};
    const newBallotSet = new BallotSet([]);
    for (const ballot of this.ballotSet.ballots) {
      const idx = ballot.rankings.indexOf(eliminatedCandidate);
      if (idx === -1) {
        // Candidate is not in the rankings, so no transfers occur and the ballot isn't modified
        newBallotSet.add(ballot.copy());
      } else {
        if (ballot.rankings.length === 1) {
          // Exhausted ballot
          transfer(eliminatedCandidate, EXHAUSTED, ballot.count);
          newBallotSet.undervotes += ballot.count;
        } else {
          if (idx === 0) {
            // Eliminated candidate is the first choice
            newBallotSet.add(new Ballot(ballot.count, ballot.rankings.slice(1)));
            transfer(eliminatedCandidate, ballot.rankings[1], ballot.count);
          } else {
            // Eliminated candidate is not the first choice, but is ranked
            // Remove the eliminated candidate from the rankings, but no transfers occur
            newBallotSet.add(
              new Ballot(
                ballot.count,
                ballot.rankings.filter((c) => c !== eliminatedCandidate)
              )
            );
          }
        }
      }
    }

    // There may be a majority winner after the transfers
    //const newMajorityWinner = this.majorityWinner(newBallotSet);

    return new RCVRoundResult({
      round: this,
      winner: null,
      roundWinner: this.roundWinner(this.ballotSet),
      eliminatedCandidate,
      transfers,
      ballotSet: this.ballotSet,
      nextBallotSet: newBallotSet,
    });
  }
}

class RCVRoundResult {
  constructor({
    round,
    winner,
    roundWinner,
    eliminatedCandidate,
    transfers,
    ballotSet,
    nextBallotSet,
    shortCircuit = false,
  }) {
    this.round = round;
    this.winner = winner || null;
    this.roundWinner = roundWinner || null;
    this.eliminatedCandidate = eliminatedCandidate || null;
    this.transfers = transfers || {};
    this.ballotSet = ballotSet || null;
    this.nextBallotSet = nextBallotSet || null;
    this.shortCircuit = shortCircuit;
  }
}

const RCVCalculator = ({ ballotSet, Renderer }) => {
  /*const calculateRounds = () => {
    // Special case round 0. This is the initial state of the ballot set, but there may already be a winner.
    // Don't include transfers and don't modify the ballot set in this round.
    let round = new RCVRound(ballotSet);
    let result = round.results;
    let rc = 0;
    const results = [
      new RCVRoundResult({
        round: rc,
        winner: result.winner,
        roundWinner: result.roundWinner,
        ballotSet: ballotSet,
        shortCircuit: result.shortCircuit,
      }),
    ];
    result = results[0];
    let forceNextRound = result.winner !== null && rc === 0 && !result.shortCircuit;
    // if there's a winner and it isn't a short circuit, then we should continue to the next round
    while (!result.winner || forceNextRound) {
      // || (result.winner && rc === 0 && !result.shortCircuit)) {
      rc += 1;
      forceNextRound = false;
      round = new RCVRound(result.ballotSet);
      result = round.results;
      result.round = rc;
      results.push(result);
    }
    return results;
  };*/

  const calculateRounds = () => {
    let round = new RCVRound(ballotSet);
    let roundCount = 0;
    let result = round.results;
    result.round = roundCount;
    let results = [result];
    while (result.winner === null && roundCount < 20) {
      roundCount += 1;
      round = new RCVRound(result.nextBallotSet);
      result = round.results;
      result.round = roundCount;
      results.push(result);
    }
    return results;
  };

  return <Renderer results={calculateRounds()} />;
};

const RCVTableRenderer = ({ results }) => {
  const cell = ({ candidate, transfers, totalTransfers, roundWinner, votes, totalVotes }) => {
    if (votes === 0 && !transfers) {
      if (candidate == EXHAUSTED) {
        return <td className="w-fit px-2"></td>;
      }
      return <td className="w-fit px-2 bg-red-100"></td>;
    }
    let transferStr = '';
    let bg = '';
    if (transfers > 0) {
      transferStr += `+${formatNumber(transfers)} (${((transfers / totalTransfers) * 100).toFixed(
        0
      )}%)`;
    } else if (transfers < 0) {
      // Eliminated candidate
      bg = 'bg-red-100';
    }
    if (candidate != EXHAUSTED && roundWinner) {
      bg = 'bg-green-100';
    }
    return (
      <td className={`w-fit px-2 text-center content-top ${bg}`}>
        <span className="font-extrabold text-lg">{((votes / totalVotes) * 100).toFixed(2)}%</span>
        <br />
        {formatNumber(votes)}
        {transfers && (
          <Fragment>
            <br />
            <small>{transferStr}</small>
          </Fragment>
        )}
      </td>
    );
  };

  const row = ({ candidate, candidateIndex }) => {
    const totalVotes =
      Object.values(results[0].ballotSet.firstRankVotes).reduce((a, b) => a + b, 0) +
      results[0].ballotSet.undervotes;
    let runningTotalExhausted = results[0].ballotSet.undervotes || 0;
    return (
      <tr className="even:bg-slate-50 odd:bg-white" key={candidateIndex}>
        <td className="whitespace-nowrap text-left content-center w-fit">{candidate}</td>
        {results.map((result, resultIndex) => {
          let votes = result.ballotSet.firstRankVotes[candidate] || 0;
          const transfer = result.transfers && result.transfers[candidate];
          if (candidate === EXHAUSTED) {
            if (transfer > 0) {
              runningTotalExhausted += transfer;
            }
            votes = runningTotalExhausted;
          }
          let totalTransfers = 0;
          if (transfer > 0) {
            totalTransfers = Object.values(result.transfers)
              .filter((xfer) => xfer > 0)
              .reduce((a, b) => a + b, 0);
          }
          return cell({
            candidate,
            transfers: transfer || '',
            totalTransfers,
            votes: votes,
            totalVotes,
            roundWinner: result.roundWinner === candidate,
          });
        })}
      </tr>
    );
  };

  if (results.length === 0) {
    return null;
  }
  const candidates = results[0].ballotSet.candidates;
  // Order the candidates by their reverse order of elimination
  const eliminatedCandidates = results
    .filter((result) => result.eliminatedCandidate)
    .map((result) => result.eliminatedCandidate);
  const remainingCandidates = candidates
    .filter((candidate) => !eliminatedCandidates.includes(candidate) && candidate !== EXHAUSTED)
    .reverse();
  const orderedCandidates = [
    ...[...eliminatedCandidates, ...remainingCandidates].reverse(),
    EXHAUSTED,
  ];
  const winner = results[results.length - 1].winner;

  return (
    <div>
      {winner && <h2 className="!m-0 !mb-1 !text-brand-blue-4 ">Winner: {winner}</h2>}
      {results.length > 0 && (
        <div>
          <h4>Simulation Results</h4>
          <table className="text-right w-full">
            <thead className="sticky top-0 bg-brand-blue-3 text-white">
              <tr>
                <th></th>
                {results.map((result, index) => (
                  <th
                    key={index}
                    className="not-prose py-2 px-2 !text-center whitespace-nowrap w-fit"
                  >
                    Round {index + 1}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderedCandidates.map((candidate, candidateIndex) =>
                row({ candidate, candidateIndex })
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const RCVSeveralTablesWithExplanationRenderer = ({ results }) => {
  if (results.length === 0) {
    return null;
  }
  const winner = results[results.length - 1].winner;
  let runningTotalExhausted = results[0].ballotSet.undervotes || 0;

  // For each round, list all candidates and highlight the candidate who was eliminated
  const round = ({ result, index }) => {
    // Order candidates alphabetically
    const eliminatedCandidate = result.eliminatedCandidate;
    const candidates = [...result.ballotSet.candidates].sort();
    const totalTransfers = Object.values(result.transfers)
      .filter((t) => t > 0)
      .reduce((a, b) => a + b, 0);
    console.log(eliminatedCandidate);
    const ret = (
      <div key={index}>
        <h3>Round {index + 1}</h3>
        <div className="w-full overflow-x-scroll rounded-lg my-4">
        <table className="w-full">
          <thead className="sticky top-0 bg-brand-blue-3 !text-white">
            <tr>
              <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">Candidate</th>
              {/* <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">Votes</th> */}
              <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">% of Active Ballots</th>
              <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">% of All Ballots</th>
            </tr>
          </thead>
          <tbody>
            {candidates.map((candidate) => {
              const votes = result.ballotSet.firstRankVotes[candidate] || 0;
              const roundPercentage = ((votes / result.ballotSet.totalVotes) * 100).toFixed(0);
              const totalPercentage = (
                (votes / (result.ballotSet.totalVotes + runningTotalExhausted)) *
                100
              ).toFixed(0);
              let bg = 'even:bg-slate-50 odd:bg-white';
              if (candidate == result.roundWinner) {
                bg = 'bg-green-100';
              } else if (candidate == eliminatedCandidate) {
                bg = 'bg-red-100';
              }
              return (
                <tr key={candidate} className={bg}>
                  <td className="w-fit px-2">{candidate}</td>
                  {/* <td className="w-fit px-2">{formatNumber(votes)}</td> */}
                  <td className="w-fit px-2">{roundPercentage}%</td>
                  <td className="w-fit px-2">{totalPercentage}%</td>
                </tr>
              );
            })}
            <tr key={runningTotalExhausted} className="bg-gray-200">
              <td className="w-fit px-2">Undecided or exhausted</td>
              {/* <td className="w-fit px-2">{formatNumber(runningTotalExhausted)}</td> */}
              <td className="w-fit px-2"></td>
              <td className="w-fit px-2">
                {(
                  (runningTotalExhausted / (result.ballotSet.totalVotes + runningTotalExhausted)) *
                  100
                ).toFixed(0)}
                %
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        {eliminatedCandidate && (
          <Fragment>
            <p>
            <b>{eliminatedCandidate} is eliminated</b> because they had the fewest votes.{' '}
            Here's how {eliminatedCandidate}'s votes transfer for the next round:
            </p>
            <div className="w-full overflow-x-scroll rounded-lg my-4">
            <table className="w-fit">
              <thead className="sticky top-0 bg-brand-blue-3 !text-white">
                <tr>
                  <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">From</th>
                  <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">To</th>
                  {/* <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">Votes</th> */}
                  <th className="not-prose py-2 px-2 whitespace-nowrap w-fit">Percent</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th
                    className="not-prose py-2 px-2 whitespace-nowrap w-fit"
                    rowSpan={Object.keys(result.transfers).length + 1}
                  >
                    {eliminatedCandidate}
                  </th>
                </tr>
                {Object.entries(result.transfers)
                  .sort()
                  .filter((c) => c[0] !== eliminatedCandidate && c[0] != EXHAUSTED)
                  .map(([to, votes]) => {
                    return (
                      <tr key={to} className="even:bg-slate-50 odd:bg-white">
                        <td className="w-fit px-2">{to}</td>
                        {/* <td className="w-fit px-2">{formatNumber(votes)}</td> */}
                        <td className="w-fit px-2">
                          {((votes / totalTransfers) * 100).toFixed(0)}%
                        </td>
                      </tr>
                    );
                  })}
                <tr key={result.transfers[EXHAUSTED]} className="bg-gray-200">
                  <td className="w-fit px-2">{EXHAUSTED}</td>
                  {/* <td className="w-fit px-2">{formatNumber(result.transfers[EXHAUSTED])}</td> */}
                  <td className="w-fit px-2">
                    {((result.transfers[EXHAUSTED] / totalTransfers) * 100).toFixed(0)}%
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </Fragment>
        )}
        {result.winner && (
          <p>
            <b>{result.winner}</b> wins!
          </p>
        )}
      </div>
    );
    runningTotalExhausted += result.transfers[EXHAUSTED] || 0;
    return ret;
  };

  return <div>{results.map((result, index) => round({ result, index }))}</div>;
};

export { RCVCalculator, RCVTableRenderer, RCVSeveralTablesWithExplanationRenderer };

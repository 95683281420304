import { useState, useEffect } from 'react';

const isFirefox = typeof window !== 'undefined' && navigator?.userAgent.indexOf('Firefox') >= 0;

const useDetectPrint = () => {
  const [isPrinting, setIsPrinting] = useState(false);

  useEffect(() => {
    if (!isFirefox) return;
    //fallback due to firefox before
    const before = () => setIsPrinting(true);
    //fallback due to firefox after
    const after = () => setIsPrinting(false);

    window?.addEventListener('beforeprint', before);
    window?.addEventListener('afterprint', after);
    return () => {
      window?.removeEventListener('beforeprint', before);
      window?.removeEventListener('afterprint', after);
    };
  }, []);

  useEffect(() => {
    if (isFirefox) return;
    const mgList = typeof window !== 'undefined' && window.matchMedia && window.matchMedia('print');

    const eventHandler = (ev) =>
      !!ev.matches
        ? setIsPrinting(true)
        : setTimeout(() => {
            setIsPrinting(false);
          }, 5000);

    // Fallback For Safari < 14
    if (!mgList?.addEventListener) {
      mgList?.addListener('change', eventHandler);
      return () => mgList?.removeListener('change', eventHandler);
    }
    mgList?.addEventListener('change', eventHandler);
    return () => mgList?.removeEventListener('change', eventHandler);
  }, []);

  return isPrinting;
};

export default useDetectPrint;

import React from 'react';
import {
  PairwiseAffinityByRoundScenario,
  SimpleScenario,
} from '../../../src/components/rcv/scenarios';
import { Ballot, BallotSet } from '../../../src/components/rcv/ballot';

/*
NOTES:
SF Voting population: 500,000
Expected turnout: 80%
*/
const voterUniverse = 500000 * 0.8;
const Mayor0524 = new PairwiseAffinityByRoundScenario({
  name: 'San Francisco Mayoral Election 2024 RCV Simulation',
  notes:
    'GrowSF polled on the San Francisco Mayoral election for November 2024, and this is how the election would unfold if the election were held today.',
  firstRankVotes: {
    Farrell: voterUniverse * 0.1626,
    Breed: voterUniverse * 0.2055,
    Lurie: voterUniverse * 0.1594,
    Peskin: voterUniverse * 0.1151,
    Safai: voterUniverse * 0.0675,
    Other: voterUniverse * 0.0109,
  },
  // Each round of voting gets its own affinity matrix. If you input fewer than the max number of rounds, the last affinity matrix will be used for the remaining rounds.
  pairwiseAffinity: [
    {
      // 1st to 2nd
      Breed: {
        Breed: 0.3905,
        Farrell: 0.2703,
        Lurie: 0.1096,
        Peskin: 0.1224,
        Safai: 0.0895,
        Other: 0.0176,
      },
      Farrell: {
        Breed: 0.1616,
        Farrell: 0.1178,
        Lurie: 0.518,
        Peskin: 0.1198,
        Safai: 0.0351,
        Other: 0.0478,
      },
      Lurie: {
        Breed: 0.0881,
        Farrell: 0.4584,
        Lurie: 0.1779,
        Peskin: 0.1003,
        Safai: 0.1596,
        Other: 0.0156,
      },
      Peskin: {
        Breed: 0.1727,
        Farrell: 0.0135,
        Lurie: 0.2185,
        Peskin: 0.2477,
        Safai: 0.3295,
        Other: 0.0182,
      },
      Safai: {
        Breed: 0,
        Farrell: 0.1903,
        Lurie: 0.0454,
        Peskin: 0.3565,
        Safai: 0.4078,
        Other: 0,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.1324,
        Peskin: 0.0,
        Safai: 0.0,
        Other: 0.8676,
      },
    },
    {
      // 2nd to 3rd
      Breed: {
        Breed: 0.2849,
        Farrell: 0.1645,
        Lurie: 0.2319,
        Peskin: 0.0602,
        Safai: 0.2585,
        Other: 0,
      },
      Farrell: {
        Breed: 0.1233,
        Farrell: 0.3427,
        Lurie: 0.2228,
        Peskin: 0.1152,
        Safai: 0.1424,
        Other: 0.0536,
      },
      Lurie: {
        Breed: 0.2622,
        Farrell: 0.1102,
        Lurie: 0.2595,
        Peskin: 0.0278,
        Safai: 0.2539,
        Other: 0.0864,
      },
      Peskin: {
        Breed: 0.2082,
        Farrell: 0.1374,
        Lurie: 0.271,
        Peskin: 0.3095,
        Safai: 0.0738,
        Other: 0,
      },
      Safai: {
        Breed: 0.1452,
        Farrell: 0.1826,
        Lurie: 0.1081,
        Peskin: 0.2438,
        Safai: 0.2636,
        Other: 0.0567,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.0,
        Peskin: 0.0,
        Safai: 0.0,
        Other: 1.0,
      },
    },
    {
      // 3rd to 4th
      Breed: {
        Breed: 0.402,
        Farrell: 0.1576,
        Lurie: 0.0955,
        Peskin: 0.0673,
        Safai: 0.2538,
        Other: 0.0238,
      },
      Farrell: {
        Breed: 0.172,
        Farrell: 0.2031,
        Lurie: 0.1903,
        Peskin: 0.1104,
        Safai: 0.2125,
        Other: 0.1117,
      },
      Lurie: {
        Breed: 0.0149,
        Farrell: 0.0761,
        Lurie: 0.2217,
        Peskin: 0.1618,
        Safai: 0.4787,
        Other: 0.0469,
      },
      Peskin: {
        Breed: 0.3987,
        Farrell: 0.0,
        Lurie: 0.1071,
        Peskin: 0.1972,
        Safai: 0.1823,
        Other: 0.1148,
      },
      Safai: {
        Breed: 0.1613,
        Farrell: 0.1875,
        Lurie: 0.1455,
        Peskin: 0.2071,
        Safai: 0.2128,
        Other: 0.0858,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.0,
        Peskin: 0.0,
        Safai: 0.0528,
        Other: 0.9472,
      },
    },
  ],
  maxRankings: 4,
});

const Mayor0524Old = new PairwiseAffinityByRoundScenario({
  name: 'San Francisco Mayoral Election 2024 RCV Simulation',
  notes:
    'GrowSF polled on the San Francisco Mayoral election for November 2024, and this is how the election would unfold if the election were held today.',
  firstRankVotes: {
    Farrell: voterUniverse * 0.16,
    Breed: voterUniverse * 0.21,
    Lurie: voterUniverse * 0.16,
    Peskin: voterUniverse * 0.12,
    Safai: voterUniverse * 0.07,
    Other: voterUniverse * 0.01,
  },
  // Each round of voting gets its own affinity matrix. If you input fewer than the max number of rounds, the last affinity matrix will be used for the remaining rounds.
  pairwiseAffinity: [
    {
      // 1st to 2nd
      Breed: {
        Breed: 0.3636,
        Farrell: 0.2841,
        Lurie: 0.125,
        Peskin: 0.1136,
        Safai: 0.1023,
        Other: 0.014,
      },
      Farrell: {
        Breed: 0.1974,
        Farrell: 0.1316,
        Lurie: 0.5132,
        Peskin: 0.0921,
        Safai: 0.0263,
        Other: 0.0395,
      },
      Lurie: {
        Breed: 0.0857,
        Farrell: 0.4571,
        Lurie: 0.1857,
        Peskin: 0.1143,
        Safai: 0.1429,
        Other: 0.0143,
      },
      Peskin: {
        Breed: 0.16,
        Farrell: 0.02,
        Lurie: 0.24,
        Peskin: 0.24,
        Safai: 0.32,
        Other: 0.02,
      },
      Safai: {
        Breed: 0,
        Farrell: 0.16,
        Lurie: 0.08,
        Peskin: 0.4,
        Safai: 0.36,
        Other: 0,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.2,
        Peskin: 0.0,
        Safai: 0.0,
        Other: 0.8,
      },
    },
    {
      // 2nd to 3rd
      Breed: {
        Breed: 0.2759,
        Farrell: 0.1034,
        Lurie: 0.3103,
        Peskin: 0.069,
        Safai: 0.2414,
        Other: 0,
      },
      Farrell: {
        Breed: 0.1452,
        Farrell: 0.3226,
        Lurie: 0.2097,
        Peskin: 0.129,
        Safai: 0.129,
        Other: 0.0645,
      },
      Lurie: {
        Breed: 0.2462,
        Farrell: 0.1077,
        Lurie: 0.2462,
        Peskin: 0.0308,
        Safai: 0.2769,
        Other: 0.0923,
      },
      Peskin: {
        Breed: 0.2,
        Farrell: 0.1429,
        Lurie: 0.2286,
        Peskin: 0.3143,
        Safai: 0.1143,
        Other: 0,
      },
      Safai: {
        Breed: 0.1622,
        Farrell: 0.1829,
        Lurie: 0.1351,
        Peskin: 0.2162,
        Safai: 0.2431,
        Other: 0.0541,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.0,
        Peskin: 0.0,
        Safai: 0.0,
        Other: 1.0,
      },
    },
    {
      // 3rd to 4th
      Breed: {
        Breed: 0.3947,
        Farrell: 0.1316,
        Lurie: 0.1053,
        Peskin: 0.0526,
        Safai: 0.2895,
        Other: 0.0263,
      },
      Farrell: {
        Breed: 0.1818,
        Farrell: 0.2273,
        Lurie: 0.1818,
        Peskin: 0.1364,
        Safai: 0.1818,
        Other: 0.0909,
      },
      Lurie: {
        Breed: 0.0286,
        Farrell: 0.0857,
        Lurie: 0.2571,
        Peskin: 0.1143,
        Safai: 0.4286,
        Other: 0.0857,
      },
      Peskin: {
        Breed: 0.35,
        Farrell: 0.0,
        Lurie: 0.15,
        Peskin: 0.2,
        Safai: 0.2,
        Other: 0.1,
      },
      Safai: {
        Breed: 0.1892,
        Farrell: 0.1892,
        Lurie: 0.1081,
        Peskin: 0.1892,
        Safai: 0.2431,
        Other: 0.0811,
      },
      Other: {
        Breed: 0.0,
        Farrell: 0.0,
        Lurie: 0.0,
        Peskin: 0.0,
        Safai: 0.05,
        Other: 0.95,
      },
    },
  ],
  maxRankings: 4,
});

const MoreBreedSeconds = new PairwiseAffinityByRoundScenario({
  name: 'More Breed second-choice votes',
  notes: 'If Mayor Breed got more second-place votes, she would win.',
  firstRankVotes: {
    Farrell: 104000,
    Breed: 100000,
    Lurie: 76000,
    Peskin: 72000,
    Safai: 24000,
  },
  pairwiseAffinity: [
    {
      Farrell: {
        Breed: 0.26,
        Lurie: 0.4,
        Farrell: 0.12,
        Safai: 0.08,
        Peskin: 0.14,
      },
      Breed: {
        Breed: 0.26,
        Lurie: 0.22,
        Farrell: 0.2,
        Safai: 0.16,
        Peskin: 0.16,
      },
      Lurie: {
        Breed: 0.28,
        Lurie: 0.1,
        Farrell: 0.4,
        Safai: 0.1,
        Peskin: 0.12,
      },
      Peskin: {
        Breed: 0.22,
        Lurie: 0.14,
        Farrell: 0.13,
        Safai: 0.32,
        Peskin: 0.19,
      },
      Safai: {
        Breed: 0.23,
        Lurie: 0.22,
        Farrell: 0.11,
        Safai: 0.13,
        Peskin: 0.31,
      },
    },
  ],
  maxRankings: 3,
});

const BreedBulletVotes = new PairwiseAffinityByRoundScenario({
  name: 'Breed Bullet Votes',
  notes: 'If Mayor Breed got more bullet votes, no change.',
  firstRankVotes: {
    Farrell: 104000,
    Breed: 100000,
    Lurie: 76000,
    Peskin: 72000,
    Safai: 24000,
  },
  pairwiseAffinity: [
    {
      Farrell: {
        Breed: 0.26,
        Lurie: 0.4,
        Farrell: 0.12,
        Safai: 0.08,
        Peskin: 0.14,
      },
      Breed: {
        Breed: 0.5,
        Lurie: 0.16,
        Farrell: 0.14,
        Safai: 0.1,
        Peskin: 0.1,
      },
      Lurie: {
        Breed: 0.18,
        Lurie: 0.15,
        Farrell: 0.4,
        Safai: 0.1,
        Peskin: 0.17,
      },
      Peskin: {
        Breed: 0.22,
        Lurie: 0.14,
        Farrell: 0.13,
        Safai: 0.32,
        Peskin: 0.19,
      },
      Safai: {
        Breed: 0.23,
        Lurie: 0.22,
        Farrell: 0.11,
        Safai: 0.13,
        Peskin: 0.31,
      },
    },
  ],
  maxRankings: 3,
});

const MoreBreedBulletVotes = new PairwiseAffinityByRoundScenario({
  name: 'More Breed Bullet Votes',
  notes: 'If Mayor Breed got more bullet votes and more first-place votes, Farrell would win.',
  firstRankVotes: {
    Farrell: 99000,
    Breed: 110000,
    Lurie: 71000,
    Peskin: 72000,
    Safai: 24000,
  },
  pairwiseAffinity: [
    {
      Farrell: {
        Breed: 0.21,
        Lurie: 0.42,
        Farrell: 0.13,
        Safai: 0.1,
        Peskin: 0.16,
      },
      Breed: {
        Breed: 0.5,
        Lurie: 0.16,
        Farrell: 0.14,
        Safai: 0.1,
        Peskin: 0.1,
      },
      Lurie: {
        Breed: 0.13,
        Lurie: 0.16,
        Farrell: 0.42,
        Safai: 0.12,
        Peskin: 0.19,
      },
      Peskin: {
        Breed: 0.17,
        Lurie: 0.16,
        Farrell: 0.15,
        Safai: 0.34,
        Peskin: 0.2,
      },
      Safai: {
        Breed: 0.18,
        Lurie: 0.24,
        Farrell: 0.13,
        Safai: 0.14,
        Peskin: 0.33,
      },
    },
  ],
  maxRankings: 3,
});

const MorePeskinFirstPlaceVotes = new PairwiseAffinityByRoundScenario({
  name: 'More Peskin first place votes',
  notes: 'If Peskin increases his popularity, he can easily win.',
  firstRankVotes: {
    Farrell: 100017,
    Breed: 96170,
    Lurie: 73089,
    Peskin: 86400,
    Safai: 23080,
  },
  pairwiseAffinity: [
    {
      Farrell: {
        Breed: 0.26,
        Lurie: 0.4,
        Farrell: 0.12,
        Safai: 0.08,
        Peskin: 0.14,
      },
      Breed: {
        Breed: 0.26,
        Lurie: 0.22,
        Farrell: 0.2,
        Safai: 0.16,
        Peskin: 0.16,
      },
      Lurie: {
        Breed: 0.18,
        Lurie: 0.15,
        Farrell: 0.4,
        Safai: 0.1,
        Peskin: 0.17,
      },
      Peskin: {
        Breed: 0.22,
        Lurie: 0.14,
        Farrell: 0.13,
        Safai: 0.32,
        Peskin: 0.19,
      },
      Safai: {
        Breed: 0.23,
        Lurie: 0.22,
        Farrell: 0.11,
        Safai: 0.13,
        Peskin: 0.31,
      },
    },
  ],
  maxRankings: 3,
});

const weightSum = 293.16846;
const normalizer = 310 / weightSum;
const SCALE_FACTOR = (voterUniverse / 412) * normalizer; // Just for nice numbers
const bs = new BallotSet(
  [
    new Ballot(Math.round(SCALE_FACTOR * 0.79063258575974), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.959642232005), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85890382273976), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01692801787649), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04250747613644), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04250747613644), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.10657598038392), ['Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.83518686350384), ['Farrell', 'Breed', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.83518686350384), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84551281946488), [
      'Lurie',
      'Farrell',
      'Peskin',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.84551281946488), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44933384387483), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.77602986730548), ['Lurie', 'Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96983376054091), ['Breed', 'Lurie', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.54538573367436), [
      'Peskin',
      'Safai',
      'Breed',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.63552562613384), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.69273863145019), [
      'Breed',
      'Lurie',
      'Farrell',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.70130340768584), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.70130340768584), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.73933160968111), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.56490865959706), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84452973283901), ['Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57189299112322), ['Lurie', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57189299112322), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57189299112322), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.67465323898823), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.63089783132509), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.11805489419814), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.98846607523718), ['Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.00068712133823), [
      'Farrell',
      'Breed',
      'Peskin',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.19976561526404), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19976561526404), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19976561526404), ['Lurie', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19976561526404), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19976561526404), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19976561526404), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21459909439086), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.79189252830763), ['Peskin', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27349860056828), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.97305509195806), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.97305509195806), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.23595615196482), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.51711408118947), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82145173037563), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6276550195525), ['Peskin', 'Safai', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6276550195525), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6276550195525), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6276550195525), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85085686566062), ['Lurie', 'Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85085686566062), ['Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27201789038453), ['Lurie', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86137656000434), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6501229018148), ['Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.97192371059387), ['Lurie', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6581607921921), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6581607921921), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.76693984409276), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.14656323749267), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.23451909277993), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.92062150378387), ['Peskin', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.11321877694193), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.22445346217679), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.08247940466953), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.67278718699526), ['Peskin', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53637002734396), ['Breed', 'Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53637002734396), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.54300151728135), [
      'Lurie',
      'Peskin',
      'Safai',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.78741447244345), ['Lurie', 'Farrell', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.56243909649043), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84083777408562), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.56939289520891), ['Breed', 'Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.68036293603035), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.54609225969728), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.2839320832487), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.02860001880706), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63149229464242), [
      'Breed',
      'Farrell',
      'Peskin',
      'Lurie',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.63929984276028), ['Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82206616451119), [
      'Farrell',
      'Peskin',
      'Lurie',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.62132851231418), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.65538057153951), ['Breed', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.65538057153951), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.66348346589185), ['Lurie', 'Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84274413460581), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84274413460581), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.2598894825899), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85316352600266), ['Breed', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.89462963674481), ['Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.53071648607474), [
      'Peskin',
      'Breed',
      'Farrell',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.25325192626066), ['Peskin', 'Safai', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.02583549517367), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.94469161883035), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21476490966218), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75741790879734), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.2667231844509), ['Farrell', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.78606973699172), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.91598923650029), ['Safai', 'Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99845089485453), [
      'Safai',
      'Peskin',
      'Farrell',
      'Lurie',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.82427490372906), ['Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82427490372906), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82427490372906), [
      'Peskin',
      'Lurie',
      'Safai',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 2.86477658648069), [
      'Safai',
      'Farrell',
      'Lurie',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.93995023175166), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.29627446068062), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.99846819489936), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.68710401946234), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.24209642173867), [
      'Lurie',
      'Farrell',
      'Safai',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.00248675160723), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.02541738780256), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.06420717938472), ['Peskin', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01758142800968), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.22002090565992), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.51042841312502), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.08076253953397), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.32536988554146), ['Breed', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.95266232266652), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.06179719860633), [
      'Safai',
      'Peskin',
      'Farrell',
      'Lurie',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.72791043788631), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.3821117032626), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.60699627269836), [
      'Breed',
      'Peskin',
      'Safai',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.44688880450158), ['Peskin', 'Safai', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.4524139801436), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.69336464626114), [
      'Farrell',
      'Peskin',
      'Lurie',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.88069864407872), [
      'Peskin',
      'Lurie',
      'Breed',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.59638561231846), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.4056372987344), ['Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64436740957395), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.49234839260755), ['Lurie', 'Breed', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.49234839260755), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.49234839260755), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.84723129288774), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57372241547252), ['Farrell', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.62537160514599), [
      'Lurie',
      'Farrell',
      'Safai',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.62537160514599), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63310347921658), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63310347921658), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63310347921658), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.44530162004326), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.27341316063878), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.00529387741999), [
      'Lurie',
      'Farrell',
      'Safai',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.81136518546178), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.21297843761445), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.8213966185931), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.8213966185931), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.8213966185931), ['Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.05622170165696), [
      'Farrell',
      'Lurie',
      'Peskin',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.70095917877965), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.85079978106658), [
      'Breed',
      'Farrell',
      'Lurie',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.86131876963651), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86131876963651), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86131876963651), ['Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86131876963651), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.00367522759756), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01608431429688), ['Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.09403079119891), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.78833361692017), [
      'Peskin',
      'Breed',
      'Safai',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.48442180087323), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.50277466873218), ['Breed', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.50277466873218), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.97977681474875), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.25988044154662), ['Farrell', 'Breed', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.25988044154662), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.18921889422095), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.13277993418107), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.14678522596072), [
      'Peskin',
      'Safai',
      'Farrell',
      'Breed',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.77657234262895), ['Breed', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.9049221382497), ['Lurie', 'Farrell', 'Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.47463407491738), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.80437095081149), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.20252216469267), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.41859663600991), [
      'Farrell',
      'Lurie',
      'Breed',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.03432864854586), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04711672333587), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 2.08481690695834), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.88334064770877), ['Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.89426196008258), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.57404178324742), ['Breed', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86742192438927), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.36785287027833), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.92627345016054), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.20580736918287), [
      'Peskin',
      'Lurie',
      'Breed',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.20580736918287), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.58278080276851), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.72081583885718), ['Lurie', 'Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.53612078530798), ['Lurie', 'Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.25045339140672), [
      'Safai',
      'Peskin',
      'Breed',
      'Farrell',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.28055890906537), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.49764436702634), ['Lurie', 'Peskin', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.5542977001492), ['Safai', 'Peskin', 'Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.6872560704659), ['Breed', 'Farrell', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6461830504052), ['Safai', 'Farrell', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96603369350553), ['Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57937189954017), ['Breed', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.8661520534702), ['Breed', 'Peskin', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.8661520534702), ['Safai', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.38703726324806), [
      'Farrell',
      'Lurie',
      'Safai',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.38703726324806), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40584834638751), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.40584834638751), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63582143184167), ['Breed', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63582143184167), [
      'Lurie',
      'Farrell',
      'Safai',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.86344558076973), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.93800423029395), ['Lurie', 'Farrell', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.93800423029395), ['Peskin', 'Breed', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96629882057896), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.58973700246243), ['Lurie', 'Breed', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.39935415557308), ['Lurie', 'Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.48472213277974), ['Lurie', 'Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.48472213277974), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53848224032626), ['Farrell', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75059215528006), [
      'Breed',
      'Farrell',
      'Lurie',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.50828097117874), ['Peskin', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50828097117874), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50828097117874), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75987221208986), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.96517529480238), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.91844771024313), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.66657855879499), ['Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.14811826200403), ['Breed', 'Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.62581642529351), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.62581642529351), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6323134956286), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.9690766476392), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44885877590859), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.30360176928924), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.35815427045408), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.8075361790566), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.9801592234527), ['Lurie', 'Peskin', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99227756656661), ['Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.78523763743512), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.79494603877309), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.49108861274849), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.66289650129589), ['Breed', 'Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.47432483391017), ['Safai', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.74797116289761), [
      'Farrell',
      'Breed',
      'Lurie',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.50650610510236), ['Safai', 'Lurie', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50650610510236), ['Breed', 'Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50650610510236), ['Farrell', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44299373525112), ['Breed', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.78432463339577), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.53112370490079), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.00855138697271), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.9266711440933), ['Breed', 'Safai', 'Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.74238658623635), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.47886565843054), ['Peskin', 'Safai', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.04065358576194), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.551437797636), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57823920215843), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.58538834781355), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.69836535165843), [
      'Peskin',
      'Breed',
      'Farrell',
      'Safai',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.95844231209591), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.64903154900299), ['Farrell', 'Lurie', 'Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44442341003241), ['Farrell', 'Peskin', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.86852628730876), [
      'Lurie',
      'Safai',
      'Farrell',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.51532058968108), ['Safai', 'Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.90277670393178), ['Breed', 'Farrell', 'Safai', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.01052154653702), ['Breed', 'Lurie', 'Safai', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19209650235371), [
      'Peskin',
      'Lurie',
      'Farrell',
      'Breed',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.39899971826245), ['Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.64909667902238), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.75581946762059), ['Safai', 'Lurie', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.16557359251135), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50498224571112), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.02890373375313), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.95378643085032), ['Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.47588057766497), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.44364464671746), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.63024596814125), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.50994421916929), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6623178331371), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.57062850849975), ['Lurie', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.17051888195519), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.65710731695966), ['Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.99450679782245), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.68146564118407), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.78793011925102), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.19341583022668), [
      'Peskin',
      'Safai',
      'Farrell',
      'Lurie',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 1.46031713483742), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.67833691568487), ['Peskin', 'Safai', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.0024422679115), ['Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.74869404557091), ['Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.50640613282325), ['Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.6432274805696), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82073954454909), ['Lurie', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82073954454909), ['Lurie', 'Farrell', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 0.71467298302985), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.91793070262839), ['Peskin', 'Lurie', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.88996129444165), ['Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 2.13251472058081), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.03410773484134), ['Lurie', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 1.93979163967738), ['Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.38759861666061), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.03855840030221), ['Farrell', 'Lurie', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.38598524119108), ['Farrell', 'Safai', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.73904697341985), ['Lurie', 'Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.37619269326212), ['Breed', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.17360318925964), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.82882779204676), [
      'Lurie',
      'Safai',
      'Farrell',
      'Peskin',
    ]),
    new Ballot(Math.round(SCALE_FACTOR * 0.5080260717356), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.71845662082715), ['Safai', 'Peskin', 'Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.24614685921391), ['Breed']),
    new Ballot(Math.round(SCALE_FACTOR * 1.35409195837276), ['Safai', 'Peskin', 'Farrell']),
    new Ballot(Math.round(SCALE_FACTOR * 1.28982831246173), ['Lurie', 'Farrell', 'Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.79186097393312), ['Farrell', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.00580681435422), ['Farrell', 'Breed', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 2.0635895915167), ['Farrell', 'Peskin', 'Lurie', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 1.3937999323807), ['Farrell', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 1.47767652534635), ['Peskin']),
    new Ballot(Math.round(SCALE_FACTOR * 0.7165609267344), ['Breed', 'Safai', 'Peskin', 'Lurie']),
    new Ballot(Math.round(SCALE_FACTOR * 0.93092129352549), ['Breed', 'Safai']),
    new Ballot(Math.round(SCALE_FACTOR * 0.93092129352549), ['Safai']),
  ],
  0
);
// 28% undecided
bs.undervotes = Math.round(bs.totalVotes / (1 - 0.28) - bs.totalVotes);
const SimpleWeightedMayor0524 = new SimpleScenario({
  name: 'Simple Mayor 0524',
  notes: 'These are the raw (but weighted) ballots from FM3.',
  ballotSet: bs,
});

export {
  Mayor0524,
  MoreBreedSeconds,
  BreedBulletVotes,
  MoreBreedBulletVotes,
  MorePeskinFirstPlaceVotes,
  SimpleWeightedMayor0524,
};

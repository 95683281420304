import { bluePalette, goodPalette, badPalette, reversedGoodPalette, reversedBadPalette } from "../../../../src/utils/pulsePalette.js";
import direction from "../../../../src/images/icons/direction.svg";
import mayor from "../../../../src/images/icons/mayor.svg";
import government from "../../../../src/images/icons/government.svg";
import updown from "../../../../src/images/icons/updown.svg";
import homeless from "../../../../src/images/icons/homeless.svg";
import drugs from "../../../../src/images/icons/drugs.svg";
import crime from "../../../../src/images/icons/crime.svg";
import transit from "../../../../src/images/icons/transit.svg";
import education from "../../../../src/images/icons/education.svg";
import covid from "../../../../src/images/icons/covid.svg";
import tech from "../../../../src/images/icons/tech.svg";
import { H1 } from "../../../../src/components/layout/headings";
import * as React from 'react';
export default {
  bluePalette,
  goodPalette,
  badPalette,
  reversedGoodPalette,
  reversedBadPalette,
  direction,
  mayor,
  government,
  updown,
  homeless,
  drugs,
  crime,
  transit,
  education,
  covid,
  tech,
  H1,
  React
};
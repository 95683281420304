import { TLDR } from "../../../../src/components/election/tldr";
import { MeasureInfo } from "../../../../src/components/election/measure";
import { CandidateInfo } from "../../../../src/components/election/candidate_info";
import BgWhiteWrapper from "../../../../src/components/election/bgWhiteWrapper";
import { AllCandidates } from "../../../../src/components/election/candidates";
import { MeasureHeader } from "../../../../src/components/election/measure";
import { june_contests } from "../../../../src/data/data-june-2022.js";
import * as React from 'react';
export default {
  TLDR,
  MeasureInfo,
  CandidateInfo,
  BgWhiteWrapper,
  AllCandidates,
  MeasureHeader,
  june_contests,
  React
};
import React, { Fragment, useEffect, useRef, useState, memo } from 'react';
import slugify from 'react-slugify';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { AllCandidates } from './candidates';
import { H3 } from '../layout/headings';
import { useIntersectionObserver } from '../../hooks/useIntersectionObserver';
import Profile from '../content/profile';

const endorsement = (contest, lang) => {
  if (!contest.has_endorsement) return '';

  const theEndorsement = contest.endorsement(lang);

  let endorsement = null;
  if (typeof theEndorsement === 'string') {
    endorsement = theEndorsement;
  } else {
    if (theEndorsement.length > 5) {
      return '';
    }
    const lf = new Intl.ListFormat('en');
    endorsement = lf.format(theEndorsement);
  }
  return (contest.has_endorsement ? 'Vote ' : '') + endorsement;
};

const getName = (obj, lang) => {
  return obj.names[lang] || obj.name;
};

const getKey = (obj) => {
  return obj.names['en'] || obj.name;
};

export const CandidateInfo = memo(
  ({
    hide = false,
    contest,
    children,
    infoOnly = false,
    profession = true,
    questionnaires = true,
    party = true,
    showTable = true,
    lang = 'en',
    ...props
  }) => {
    /* contest may be a list of contests, and all the contests will appear in the same section.

  Note, however, that these contests must have the same list of candidates and the same endorsements.
  */

    var name;
    var candidates;
    var end;
    var topLink;
    if (Array.isArray(contest)) {
      name = contest.map((c) => (
        <Fragment key={getKey(c)}>
          <H3
            hide={hide}
            idOverride={slugify(getKey(c))}
            className="!m-0 font-black tracking-tight sticky-heading"
          >
            {getName(c, lang)}
          </H3>{' '}
          <br />
        </Fragment>
      ));
      name = <div className="!m-0 font-black tracking-tight sticky-heading">{name}</div>;
      // Special case candidates and endorsements to only look at the first element
      candidates = contest[0].candidates;
      end = endorsement(contest[0], lang);
      topLink = '#' + contest[0].jurisdiction.toLowerCase() + '-candidates';
    } else {
      name = (
        <H3
          hide={hide}
          key={getKey(contest)}
          idOverride={slugify(getKey(contest))}
          className="!m-0 font-black tracking-tight sticky-heading"
        >
          {getName(contest, lang)}
        </H3>
      );
      candidates = contest.candidates;
      end = endorsement(contest, lang);
      topLink = '#' + contest.jurisdiction.toLowerCase() + '-candidates';
    }
    return (
      <StickyContainer
        content={children}
        AllCandidates={
          showTable === true ? (
            <AllCandidates
              candidates={candidates}
              profession={profession}
              questionnaire={questionnaires}
              party={party}
              infoOnly={infoOnly}
              lang={lang}
            />
          ) : (
            ''
          )
        }
      >
        <div className="flex justify-between items-center gap-2.5 lg:gap-8">
          {name}
          <span className="hidden lg:block">
            <TopToLink topLink={topLink} />
          </span>
        </div>
        {!infoOnly && (
          <span className="tracking-normal leading-7 !m-0 font-medium leading-7 mt-2 bg-brand-yellow-2 w-fit px-2">
            {end}
          </span>
        )}
      </StickyContainer>
    );
  }
);

export const CandidateHeadshots = memo(({ contest, onlyEndorsed = true, lang = 'en' }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { regex: "/voter-guide/.*/headshots/" }
        }
      ) {
        nodes {
          base
          childImageSharp {
            gatsbyImageData(
              quality: 50
              placeholder: BLURRED
              layout: FIXED
              formats: [WEBP, AUTO]
              height: 56
              width: 56
            )
          }
        }
      }
    }
  `);

  const headshots = data.allFile.nodes;
  let orderStatement = 'Candidates are listed in the order they appear on the ballot.';
  if (lang === 'zh') {
    orderStatement = '候选人按照他们在选票上的顺序排列。';
  }

  return (
    <Fragment>
      <div className="text-sm my-5">{orderStatement}</div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-5 mb-10">
        {contest.candidates.map((candidate, index) => {
          if (onlyEndorsed && !candidate.endorsed) {
            return null; // Skip candidates that are not endorsed
          }

          let candidateHeadshot = null;

          if (candidate.headshot != null) {
            candidateHeadshot = headshots.find((headshot) =>
              candidate.headshot.endsWith('headshots/' + headshot.base)
            );
          }
          let name = getName(candidate, lang);
          if (lang !== 'en') {
            name = `${name} (${candidate.names['en'] || candidate.name})`;
          }
          return (
            <Profile
              key={`Profile_${index}`}
              name={name}
              role={candidate.profession[lang] || candidate.profession}
            >
              {candidateHeadshot && (
                <GatsbyImage
                  image={getImage(candidateHeadshot.childImageSharp.gatsbyImageData)}
                  key={`${getKey(candidate)}-headshot`}
                  alt={name}
                />
              )}
            </Profile>
          );
        })}
      </div>
    </Fragment>
  );
});

export const JudgeInfo = memo(
  ({
    hide = false,
    contest,
    children,
    infoOnly = false,
    profession = true,
    questionnaires = false,
    lang = 'en',
    ...props
  }) => {
    var name;
    var candidates;
    var end;
    var topLink;

    name = (
      <H3
        hide={hide}
        key={getKey(contest)}
        idOverride={slugify(getKey(contest))}
        className="!m-0 font-black tracking-tight sticky-heading"
      >
        {getName(contest, lang)}
      </H3>
    );
    candidates = contest.candidates;
    end = endorsement(contest, lang);
    topLink = '#' + contest.jurisdiction.toLowerCase() + '-judges';

    return (
      <StickyContainer
        content={children}
        AllCandidates={
          <AllCandidates
            party={false}
            candidates={candidates}
            profession={profession}
            questionnaire={questionnaires}
            lang={lang}
          />
        }
      >
        <div className="flex justify-between items-center gap-2.5 lg:gap-8">
          {name}
          <span className="hidden lg:block">
            <TopToLink topLink={topLink} />
          </span>
        </div>
        {!infoOnly && (
          <span className="tracking-normal leading-7 !m-0 font-medium leading-7 mt-2 bg-brand-yellow-2 w-fit px-2">
            {end}
          </span>
        )}
      </StickyContainer>
    );
  }
);

export const StickyContainer = ({ children, content, AllCandidates, id }) => {
  const ref = useRef(null);
  const headerRef = useRef(null);
  const entry = useIntersectionObserver(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });
  const isVisible = !!entry?.isIntersecting;
  const [mounted, setMounted] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [show, setShow] = useState(false);
  const [showShadow, setShowShadow] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined' || !mounted) return;
    setScrolled(false);
    location.hash
      ? setTimeout(() => setScrolled(true), 300)
      : setTimeout(() => setScrolled(true), 0);
  }, [mounted]);

  useEffect(() => {
    isVisible && scrolled ? setShow(true) : setShow(false);
  }, [isVisible, scrolled]);

  useEffect(() => {
    const handleShadow = () => {
      const topOffset = window?.innerWidth < 1024 ? 65 : 10;
      const headerTop = headerRef?.current?.getBoundingClientRect().top;
      headerTop <= topOffset ? setShowShadow(true) : setShowShadow(false);
    };
    handleShadow();
    window?.addEventListener('scroll', handleShadow);
    return () => {
      window?.removeEventListener('scroll', handleShadow);
    };
  }, []);

  return (
    <div ref={ref} {...{ id: id ? id : '' }} className="sticky-container">
      <div
        ref={headerRef}
        className={`flex flex-col -mx-5 px-5 py-2 top-14 bg-brand-blue-1 z-30 scroll-mt-5 ease-in-out transform transition-all duration-1000 mb-5 lg:py-5 lg:-mx-10 lg:px-10 lg:top-0 lg:transition-none border-b-slate-200 after:block after:absolute after:top-full after:left-0 after:h-14 after:w-full after:pointer-events-none after:ease-in-out after:transform after:transition-all duration-1000 after:lg:transition-none after:bg-[linear-gradient(270deg,#ECF8FF_0.31%,rgba(236,248,255,0.00)_8.05%),linear-gradient(90deg,#ECF8FF_0.43%,rgba(236,248,255,0.00)_5.36%),linear-gradient(180deg,rgba(23,40,65,0.10)_0%,rgba(23,40,65,0.00)_100%)] ${
          show ? 'sticky' : 'relative'
        } ${showShadow ? '' : 'border-b after:hidden'}`}
      >
        {children}
      </div>
      {content}
      {AllCandidates}
    </div>
  );
};

export const TopToLink = memo(({ topLink }) => {
  return (
    <a
      href={topLink}
      className="flex justify-center items-center flex-nowrap gap-2.5 bg-none h-9 px-4 py-2.5 no-underline rounded-sm hover:shadow-sm border-solid border border-brand-blue-4"
    >
      <span className="text-xs font-bold text-brand-blue-4 uppercase tracking-widest">Top</span>
      <svg
        className="fill-current w-2.5 h-2.5"
        width="10"
        height="10"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.36006L0.000282307 11.7756L6.13664 5.63915L12.2727 11.7753L12.2724 6.35978L6.13636 0.223633L0 6.36006Z"
          fill="text-brand-blue-4"
        />
      </svg>
    </a>
  );
});

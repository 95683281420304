import { bluePalette, goodPalette, badPalette, reversedGoodPalette, reversedBadPalette, extendedGoodPalette, strongSupport, weakSupport, weakOppose, strongOppose } from "../../../../src/utils/pulsePalette.js";
import apartmentSmall from "../../../../src/images/icons/apartment-small.svg";
import apartmentLarge from "../../../../src/images/icons/apartment-large.svg";
import house from "../../../../src/images/icons/house.svg";
import legal from "../../../../src/images/icons/legal.svg";
import mayor from "../../../../src/images/icons/mayor.svg";
import moneyBag from "../../../../src/images/icons/money-bag.svg";
import budget from "../../../../src/images/icons/budget.svg";
import { H1 } from "../../../../src/components/layout/headings";
import { OldLineChart } from "../../../../src/components/pulse/lineChart";
import * as React from 'react';
export default {
  bluePalette,
  goodPalette,
  badPalette,
  reversedGoodPalette,
  reversedBadPalette,
  extendedGoodPalette,
  strongSupport,
  weakSupport,
  weakOppose,
  strongOppose,
  apartmentSmall,
  apartmentLarge,
  house,
  legal,
  mayor,
  moneyBag,
  budget,
  H1,
  OldLineChart,
  React
};
import React from 'react';
import { PARTY, JURISDICTION, QS, ENDORSEMENT } from '../components/election/constants.js';
import { Contest, Candidate, Judge, Measure } from '../components/election/data_objs.js';

const defaultOrder = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
// Name ordering for March 2024 state contests
const stateOrder = 'GHAUTJLCBFVENWYSOXZDPIRKMQ';
// Name ordering for March 2024 local contests
const localOrder = 'FWSLBZQXGYTMJRKCIOPEHNVUAD';

// These are listed in the order in which they'll appear on the ballot
export const mar24_contests = {
  // https://elections.cdn.sos.ca.gov//statewide-elections/2024-primary/cert-list-candidates.pdf
  'President (Democrat)': Contest(
    JURISDICTION.FEDERAL,
    'President (Democrat)',
    [
      Candidate('Dean Philips', PARTY.DEM, 'Congressman from Minnesota', QS.NOT_SENT),
      Candidate(
        'Joseph R Biden Jr',
        PARTY.DEM,
        'Incumbent President of the United States',
        QS.NOT_SENT
      ),
      Candidate('Marianne Williamson', PARTY.DEM, 'Author & Spiritual Advisor', QS.NOT_SENT),
      Candidate('President R Boddie', PARTY.DEM, '', QS.NOT_SENT),
      Candidate('Eban Cambridge', PARTY.DEM, '', QS.NOT_SENT),
      Candidate('Gabriel Cornejo', PARTY.DEM, '', QS.NOT_SENT),
      Candidate('Stephen P Lyons', PARTY.DEM, '', QS.NOT_SENT),
      Candidate('Armando "Mando" Perez-Serrato', PARTY.DEM, '', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'President (Republican)': Contest(
    JURISDICTION.OTHER,
    'President (Republican)',
    [
      Candidate('Asa Hutchinson', PARTY.REP, 'Former Governor of Arkansas', QS.NOT_SENT),
      Candidate('Chris Christie', PARTY.REP, 'Governor of New Jersey', QS.NOT_SENT),
      Candidate('Donald J. Trump', PARTY.REP, 'Former President of the United States', QS.NOT_SENT),
      Candidate(
        'Nikki Haley',
        PARTY.REP,
        'Former Governor of South Carolina & Former UN Ambassador',
        QS.NOT_SENT
      ),
      Candidate('Ron DeSantis', PARTY.REP, 'Governor of Florida', QS.NOT_SENT),
      Candidate('Vivek Ramaswamy', PARTY.REP, 'Entrepreneur', QS.NOT_SENT),
      Candidate('Ryan L Binkley Republican', PARTY.REP, '', QS.NOT_SENT),
      Candidate('David Stuckenberg Republican', PARTY.REP, '', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'President (Third Party)': Contest(
    JURISDICTION.OTHER,
    'President (Third Party)',
    [
      Candidate('James Bradley', PARTY.AI, '', QS.NOT_SENT),
      Candidate('Jill Stein', PARTY.GRN, '', QS.NOT_SENT),
      Candidate('Charles Ballay', PARTY.LIB, '', QS.NOT_SENT),
      Candidate('Claudia De la Cruz', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Jasmine Sherman', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Cornell West', PARTY.PF, '', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  /* https://ballotpedia.org/United_States_Senate_election_in_California,_2024 */
  'US Senate (Full Term)': Contest(
    JURISDICTION.FEDERAL,
    'US Senate (Full Term)',
    [
      Candidate('Adam B. Schiff', PARTY.DEM, 'United States Representative', QS.NOT_SENT),
      Candidate('Barbara Lee', PARTY.DEM, 'Congresswoman/Mother', QS.NOT_SENT),
      Candidate('Christina Pascucci', PARTY.DEM, '', QS.NOT_SENT),
      Candidate('David Peterson', PARTY.DEM, 'Small Business Owner', QS.NOT_SENT),
      Candidate('Denice Gary-Pandel', PARTY.REP, 'Retired Security Analyst', QS.NOT_SENT),
      Candidate('Don J. Grundmann', PARTY.NPP, 'Doctor of Chiropractic', QS.NOT_SENT),
      Candidate('Douglas H. Pierce', PARTY.DEM, 'Missing Persons Investigator', QS.NOT_SENT),
      Candidate('Eric Early', PARTY.REP, 'Business Owner/Attorney', QS.NOT_SENT),
      Candidate('Forrest Jones', PARTY.AI, '', QS.NOT_SENT),
      Candidate('Gail Lightfoot', PARTY.LIB, 'Retired Registered Nurse', QS.NOT_SENT),
      Candidate('Harmesh Kumar', PARTY.DEM, 'Psychologist/Social Entrepreneur', QS.NOT_SENT),
      Candidate('James “Jim” Macauley', PARTY.REP, 'Sales Associate', QS.NOT_SENT),
      Candidate('James Bradley', PARTY.REP, 'Chief Executive Officer', QS.NOT_SENT),
      Candidate('Joe Sosinski', PARTY.NPP, '', QS.NOT_SENT),
      Candidate('John Rose', PARTY.DEM, 'Office Manager', QS.NOT_SENT),
      Candidate('Jonathan Reiss', PARTY.REP, 'Media entrepreneur', QS.NOT_SENT),
      Candidate('Katie Porter', PARTY.DEM, 'U.S. Representative', QS.NOT_SENT),
      Candidate('Laura Garza', PARTY.NPP, 'Freight Railroad Worker', QS.NOT_SENT),
      Candidate('Major Singh', PARTY.INDEP, 'Software Engineer', QS.NOT_SENT),
      Candidate('Mark Ruzon', PARTY.NPP, 'Software Engineer', QS.NOT_SENT),
      Candidate('Martin Veprauskas', PARTY.REP, 'Retired Operations Manager', QS.NOT_SENT),
      Candidate('Perry Pound', PARTY.DEM, 'Small Business Owner', QS.NOT_SENT),
      Candidate('Raji Rab', PARTY.DEM, 'Aviator/Educator/Entrepreneur', QS.NOT_SENT),
      Candidate('Sarah Sun Liew', PARTY.REP, 'Pastor/Nonprofit CEO', QS.NOT_SENT),
      Candidate('Sepi Gilani', PARTY.DEM, 'Physician/Surgeon/Professor', QS.NOT_SENT),
      Candidate('Sharleta Bassett', PARTY.REP, 'Businesswoman/Mother/Farmer', QS.NOT_SENT),
      Candidate('Stefan Simchowitz', PARTY.REP, 'Art Dealer', QS.NOT_SENT),
      Candidate('Steve Garvey', PARTY.REP, 'Professional Baseball Representative', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  'US Senate (Partial Term)': Contest(
    JURISDICTION.FEDERAL,
    'US Senate (Partial Term)',
    [
      Candidate('Eric Early', PARTY.REP, 'Business Owner/Attorney', QS.NOT_SENT),
      Candidate('Steve Garvey', PARTY.REP, 'Professional Baseball Representative', QS.NOT_SENT),
      Candidate('Sepi Gilani', PARTY.DEM, 'Physician/Surgeon/Professor', QS.NOT_SENT),
      Candidate('Barbara Lee', PARTY.DEM, 'Congresswoman/Mother', QS.NOT_SENT),
      Candidate('Christina Pascucci', PARTY.DEM, '', QS.NOT_SENT),
      Candidate('Katie Porter', PARTY.DEM, 'U.S. Representative', QS.NOT_SENT),
      Candidate('Adam B. Schiff', PARTY.DEM, 'United States Representative', QS.NOT_SENT),
    ],
    defaultOrder
  ),
  /* https://ballotpedia.org/California%27s_11th_Congressional_District_election,_2024 */
  'US Congress, District 11': Contest(
    JURISDICTION.FEDERAL,
    'US Congress, District 11',
    [
      Candidate({ en: 'Jason Boyce', zh: '傑森·博伊斯' }, PARTY.DEM, 'Web Developer', QS.NOT_SENT),
      Candidate(
        { en: 'Eve Del Castello', zh: '伊芙.德爾.卡斯德羅' },
        PARTY.REP,
        'Business Consultant',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Bruce Lou', zh: '樓成生' }, PARTY.REP, 'Business Owner', QS.NOT_SENT),
      Candidate(
        { en: 'Marjorie Mikels', zh: '瑪喬莉·米克斯' },
        PARTY.DEM,
        'Attorney/Peace Advocate',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Larry Nichelson', zh: '拉里·尼科爾森' },
        PARTY.REP,
        'Retired School Worker',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Nancy Pelosi', zh: '南希 ‧ 佩洛西' },
        PARTY.DEM,
        'Member of Congress; Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Bianca Von Krieg', zh: '比安卡.馮.克里格' },
        PARTY.DEM,
        'Community Organizer',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Jason Chuyuan Zeng', zh: '曾楚源' },
        PARTY.REP,
        'Data Engineer',
        QS.NOT_SENT
      ),
    ],
    stateOrder
  ),
  /* https://ballotpedia.org/California%27s_15th_Congressional_District_election,_2024 */
  'US Congress, District 15': Contest(
    JURISDICTION.FEDERAL,
    'US Congress, District 15',
    [
      Candidate(
        { en: 'Kevin Mullin', zh: '凱文 穆林' },
        PARTY.DEM,
        'U.S. Representative; Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Anna Cheng Kramer', zh: '陳安娜' },
        PARTY.REP,
        'Housing Policy Advisor',
        QS.NOT_SENT
      ),
    ],
    stateOrder
  ),
  'State Senate, District 11': Contest(
    JURISDICTION.STATE,
    { en: 'State Senate, District 11', zh: '州參議院，第 11 區' },
    [
      Candidate(
        { en: 'Scott Wiener', zh: '威善高' },
        PARTY.DEM,
        'State Senator; Incumbent',
        'https://drive.google.com/file/d/1CvHCB0xQI2li6s8C88lElthbesfLAOgt/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Yvette Corkrean', zh: '依薇特·科克安' },
        PARTY.REP,
        'Registered Nurse / Mother',
        'https://drive.google.com/file/d/1OLBQy3yFBnGanch1Ccv7AQ_EP8Qh_O9e/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Cynthia J Cravens', zh: '辛西亞·珍·克雷文斯' },
        PARTY.DEM,
        'Community Volunteer',
        'https://drive.google.com/file/d/1YrqZr0QquBAUZbeQjns5KuyQkFhRkgFY/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Jingchao Xiong Jr.', zh: '熊競超' },
        PARTY.NPP,
        'Social Management Researcher',
        QS.NO_RESPONSE
      ),
    ],
    stateOrder
  ),
  'State Assembly, District 17': Contest(
    JURISDICTION.STATE,
    { en: 'State Assembly, District 17', zh: ' 17 區州眾議院，第17區' },
    [
      Candidate(
        { en: 'Matt Haney', zh: '楊馳馬' },
        PARTY.DEM,
        'Assemblymember; Incumbent',
        'https://drive.google.com/file/d/1ca76TqSGsVg_SCFo-tSx-XSIWJHfBEgy/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'Otto Duke', zh: '奧托·杜克' },
        PARTY.DEM,
        'Cancer Resource CEO',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Manuel Noris-Barrera', zh: '李明杰' },
        PARTY.REP,
        'Real Estate Businessman',
        QS.NO_RESPONSE
      ),
    ],
    stateOrder
  ),
  'State Assembly, District 19': Contest(
    JURISDICTION.STATE,
    { en: 'State Assembly, District 19', zh: ' 19 區州眾議院，第17區' },
    [
      Candidate(
        { en: 'Catherine Stefani', zh: '司嘉怡' },
        PARTY.DEM,
        'Supervisor City and County of San Francisco, District 2',
        'https://drive.google.com/file/d/13nLy5Ldf47U8pz0r9BFAhuSAYCIZI5ab/view?usp=drive_link',
        true
      ),
      Candidate(
        { en: 'David E. Lee', zh: '李志威' },
        PARTY.DEM,
        'Civil Rights Educator',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Arjun Sodhani', zh: '阿瓊∙古斯塔夫∙索達尼' },
        PARTY.REP,
        'Inventory Manager',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Nadia Flamenco', zh: '納迪亞·弗拉門戈' },
        PARTY.REP,
        'Mother / Filmmaker',
        QS.NOT_SENT
      ),
    ],
    stateOrder
  ),

  'Democratic County Central Committee, District 17': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Democratic County Central Committee, District 17',
      zh: '三藩市縣民主黨中央委員會、州眾議院第 17 區',
    },
    [
      Candidate(
        { en: 'Adolfo Velasquez', zh: '阿道夫 ‧ 維拉奎茲' },
        PARTY.DEM,
        'Educator',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Anita Martinez', zh: '安妮塔 ‧ 馬丁內茲' },
        PARTY.DEM,
        'Retired College Teacher',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Bilal Mahmood', zh: '馬百樂' },
        PARTY.DEM,
        'Climate Nonprofit Director',
        'https://drive.google.com/file/d/1iEzy-pGgQfbor8UIcnOSvrgD6NbioJLn/view?usp=drive_link',
        true,
        'headshots/AD17-Bilal-Mahmood.jpg'
      ),
      Candidate(
        { en: 'Carrie Barnes', zh: '凱莉·巴恩斯' },
        PARTY.DEM,
        'Community Organizer / Mom',
        'https://drive.google.com/file/d/1dU4tyA0HkjBS0r6iTqPXXcwmhMQaZEek/view?usp=drive_link',
        true,
        'headshots/AD17-Carrie-Barnes.jpg'
      ),
      Candidate(
        { en: 'Cedric G. Akbar', zh: '塞德里克·G·阿克巴' },
        PARTY.DEM,
        'Community Advocate',
        'https://drive.google.com/file/d/1irYm3F8ijGOs-pydxgIK-ldZd29wTrub/view?usp=drive_link',
        true,
        'headshots/AD17-Cedric-Akbar.jpg'
      ),
      Candidate(
        { en: 'Christopher Christensen', zh: '克里斯多福·克里斯滕森' },
        PARTY.DEM,
        'Union Organizer',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Emma Heiken', zh: '艾瑪·海肯' },
        PARTY.DEM,
        'Public Policy Analyst',
        'https://drive.google.com/file/d/1qXKHWZ_0cMZ8Su2pV-1UvDY1m10pSIP8/view?usp=drive_link',
        true,
        'headshots/AD17-Emma-Heiken.jpg'
      ),
      Candidate(
        { en: 'Frank Tizedes', zh: '弗蘭克·泰茲德斯' },
        PARTY.DEM,
        'Workforce Talent Strategist',
        'https://drive.google.com/file/d/14tRA_7Z9MgK046m4B2kRSeU8qGInFRa_/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Gloria Berry', zh: '彼得 ‧ 加洛塔' },
        PARTY.DEM,
        'Public Servant / Mother',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jane Kim', zh: '金貞妍' },
        PARTY.DEM,
        'Statewide Organizing Director',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jeremy Lee', zh: '李銘富' },
        PARTY.DEM,
        'Affordable Housing Manager',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Joe Sangirardi', zh: '喬·桑吉拉迪' },
        PARTY.DEM,
        'Housing Policy Director',
        'https://drive.google.com/file/d/1XB2bVmU_Gvmx1fSq5R89BT70d2p9YVEm/view?usp=drive_link',
        true,
        'headshots/AD17-Joe-Sangirardi.jpg'
      ),
      Candidate(
        { en: 'John Avalos', zh: '艾華樂' },
        PARTY.DEM,
        'Affordable Housing Organizer',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Joshua Rudy Ochoa', zh: '約書亞·魯迪·奧喬亞' },
        PARTY.DEM,
        'Community Organizer',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Kristin Hardy', zh: '克里斯汀·哈迪' },
        PARTY.DEM,
        'Medical Records Clerk',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Laurance Lem Lee', zh: '林灼世' },
        PARTY.DEM,
        'Small Business Owner',
        'https://drive.google.com/file/d/1ofsRHKvzdsnljqzsttfmmLHzqjwVlKrS/view?usp=drive_link',
        true,
        'headshots/AD17-Laurance-Lem-Lee.jpg'
      ),
      Candidate(
        { en: 'Lily Ho', zh: '何元麗' },
        PARTY.DEM,
        'Public Safety Director',
        'https://drive.google.com/file/d/1Km50IEheec-DOy6zaBcvC_CocG6kvP2v/view?usp=drive_link',
        true,
        'headshots/AD17-Lily-Ho.jpg'
      ),
      Candidate(
        { en: 'Luis A. Zamora', zh: '路易斯·A.扎莫拉' },
        PARTY.DEM,
        'Executive Affairs Director',
        'https://drive.google.com/file/d/1FHXX0JxE5jiUt1Z1KMMpKZVPefiY1btV/view?usp=drive_link',
        true,
        'headshots/AD17-Luis-Zamora.jpg'
      ),
      Candidate(
        { en: 'Lyn Werbach', zh: '琳·韋巴赫' },
        PARTY.DEM,
        'School Administrator / COO',
        'https://drive.google.com/file/d/1YGDRV7UAitmmx1oS5qBxxmscmfeN0v3Z/view?usp=drive_link',
        true,
        'headshots/AD17-Lyn-Werbach.jpg'
      ),
      Candidate(
        { en: 'Matt Dorsey', zh: '麥德誠' },
        PARTY.DEM,
        'Supervisor, District 6',
        'https://drive.google.com/file/d/1wpk8rYk2K-MlXuFbK67UqcAj2pk7EGyk/view?usp=drive_link',
        true,
        'headshots/AD17-Matt-Dorsey.jpg'
      ),
      Candidate(
        { en: 'Michael Lai', zh: '賴天宸' },
        PARTY.DEM,
        'Early Education Director',
        'https://drive.google.com/file/d/1qU0vtCtE0Xk4-7V7wTTGH07yMhpMK1In/view?usp=drive_link',
        true,
        'headshots/AD17-Michael-Lai.jpg'
      ),
      Candidate(
        { en: 'Michael Nguyen', zh: '阮忠誠' },
        PARTY.DEM,
        'Attorney / LGBTQ Advocate',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Nancy Tung', zh: '湯曉慧' },
        PARTY.DEM,
        "Chief Prosecutor (San Francisco District Attorney's Office)",
        'https://drive.google.com/file/d/1ZkEKmqJKQzD6hyc6J4n0Pi8cFw-Q5wew/view?usp=drive_link',
        true,
        'headshots/AD17-Nancy-Tung.jpg'
      ),
      Candidate(
        { en: 'Patrick Bell', zh: '派崔克·貝爾' },
        PARTY.DEM,
        'Union Plumber',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Peter Gallotta', zh: '彼得 ‧ 加洛塔' },
        PARTY.DEM,
        'Clean Energy Manager',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Peter Ho Lik Lee', zh: '李浩力' },
        PARTY.DEM,
        'Business Owner / Parent',
        'https://drive.google.com/file/d/1h89x8CsqSC2rwxhdMgpIFcvJ81Yb9FMP/view?usp=drive_link',
        true,
        'headshots/AD17-Peter-Lee.jpg'
      ),
      Candidate(
        { en: 'Sal Rosselli', zh: '薩爾·羅塞利' },
        PARTY.DEM,
        'Healthcare Union President',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Sydney Simpson', zh: '悉尼' },
        PARTY.DEM,
        'Registered Nurse',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Trevor Chandler', zh: '陳澤維' },
        PARTY.DEM,
        'Public School Teacher; Candidate for Supervisor, District 9',
        'https://drive.google.com/file/d/1zz194xyoDdlbzb9fBMxAZExpToBFf3TP/view?usp=drive_link',
        true,
        'headshots/AD17-Trevor-Chandler.jpg'
      ),
      Candidate(
        { en: 'Vick Chung', zh: '鍾曉雲' },
        PARTY.DEM,
        'Member, Community College Board',
        QS.NO_RESPONSE
      ),
    ],
    stateOrder,
    {
      en: 'Vote for ALL 14 candidates',
      zh: '而是投票給所有 14 名候選人',
    }
  ),
  'Democratic County Central Committee, District 19': Contest(
    JURISDICTION.LOCAL,
    {
      en: 'Democratic County Central Committee, District 19',
      zh: '三藩市縣民主黨中央委員會、州眾議院第 19 區',
    },
    [
      Candidate(
        { en: 'Brian Quan', zh: '關達民' },
        PARTY.DEM,
        'Community Volunteer',
        'https://drive.google.com/file/d/1HF3DsWy0GO1Are78DaljwQTbCbUSdQVo/view?usp=drive_link',
        true,
        'headshots/AD19-Brian-Quan.jpg'
      ),
      Candidate(
        { en: 'Catherine Stefani', zh: '司嘉怡' },
        PARTY.DEM,
        'Supervisor, District 2',
        'https://drive.google.com/file/d/1HVlCpO5tRABAWLcFYR5mJsM3q8BxDp7S/view?usp=drive_link',
        true,
        'headshots/AD19-Catherine-Stefani.jpg'
      ),
      Candidate(
        { en: 'Connie Chan', zh: '陳詩敏' },
        PARTY.DEM,
        'Supervisor, District 1',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Dan Calamuci', zh: '丹·卡拉穆奇' },
        PARTY.DEM,
        'Labor Union Representative, Nor Cal Carpenters',
        'https://drive.google.com/file/d/1Z5ya6Q8JHPAaOVfWI-jbm77-JTC4bRjL/view?usp=drive_link',
        true,
        'headshots/AD19-Dan-Calamuci.jpg'
      ),
      Candidate(
        { en: 'Frances Hsieh', zh: '謝令宜' },
        PARTY.DEM,
        'Labor Union Officer',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Gordon Mar', zh: '馬兆明' },
        PARTY.DEM,
        'Healthcare Union Coordinator (Former Supervisor, District 4)',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Greg Hardeman', zh: '葛雷格·哈德曼' },
        PARTY.DEM,
        'Trades Union Representative',
        'https://drive.google.com/file/d/10aGZlU1rJOs9l56W1QyGOxEhWltOYhmn/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Hene Kelly', zh: '希尼 ‧ 凱' },
        PARTY.DEM,
        'Teacher / Legislative Director',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Jade Tu', zh: '徐加儀' },
        PARTY.DEM,
        'Community Non-profit Director',
        'https://drive.google.com/file/d/1lwaXfqKb3S9X18Agw8fII7FBeBg2XZ7w/view?usp=drive_link',
        true,
        'headshots/AD19-Jade-Tu.jpg'
      ),
      Candidate(
        { en: 'Jen Nossokoff', zh: '珍·諾索科夫' },
        PARTY.DEM,
        'Physician Assistant',
        'https://drive.google.com/file/d/1In-DEvdTfYpww8fRFZXqS286m0l-9I6j/view?usp=drive_link'
      ),
      Candidate(
        { en: 'Lanier Coles', zh: '拉尼爾·科爾斯' },
        PARTY.DEM,
        'Healthcare professional',
        'https://drive.google.com/file/d/1S1h1nOpHXa_AOCZi0k_Mkrh4eH7hVvs1/view?usp=drive_link',
        true,
        'headshots/AD19-Lanier-Coles.jpg'
      ),
      Candidate(
        { en: 'Leah LaCroix', zh: '莉亞 ‧ 拉華克' },
        PARTY.DEM,
        'Incumbent',
        QS.NO_RESPONSE
      ),
      Candidate({ en: 'Mano Raju', zh: '力儲文' }, PARTY.DEM, 'Public Defender', QS.NO_RESPONSE),
      Candidate(
        { en: 'Marjan Philhour', zh: '邁珍' },
        PARTY.DEM,
        'Small Business Owner, Candidate for Supervisor, District 1',
        'https://drive.google.com/file/d/1Xl6ww-EVFzhQPrJE92RUfky28k_l7kId/view?usp=drive_link',
        true,
        'headshots/AD19-Marjan-Philhour.jpg'
      ),
      Candidate(
        { en: 'Michela Alioto-Pier', zh: '麥希娜 阿里奧圖-皮雅' },
        PARTY.DEM,
        'Small Business Owner',
        'https://drive.google.com/file/d/1AwfkXLpWGyjG_tgSMg32-Om4Gc4skXiH/view?usp=drive_link',
        true,
        'headshots/AD19-Michela-Alioto-Pier.jpg'
      ),
      Candidate(
        { en: 'Mike Chen', zh: '陳懋華' },
        PARTY.DEM,
        'Data Engineer',
        'https://drive.google.com/file/d/16ZII4SfMNdbfF35Q2LY27Hk38GggFvDe/view?usp=drive_link',
        true,
        'headshots/AD19-Mike-Chen.jpg'
      ),
      Candidate({ en: 'Natalie Gee', zh: '朱凱勤' }, PARTY.DEM, 'Legislative Aide', QS.NO_RESPONSE),
      Candidate(
        { en: 'Parag Gupta', zh: '帕拉格·古普塔' },
        PARTY.DEM,
        'Affordable Housing Executive',
        'https://drive.google.com/file/d/1_QxdyAI9WkyVcswZ54ge61Lz34AXehmJ/view?usp=drive_link',
        true,
        'headshots/AD19-Parag-Gupta.jpg'
      ),
      Candidate(
        { en: 'Queena Chen', zh: '陳慧君' },
        PARTY.DEM,
        'Administrative Analyst',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Sandra Lee Fewer', zh: '李麗嫦' },
        PARTY.DEM,
        'Retired Public Servant (Former Supervisor, District 1)',
        QS.NO_RESPONSE
      ),
      Candidate(
        { en: 'Sara Barz', zh: '莎拉·巴爾茲' },
        PARTY.DEM,
        ' Transit Professional / Mother',
        'https://drive.google.com/file/d/11vnEGKZCRf1tTEsG_jmdfgOfOYevTrlU/view?usp=drive_link',
        true,
        'headshots/AD19-Sara-Barz.jpg'
      ),
    ],
    stateOrder,
    {
      en: 'Vote for ALL 10 candidates',
      zh: '而是投票給所有 10 名候選人',
    }
  ),
  'Republican County Central Committee, District 17': Contest(
    JURISDICTION.OTHER,
    'Republican County Central Committee, District 17',
    [
      Candidate(
        { en: 'Bill (William) Jackson', zh: '比爾·傑克遜' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Bruce Lou', zh: '樓成生' }, PARTY.REP, 'Business Owner', QS.NOT_SENT),
      Candidate(
        { en: 'Charles Page Chamberlain', zh: '查爾斯·佩奇·張伯倫' },
        PARTY.REP,
        'Professor',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Christian J. Foster', zh: '克里斯蒂安·J·福斯特' },
        PARTY.REP,
        'Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Christopher G. Lewis', zh: '克里斯多福·路易斯' },
        PARTY.REP,
        'Bank Executive',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Colton Weeks', zh: '科爾頓·威克斯' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'David Cuadro', zh: '大衛·庫德羅' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Jamie Wong', zh: '李凱明' },
        PARTY.REP,
        'Community Volunteer / Homemaker',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Jason Chuyuan Zeng', zh: '曾楚源' },
        PARTY.REP,
        'Data Engineer',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Jennie Feldman', zh: '珍妮·費德曼' }, PARTY.REP, 'Attorney', QS.NOT_SENT),
      Candidate({ en: 'Joshua Wolff', zh: '喬許·沃爾夫' }, PARTY.REP, 'Entrepreneur', QS.NOT_SENT),
      Candidate({ en: 'Larry Marso', zh: '馬素力' }, PARTY.REP, 'Incumbent', QS.NOT_SENT),
      Candidate({ en: 'Leslie Huang', zh: '雷斯利·黃' }, PARTY.REP, 'Accountant', QS.NOT_SENT),
      Candidate(
        { en: 'Manuel Noris-Barrera', zh: '李明杰' },
        PARTY.REP,
        'Business Owner / Realtor',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Min Chang', zh: '張珉' }, PARTY.REP, 'Chief Strategy Officer', QS.NOT_SENT),
      Candidate(
        { en: 'William Kirby Shireman', zh: '威廉·柯比·希爾曼' },
        PARTY.REP,
        'Energy Solutions Entrepreneur',
        QS.NOT_SENT
      ),
    ],
    stateOrder
  ),
  'Republican County Central Committee, District 19': Contest(
    JURISDICTION.OTHER,
    'Republican County Central Committee, District 19',
    [
      Candidate(
        { en: 'Bob Rintel', zh: '鮑伯·林特爾' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Clinton Griess', zh: '克林特 ‧ 格里斯' },
        PARTY.REP,
        'Business Consultant',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Deah Williams', zh: '迪亞·威廉斯' },
        PARTY.REP,
        'Security Dispatcher',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Edward Bate', zh: '愛德華·貝特' }, PARTY.REP, '', QS.NOT_SENT, false, null),
      Candidate(
        { en: 'Grazia Monares', zh: '葛拉齊亞·莫納雷斯' },
        PARTY.REP,
        'Property Manager',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Howard Epstein', zh: '魯迪 ‧ 阿瑟西昂' },
        PARTY.REP,
        'Retired Business Owner',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Jacob Spangler', zh: '雅各·斯潘格勒' },
        PARTY.REP,
        'Sales Consultant',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Jan Diamond', zh: '珍·戴蒙德' },
        PARTY.REP,
        'Real Estate Manager',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Jason Clark', zh: '傑森 ‧ P ‧ 克拉克' }, PARTY.REP, 'Attorney', QS.NOT_SENT),
      Candidate({ en: 'Jay Donde', zh: '傑·唐德' }, PARTY.REP, 'Attorney', QS.NOT_SENT),
      Candidate({ en: 'Jennifer Yan', zh: '嚴正人' }, PARTY.REP, 'Financial Analyst', QS.NOT_SENT),
      Candidate(
        { en: 'Jeremiah Boehner', zh: '耶利米·博納' },
        PARTY.REP,
        'Entrepreneur',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Joan Leone', zh: '瓊安 ‧ 里昂尼' }, PARTY.REP, 'Incumbent', QS.NOT_SENT),
      Candidate({ en: 'John Dennis', zh: '約翰 · 丹尼斯' }, PARTY.REP, 'Incumbent', QS.NOT_SENT),
      Candidate(
        { en: 'Joseph Coyne Bleckman', zh: '約瑟夫·科因·布萊克曼' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Lisa Remmer', zh: '麗莎·雷默' }, PARTY.REP, 'Teacher', QS.NOT_SENT),
      Candidate(
        { en: 'Martha Conte', zh: '瑪莎·康特' },
        PARTY.REP,
        'Community Volunteer',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Monika Rothenbuhler', zh: '莫妮卡·羅森布勒' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Nicholas Berg', zh: '尼可拉斯·伯格' },
        PARTY.REP,
        'Property Management Executive',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Peter A. Pusateri', zh: '彼得·A·普薩特里' },
        PARTY.REP,
        'Retired Union Chairman',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Peter J. Elden', zh: '彼得·埃爾登' },
        PARTY.REP,
        'Facilities Management Executive',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Philip Louis Wing', zh: '雷樹榮' }, PARTY.REP, '', QS.NOT_SENT, false, null),
      Candidate({ en: 'Rodney Leong', zh: '梁振俊' }, PARTY.REP, 'Incumbent', QS.NOT_SENT),
      Candidate(
        { en: 'Rudy Asercion', zh: '魯迪 ‧ 阿瑟西昂' },
        PARTY.REP,
        'Incumbent',
        QS.NOT_SENT
      ),
      Candidate({ en: 'Stephanie Jeong', zh: '張嬌蓮' }, PARTY.REP, 'Incumbent', QS.NOT_SENT),
      Candidate(
        { en: 'Thomas Rapkoch', zh: '湯姆·拉普科奇' },
        PARTY.REP,
        'Product Director',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Thomas Sleckman', zh: '托馬斯·斯萊克曼' },
        PARTY.REP,
        ' Data Scientist / Musician',
        QS.NOT_SENT
      ),
      Candidate(
        { en: 'Yvette Corkrean', zh: '依薇特·科克安' },
        PARTY.REP,
        'Appointed Incumbent',
        QS.NOT_SENT
      ),
    ],
    stateOrder
  ),
  'Green Party County Council': Contest(
    JURISDICTION.OTHER,
    'Green Party County Council',
    [
      Candidate('Barry Hermanson', PARTY.GRN, '', QS.NOT_SENT),
      Candidate('John Chandonia', PARTY.GRN, '', QS.NOT_SENT),
      Candidate('Richard William Stone III', PARTY.GRN, '', QS.NOT_SENT),
    ],
    stateOrder
  ),
  'Peace and Freedom County Central Committee': Contest(
    JURISDICTION.OTHER,
    'Peace and Freedom County Central Committee',
    [
      Candidate('Ekaterine Wu', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Gloria Estela La Riva', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Meghann Adams', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Ramsey Robinson', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Richard Becker', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Silvio Lopes de Oliveira', PARTY.PF, '', QS.NOT_SENT),
      Candidate('Thomas Lacey', PARTY.PF, '', QS.NOT_SENT),
    ],
    stateOrder
  ),
  'Judge, Seat 1': Judge(JURISDICTION.LOCAL, { en: 'Judge, Seat 1', zh: '法官，第 1 號席位' }, [
    Candidate(
      { en: 'Michael I. Begert', zh: '麥可·伊薩庫·貝格特' },
      PARTY.NP,
      'Incumbent Superior Court Judge',
      'https://drive.google.com/file/d/1mA2iwvS1LDpKj6Vo830QhGqq-gkrd8i1/view?usp=drive_link'
    ),
    Candidate(
      { en: 'Albert "Chip" Zecher', zh: '奇普·澤克' },
      PARTY.NP,
      'Attorney; Vice Chair of UC Law Board of Directors',
      'https://drive.google.com/file/d/1YwdU7OUwHar4h4P3jFcEzWLFf8UFDjjN/view?usp=drive_link',
      true
    ),
  ]),
  'Judge, Seat 13': Judge(JURISDICTION.LOCAL, { en: 'Judge, Seat 13', zh: '法官，第 13 號席位' }, [
    Candidate(
      { en: 'Patrick Thompson', zh: '派崔克·S·湯普森' },
      PARTY.NP,
      'Incumbent Superior Court Judge',
      'https://drive.google.com/file/d/1hM-xm3rppPRTig5VXulgRBXvXpxWJkOT/view?usp=drive_link'
    ),
    Candidate(
      { en: 'Jean Myungjin Roland', zh: '姜明振' },
      PARTY.NP,
      'Assistant District Attorney',
      'https://drive.google.com/file/d/1lYc-xUOOdyj4BI3CEPMn5TmqTrruaxJe/view?usp=drive_link',
      true
    ),
  ]),
  'Proposition 1': Measure(
    JURISDICTION.STATE,
    { en: 'Behavioral Health Services Program and Bond Measure', zh: '行為健康服務計劃和債券措施' },
    '1',
    ENDORSEMENT.YES,
    'Proposition 1 (March 2024) would fund a $6 billion bond for behavioral health services, including mental health and substance abuse treatment, for veterans and other people experiencing homelessness or at risk of homelessness.'
  ),
  'Proposition A': Measure(
    JURISDICTION.LOCAL,
    { en: 'Affordable Housing Bond', zh: '經濟適用住房債券' },
    'A',
    ENDORSEMENT.YES,
    'Proposition A (San Francisco, March 2024) would '
  ),
  'Proposition B': Measure(
    JURISDICTION.LOCAL,
    { en: 'Cop Tax', zh: '警察稅' },
    'B',
    ENDORSEMENT.NO,
    'Proposition B (San Francisco, March 2024) would '
  ),
  'Proposition C': Measure(
    JURISDICTION.LOCAL,
    { en: 'Convert Vacant Offices to Homes', zh: '將空置辦公室改建為住宅' },
    'C',
    ENDORSEMENT.YES,
    'Proposition C (San Francisco, March 2024) would incentivize the conversion of vacant office space to residential use by exempting the conversion from a real estate transfer tax.'
  ),
  'Proposition D': Measure(
    JURISDICTION.LOCAL,
    { en: 'Changes to Local Ethics Laws', zh: '本地道德法例的修改' },
    'D',
    ENDORSEMENT.YES,
    'Proposition D (San Francisco, March 2024) would '
  ),
  'Proposition E': Measure(
    JURISDICTION.LOCAL,
    { en: 'Police Department Policies and Procedures', zh: '三藩市警察局政策和程序' },
    'E',
    ENDORSEMENT.YES,
    'Proposition E (San Francisco, March 2024) would '
  ),
  'Proposition F': Measure(
    JURISDICTION.LOCAL,
    {
      en: 'Drug & Alcohol Treatment for City Services',
      zh: '藥物和酒精治療以得到市政府服務',
    },
    'F',
    ENDORSEMENT.YES,
    'Proposition F (San Francisco, March 2024) would require substance abuse treatment for people receiving cash assistance from the city.'
  ),
  'Proposition G': Measure(
    JURISDICTION.LOCAL,
    {
      en: 'Eighth Grade Algebra and SFUSD Math Curriculum Development',
      zh: '八年級代數與三藩市聯合校區數學課程開發',
    },
    'G',
    ENDORSEMENT.YES,
    'Proposition G (San Francisco, March 2024) would '
  ),
};

import React from "react";

class UCSFEmbed extends React.Component {
  componentDidMount() {
    console.log("Running UCSF embed");
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//p2a.co/js/embed/widget/advocacywidget.min.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "advocacy-actionwidget-code");
  }

  render() {
    return (
      <div
        className="advocacy-actionwidget"
        data-domain="p2a.co"
        data-shorturl="7IdGfbD"
        style={{ width: "100%", height: "800px" }}
      />
    );
  }
}

export default UCSFEmbed;

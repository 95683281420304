import React, { useEffect, useState } from 'react';
import { YesIcon } from '../helpers';

const PhotoWrapper = ({ endorsed, children }) => {
  const mdxRef = ['photo', 'profession', 'party', 'hint'];
  let data = {
    photo: '',
    profession: '',
    party: '',
    hint: '',
  };
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className="not-prose">
      {React.Children.map(children, (child, index) => {
        data[mdxRef[index]] = React.isValidElement(child) ? React.cloneElement(child) : null;
      })}
      {mounted && (
        <div className="flex flex-col gap-2.5 sm:gap-10 sm:flex-row">
          <div className="flex gap-4 sm:gap-10">
            <div className="mask w-40 h-auto !mt-0 !mb-0 max-w-[150px] max-h-52">{data.photo}</div>
            <div className="flex flex-col gap-2.5 sm:hidden">
              <span className="text-xs w-48 text-brand-blue-5 font-bold uppercase tracking-widest sm:w-auto">
                {data.profession}
              </span>
              <span className="flex text-base text-brand-blue-5 font-bold sm:text-xl">
                {endorsed && <YesIcon />} {data.party}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-2.5">
            <div className="hidden flex-col gap-2.5 sm:flex">
              <span className="text-xs w-48 text-brand-blue-5 font-bold uppercase tracking-widest sm:w-auto">
                {data.profession}
              </span>
              <span className="flex text-base text-brand-blue-5 font-bold sm:text-xl">
                {endorsed && <YesIcon />} {data.party}
              </span>
            </div>
            <span className="w-full max-w-xl text-left text-sm font-light text-gray-700 sm:text-lg my-0 whitespace-pre-wrap">
              {data.hint}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoWrapper;

import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const defaults = `w-full sm:w-auto text-center transition-all sm:hover:scale-110 bg-size-200 bg-pos-0 hover:bg-pos-100 inline-block font-bold px-6 py-4 uppercase rounded-sm disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:hover:bg-pos-0`;

const SIZE_CLASSNAMES = {
  sm: 'text-xs',
  md: 'text-base',
  lg: 'text-lg',
};

const COLOR_CLASSNAMES = {
  blue: 'text-gray-50 bg-gradient-to-r from-brand-blue-4 via-brand-blue-4 to-brand-green-3',
  'dark-blue': 'text-gray-50 bg-gradient-to-r from-brand-blue-5 via-brand-blue-5 to-brand-blue-4',
  green:
    'text-brand-blue-5 bg-gradient-to-r from-brand-green-3 via-brand-green-3 to-brand-yellow-2',
};

export const SuperButton = ({
  children,
  className,
  to,
  href,
  type,
  size,
  color,
  onClick,
  disabled = false,
}) => {
  // If you specify 'to', it will return a Gatsby Link component
  // for internal links, if you specify 'href' it will return an
  // OutboundLink component for external links, and if you don't
  // specify either it will return a button where you can specify
  // type.
  const classNames = `${defaults} ${SIZE_CLASSNAMES[size]} ${COLOR_CLASSNAMES[color]} ${className}`;

  if (to) {
    return (
      <Link to={to} className={classNames} onClick={onClick}>
        {children}
      </Link>
    );
  }
  if (href) {
    return (
      <OutboundLink href={href} className={classNames} onClick={onClick}>
        {children}
      </OutboundLink>
    );
  }
  return (
    <button type={type} className={classNames} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/autoplay';

/* 
breakpoints = {
  // when window width is >= 640px -- sm
  640: {
    slidesPerView: 2,
    spaceBetween: 40,
  },
  // when window width is >= 768px -- md
  768: {
    slidesPerView: 2,
    spaceBetween: 40,
  },
  // when window width is >= 1024px -- lg
  1024: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
  // when window width is >= 1280px -- xl
  1280: {
    slidesPerView: 3,
    spaceBetween: 40,
  },
}
 */

const Carousel = ({
  children,
  className,
  childClassName,
  spaceBetween = 50,
  slidesPerView = 1,
  breakpoints,
  navigation = false,
  ...props
}) => {
  return (
    <Swiper
      className={className || ''}
      modules={[Navigation, Autoplay]}
      navigation={navigation}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      {...(breakpoints && (breakpoints = { breakpoints }))}
      {...props}
    >
      {React.Children.map(children, (child, index) => (
        <SwiperSlide key={`SwiperSlide_${index}`} className={childClassName || ''}>
          {React.isValidElement(child)
            ? React.cloneElement(child, {
                /*any props want to pass to children*/
              })
            : null}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Carousel;

import React from 'react';
import S4PLogo from '../images/partners/s4p-banner-transparent.png';
import GGRALogo from '../images/partners/GGRA-logo-transparent.png';
import SFYIMBY from '../images/partners/sfyimby.png';
import GTR from '../images/partners/gtr.png';
import ENDC from '../images/partners/endc.jpg';
import EOMM from '../images/partners/eomm.jpg';

export const SharedSpacesCosponsors = () => {
  return (
    <div>
      <h2>Partners</h2>
      <div className="flex justify-items-center flex-col items-center">
        <div className="w-2/3 p-42">
          <a href="https://ggra.org/" target="_blank">
            <img src={GGRALogo} className="sm:block rounded-xl" />
          </a>
        </div>
        <div className="w-2/3">
          <a href="http://streetsforpeople.city" target="_blank">
            <img src={S4PLogo} className="sm:block" />
          </a>
        </div>
      </div>

      <h2>Co-sponsors</h2>
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="w-1/4">
            <a href="//sfyimby.org" target="_blank">
              <img src={SFYIMBY} className="sm:block" />
            </a>
          </div>
          <div className="w-1/4">
            <a href="//growtherichmond.com" target="_blank">
              <img src={GTR} className="sm:block" />
            </a>
          </div>
          <div className="w-1/4">
            <a href="//sfendc.com" target="_blank">
              <img src={ENDC} className="sm:block" />
            </a>
          </div>
        </div>
        <div className="flex justify-between">
          <div className="w-1/4">
            <a href="https://eomm.org/" target="_blank">
              <img src={EOMM} className="sm:block" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

export const ControlTable = () => {
  const green = 'bg-green-100';
  const red = 'bg-red-100';
  const blue = 'bg-blue-100';
  const orange = 'bg-orange-100';
  const white = 'bg-white';

  const header = [
    'name',
    '1996',
    '1998',
    '2000',
    '2002',
    '2004',
    '2006',
    '2008',
    '2010',
    '2012',
    '2014',
    '2016',
    '2018',
    '2020',
    '2022',
  ];
  const rows = [
    {
      name: 'Planning',
      link: '#planning-commission',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'bos',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Appeals',
      link: '#planning-commission',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'bos',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Human Rights',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Fire',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Police',
      link: '#police-commission',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Health',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Human Services',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Public Utilities',
      link: '#public-utilities-commission',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Rec & Park',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Port',
      1996: 'bos',
      1998: 'bos',
      2000: 'bos',
      2002: 'bos',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Airport',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Parking & Traffic',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: null,
      2004: null,
      2006: null,
      2008: null,
      2010: null,
      2012: null,
      2014: null,
      2016: null,
      2018: null,
      2020: null,
      2022: null,
    },
    {
      name: 'Public Transportation',
      1996: 'mayor',
      1998: 'mayor',
      2000: null,
      2002: null,
      2004: null,
      2006: null,
      2008: null,
      2010: null,
      2012: null,
      2014: null,
      2016: null,
      2018: null,
      2020: null,
      2022: null,
    },
    {
      name: 'MTA Board',
      link: '#mta-board',
      1996: null,
      1998: null,
      2000: 'bos',
      2002: 'bos',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Entertainment',
      link: '#entertainment-commission',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Environment',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Status of Women',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Disability and Aging Services',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Building Inspection',
      link: '#building-inspection-commission',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'bos',
    },
    {
      name: 'Youth',
      1996: 'bos',
      1998: 'bos',
      2000: 'bos',
      2002: 'bos',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Homelessness Oversight',
      link: '#homelessness-oversight-commission',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: null,
      2006: null,
      2008: null,
      2010: null,
      2012: null,
      2014: null,
      2016: null,
      2018: null,
      2020: null,
      2022: 'bos',
    },
    {
      name: 'Small Business',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Historic Preservation',
      link: '#historic-preservation-commission',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: null,
      2006: null,
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Police Accountability',
      link: '#department-of-police-accountability',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: "Sheriff's Department Oversight Board",
      link: '#sheriffs-department-oversight-board',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: null,
      2006: null,
      2008: null,
      2010: null,
      2012: null,
      2014: null,
      2016: null,
      2018: null,
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Sanitation and Streets',
      link: '#sanitation-and-streets-public-works',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: null,
      2006: null,
      2008: null,
      2010: null,
      2012: null,
      2014: null,
      2016: null,
      2018: null,
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Public Works Commission',
      link: '#sanitation-and-streets-public-works',
      1996: null,
      1998: null,
      2000: null,
      2002: null,
      2004: null,
      2006: null,
      2008: null,
      2010: null,
      2012: null,
      2014: null,
      2016: null,
      2018: null,
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Arts',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
    {
      name: 'Executive Branch re-org',
      1996: 'bos',
      1998: 'bos',
      2000: 'bos',
      2002: 'bos',
      2004: 'bos',
      2006: 'bos',
      2008: 'bos',
      2010: 'bos',
      2012: 'bos',
      2014: 'bos',
      2016: 'bos',
      2018: 'bos',
      2020: 'bos',
      2022: 'bos',
    },
    {
      name: 'Library',
      1996: 'mayor',
      1998: 'mayor',
      2000: 'mayor',
      2002: 'mayor',
      2004: 'mayor',
      2006: 'mayor',
      2008: 'mayor',
      2010: 'mayor',
      2012: 'mayor',
      2014: 'mayor',
      2016: 'mayor',
      2018: 'mayor',
      2020: 'mayor',
      2022: 'mayor',
    },
  ];
  const peskin = {
    1996: false,
    1998: false,
    2000: true,
    2002: true,
    2004: true,
    2006: true,
    2008: true,
    2010: false,
    2012: false,
    2014: false,
    2016: true,
    2018: true,
    2020: true,
    2022: true,
  };

  const rowNameClass =
    '!px-1 !py-1 border-b border-r border-gray-200 bg-white text-sm max-w-[100px] overflow-visible whitespace-nowrap';

  const isTransition = (year) => {
    return year === '2002' || year === '2010' || year === '2016';
  };

  let mayorControl = {};
  let bosControl = {};

  return (
    <table className="not-prose min-w-full leading-normal">
      <thead>
        <tr>
          {header.map((head) => (
            <th
              key={head}
              className="!px-1 !py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
            >
              {head === 'name' ? 'Commission' : head}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {header.map((col) => {
              if (col === 'name') {
                if (row.link) {
                  return (
                    <td key={col} className={rowNameClass}>
                      <a href={row.link} className="text-brand-blue-4 underline">
                        {row[col]}
                      </a>
                    </td>
                  );
                } else {
                  return (
                    <td key={col} className={rowNameClass}>
                      {row[col]}
                    </td>
                  );
                }
              } else if (col != 'link') {
                let year = col;
                let borderRight = 'border-r';
                if (isTransition(year)) {
                  borderRight = 'border-r border-r-4 border-r-gray-500';
                }
                if (row[year] === 'mayor') {
                  if (mayorControl[year]) {
                    mayorControl[year] += 1;
                  } else {
                    mayorControl[year] = 1;
                  }
                } else if (row[year] === 'bos') {
                  if (bosControl[year]) {
                    bosControl[year] += 1;
                  } else {
                    bosControl[year] = 1;
                  }
                }

                return (
                  <td
                    key={year}
                    className={`!px-1 !py-1 border-b ${borderRight} border-gray-200 text-sm ${
                      row[year] === 'mayor'
                        ? green
                        : row[year] === 'bos'
                        ? red
                        : row[year] === null
                        ? white
                        : 'bg-white'
                    }`}
                  >
                    &nbsp;
                  </td>
                );
              }
            })}
          </tr>
        ))}
        <tr>
          <td className={rowNameClass}></td>
          <td
            colSpan="4"
            className="!px-1 !py-1 border-b border-gray-200 border-r-4 border-r-gray-500 bg-white text-sm overflow-visible whitespace-nowrap"
          >
            Willie Brown
          </td>

          <td
            colSpan="4"
            className="!px-1 !py-1 border-b border-gray-200 border-r-4 border-r-gray-500 bg-white text-sm overflow-visible whitespace-nowrap"
          >
            Gavin Newsom
          </td>
          <td
            colSpan="3"
            className="!px-1 !py-1 border-b border-gray-200 border-r-4 border-r-gray-500 bg-white text-sm overflow-visible whitespace-nowrap"
          >
            Ed Lee
          </td>
          <td
            colSpan="3"
            className="!px-1 !py-1 border-b border-gray-200 border-r bg-white text-sm overflow-visible whitespace-nowrap"
          >
            London Breed
          </td>
        </tr>
        <tr>
          <td className={rowNameClass}>Mayor Control #</td>
          {header.map((col) => {
            if (col === 'name') {
              return;
            }
            let borderRight = 'border-r';
            if (isTransition(col)) {
              borderRight = 'border-r border-r-4 border-r-gray-500';
            }
            let bg = orange;
            if (mayorControl[col] < bosControl[col]) {
              bg = red;
            } else if (mayorControl[col] > bosControl[col]) {
              bg = green;
            }
            return (
              <td
                key={col}
                className={`!px-1 !py-1 border-b ${borderRight} border-gray-200 ${bg} text-sm`}
              >
                {mayorControl[col]}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className={rowNameClass}>BOS Control #</td>
          {header.map((col) => {
            if (col === 'name') {
              return;
            }
            let borderRight = 'border-r';
            if (isTransition(col)) {
              borderRight = 'border-r border-r-4 border-r-gray-500';
            }
            let bg = orange;
            if (bosControl[col] > mayorControl[col]) {
              bg = green;
            } else if (bosControl[col] < mayorControl[col]) {
              bg = red;
            }
            return (
              <td
                key={col}
                className={`!px-1 !py-1 border-b ${borderRight} border-gray-200 ${bg} text-sm`}
              >
                {bosControl[col]}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className={rowNameClass}>Peskin in office?</td>
          {header.map((col) => {
            if (col === 'name') {
              return;
            }
            let borderRight = 'border-r';
            if (isTransition(col)) {
              borderRight = 'border-r border-r-4 border-r-gray-500';
            }
            let bg = white;
            if (peskin[col]) {
              bg = blue;
            }
            return (
              <td
                key={col}
                className={`!px-1 !py-1 border-b ${borderRight} border-gray-200 ${bg} text-sm`}
              >
                &nbsp;
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

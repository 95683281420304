import React from 'react';
import No from '../images/icons/icon-no.svg';
import Yes from '../images/icons/icon-yes.svg';
// This is basically random stuff where we don't need to create a bunch of individual component files for... maybe

import {
  TwitterIcon,
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  EmailIcon,
  EmailShareButton,
} from 'react-share';

export const SocialShare = ({ url, message }) => {
  const size = 48;
  return (
    <div className="text-2xl my-4">
      <div>
        <span className="mr-4">
          <EmailShareButton url={url} subject={message}>
            <EmailIcon size={size} round="true" />
          </EmailShareButton>
        </span>
        <span className="mr-4">
          <TwitterShareButton url={url} title={message}>
            <TwitterIcon size={size} round="true" />
          </TwitterShareButton>
        </span>
        <span className="mr-4">
          <LinkedinShareButton url={url} title={message}>
            <LinkedinIcon size={size} round="true" />
          </LinkedinShareButton>
        </span>
        <span className="mr-4">
          <FacebookShareButton url={url} quote={message}>
            <FacebookIcon size={size} round="true" />
          </FacebookShareButton>
        </span>
      </div>
    </div>
  );
};

export const GreenText = ({ children, alt, ...props }) => (
  <span className="!text-green-500 font-bold" {...props}>
    {children}
  </span>
);

export const RedText = ({ children, alt, ...props }) => (
  <span className="!text-red-400 font-bold" {...props}>
    {children}
  </span>
);

export const Bold = ({ children, alt, ...props }) => (
  <b className="text-xs font-bold text-brand-gray-5 whitespace-nowrap" {...props}>
    {children}
  </b>
);

export const YesIcon = ({ alt, ...props }) => (
  <img className="inline mr-2 !my-0" {...props} src={Yes} alt="yes" />
);

export const NoIcon = ({ alt, ...props }) => (
  <img className="inline mr-2 !my-0" {...props} src={No} alt="no" />
);

export function sanitizedPathForSlugOrigin() {
  return typeof window !== `undefined` ? window.location.pathname.replace(/^\//, '') : '';
}

import React, { useState } from 'react';
import isValidEmail from '../utils/validateEmail';
import { trackSignUp } from './analytics';
import { SubmitButton } from './layout/submit_button';
import { sanitizedPathForSlugOrigin } from './helpers';
import { twMerge } from 'tailwind-merge';

export const SignupForm = ({ formStyle, formId = '', list_id, onSuccess, isGreen, oneRow }) => {
  // TODO: create a friendly mapping of list names to list_id

  const SubmitButtonStyle = `whitespace-nowrap text-xs transition-all enabled:hover:scale-110 enabled:hover:bg-gradient-to-r enabled:hover:from-brand-blue-4 border-0 enabled:hover:via-brand-blue-4 enabled:hover:to-brand-green-3 bg-size-200 bg-pos-0 enabled:hover:bg-pos-100 inline-block font-bold px-6 py-4 uppercase rounded-sm cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed ${
    isGreen ? 'bg-brand-green-3 text-black' : 'bg-brand-blue-4 text-gray-50'
  }`;
  const InputFieldStyle =
    'block w-full border border-gray-400 rounded-sm p-2.5 text-base text-gray-900 placeholder-gray-400 focus:border-brand-blue-4 bg-gray-100 focus:ring-brand-blue-4 disabled:opacity-50 disabled:cursor-not-allowed sm:px-5 sm:py-3';

  const [state, setState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    postal_code: '',
    phone_number: '',
    status: 'ready',
    isEmailValid: true,
    onBlurEmail: false,
  });

  const updateValue = (inputName) => {
    return (e) => {
      const updatedState = { ...state, [inputName]: e.target.value };
      if (inputName === 'email') updatedState.isEmailValid = isValidEmail(updatedState.email);
      setState(updatedState);
    };
  };

  const btnDisabled =
    state.status !== 'ready' ||
    state.email === '' ||
    !state.isEmailValid ||
    (formStyle === 'full' &&
      (state.first_name === '' ||
        state.last_name === '' ||
        state.phone_number === '' ||
        state.postal_code === ''));

  var slug_origin = sanitizedPathForSlugOrigin();

  const onSubmit = () => {
    if (!btnDisabled) {
      const { first_name, last_name, email, postal_code, phone_number } = state;
      setState({ ...state, status: 'loading' });
      fetch('/.netlify/functions/email', {
        method: 'PUT',
        body: JSON.stringify({
          first_name,
          last_name,
          email,
          postal_code,
          phone_number,
          slug_origin,
          list_id,
        }),
      })
        .then((res) => {
          if (res.status >= 200 && res.status < 400) {
            if (typeof window !== `undefined`) {
              trackSignUp({
                method: formStyle,
                path: window.location.pathname,
                email: email,
              });
            }
            localStorage?.setItem(
              'popup',
              JSON.stringify({
                ...localStorage?.getItem('popup'),
                subscribed: 'true',
              })
            );
            onSuccess && setTimeout(() => onSuccess(), 1000);
            return res.json();
          } else throw new Error(`Request failed with status ${res.status}: ${res.body}`);
        })
        .then((res) => {
          setState({
            first_name: '',
            last_name: '',
            email: '',
            postal_code: '',
            phone_number: '',
            status: 'done',
            isEmailValid: true,
            onBlurEmail: false,
          });
        })
        .catch((err) => {
          console.error(err);
          setState({
            ...state,
            status: 'ready',
          });
        });
    }
  };

  if (!formStyle || formStyle === 'full') {
    return (
      <form className="w-full max-w-lg">
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full px-3">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor={'signup-email' + formId}
            >
              Email
            </label>
            <input
              required
              autoComplete="email"
              value={state.email}
              onChange={updateValue('email')}
              onBlur={(e) => {
                setState({ ...state, onBlurEmail: true });
              }}
              onFocus={(e) => {
                setState({ ...state, onBlurEmail: false });
              }}
              className={`block w-full border ${
                state.isEmailValid
                  ? 'border-gray-300 focus:border-brand-blue-4 focus:ring-brand-blue-4'
                  : 'border-red-500 focus:outline-none'
              } rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-md`}
              id={'signup-email' + formId}
              type="email"
              placeholder="jane.doe@example.com"
            ></input>
            {!state.isEmailValid && state.onBlurEmail && (
              <p className="text-red-500 text-xs italic">Please use a valid email address.</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor={'signup-first-name' + formId}
            >
              First Name
            </label>
            <input
              value={state.first_name}
              onChange={updateValue('first_name')}
              className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-first-name' + formId}
              type="text"
              placeholder="Jane"
            ></input>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor={'signup-last-name' + formId}
            >
              Last Name
            </label>
            <input
              value={state.last_name}
              onChange={updateValue('last_name')}
              className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-last-name' + formId}
              type="text"
              placeholder="Doe"
            ></input>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor={'signup-phone-number' + formId}
            >
              Phone Number
            </label>
            <input
              value={state.phone_number}
              onChange={updateValue('phone_number')}
              className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-phone-number' + formId}
              type="tel"
              placeholder="(415)-555-8039"
            ></input>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              htmlFor={'signup-postal_code' + formId}
            >
              Zipcode
            </label>
            <input
              value={state.postal_code}
              onChange={updateValue('postal_code')}
              className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-postal_code' + formId}
              type="text"
              placeholder="94110"
            ></input>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <SubmitButton
              onSubmit={onSubmit}
              disabled={btnDisabled}
              className="transition duration-200 shadow-md w-full bg-brand-blue-4 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
              hoverClass="hover:bg-blue-400"
              disabledClass="opacity-50 cursor-not-allowed"
              status={state.status}
            />
          </div>
          <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0" />
        </div>
      </form>
    );
  } else if (formStyle === 'full-compact') {
    return (
      <form className="w-full">
        <div className="flex flex-wrap -mx-3 md:mb-4 mb-0">
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
              htmlFor="signup-email"
            >
              Email
            </label>
            <input
              required
              autoComplete="email"
              value={state.email}
              onChange={updateValue('email')}
              onBlur={(e) => {
                setState({ ...state, onBlurEmail: true });
              }}
              onFocus={(e) => {
                setState({ ...state, onBlurEmail: false });
              }}
              className={`block w-full border ${
                state.isEmailValid
                  ? 'border-gray-300 focus:border-brand-blue-4 focus:ring-brand-blue-4'
                  : 'border-red-500 focus:outline-none'
              } rounded-md px-2 py-1 text-base text-gray-900 placeholder-gray-300 shadow-md`}
              id="signup-email"
              type="email"
              placeholder="jane.doe@example.com"
            ></input>
            {!state.isEmailValid && state.onBlurEmail && (
              <p className="text-red-500 text-xs italic">Please use a valid email address.</p>
            )}
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
              htmlFor={'signup-first-name' + formId}
            >
              First Name <span className="text-gray-400 lowercase">(optional)</span>
            </label>
            <input
              value={state.first_name}
              onChange={updateValue('first_name')}
              className="block w-full border border-gray-300 rounded-md px-2 py-1 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-first-name' + formId}
              type="text"
              placeholder="Jane"
            ></input>
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
              htmlFor={'signup-last-name' + formId}
            >
              Last Name <span className="text-gray-400 lowercase">(optional)</span>
            </label>
            <input
              value={state.last_name}
              onChange={updateValue('last_name')}
              className="block w-full border border-gray-300 rounded-md px-2 py-1 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-last-name' + formId}
              type="text"
              placeholder="Doe"
            ></input>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-0 md:mb-2">
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
              htmlFor={'signup-phone-number' + formId}
            >
              Phone Number <span className="text-gray-400 lowercase">(optional)</span>
            </label>
            <input
              value={state.phone_number}
              onChange={updateValue('phone_number')}
              className="block w-full border border-gray-300 rounded-md px-2 py-1 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-phone-number' + formId}
              type="tel"
              placeholder="(415)-555-8039"
            ></input>
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1"
              htmlFor={'signup-postal_code' + formId}
            >
              Zipcode <span className="text-gray-400 lowercase">(optional)</span>
            </label>
            <input
              value={state.postal_code}
              onChange={updateValue('postal_code')}
              className="block w-full border border-gray-300 rounded-md px-2 py-1 text-base text-gray-900 placeholder-gray-300 shadow-md focus:border-brand-blue-4 focus:ring-brand-blue-4"
              id={'signup-postal_code' + formId}
              type="text"
              placeholder="94110"
            ></input>
          </div>
          <div className="w-full md:w-1/3 px-3 mb-3 md:mb-0 mt-2 md:mt-5">
            <label className="hidden md:visible block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1">
              &nbsp;
            </label>
            <SubmitButton
              onSubmit={onSubmit}
              disabled={btnDisabled}
              className="transition duration-200 shadow-md w-full bg-brand-blue-4 focus:shadow-outline focus:outline-none text-white font-bold py-0 px-0 rounded"
              hoverClass="hover:bg-blue-400"
              disabledClass="opacity-50 cursor-not-allowed"
              status={state.status}
            />
          </div>
        </div>
      </form>
    );
  } else if (formStyle === 'home') {
    return (
      <form
        className={`flex w-full gap-2.5 sm:max-w-lg ${
          oneRow
            ? 'flex-row max-w-full items-center'
            : 'flex-col justify-start items-start lg:flex-row lg:items-center'
        }`}
      >
        <div className="w-full">
          <label htmlFor={'hero_email' + formId} className="sr-only">
            Email address
          </label>
          <input
            id={'hero_email' + formId}
            type="email"
            required
            value={state.email}
            onChange={updateValue('email')}
            className={InputFieldStyle}
            placeholder="Your email address"
          />
        </div>
        <SubmitButton
          onSubmit={onSubmit}
          disabled={btnDisabled}
          hoverClass=""
          className={twMerge(SubmitButtonStyle, 'w-full lg:w-auto')}
          status={state.status}
        />
      </form>
    );
  } else if (formStyle === 'footer') {
    return (
      <form className="mt-4 sm:flex sm:max-w-md">
        <label htmlFor={'emailAddress' + formId} className="sr-only">
          Email address
        </label>
        <input
          type="email"
          name={'emailAddress' + formId}
          id={'emailAddress' + formId}
          autoComplete="email"
          value={state.email}
          onChange={updateValue('email')}
          required
          className="appearance-none min-w-0 w-full bg-white border border-transparent rounded-md py-2 px-4 text-base text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white focus:border-white focus:placeholder-gray-300"
          placeholder="Enter your email"
        />
        <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <SubmitButton
            onSubmit={onSubmit}
            disabled={btnDisabled}
            className="transition duration-200 w-full bg-brand-blue-4 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-brand-blue-4"
            hoverClass="hover:bg-brand-blue-3"
            disabledClass="opacity-50 cursor-not-allowed"
            status={state.status}
          />
        </div>
      </form>
    );
  }
};

import { TLDR } from '../../../../src/components/election/tldr';
import { MeasureInfo } from '../../../../src/components/election/measure';
import { CandidateInfo, CandidateHeadshots, JudgeInfo } from '../../../../src/components/election/candidate_info';
import { VoterGuideFAQ } from '../../../../src/components/election/voter-guide-faq';
import BgWhiteWrapper from '../../../../src/components/election/bgWhiteWrapper';
import { mar24_contests } from '../../../../src/data/data-march-2024.js';
import Carousel from '../../../../src/components/layout/carousel';
import * as React from 'react';
export default {
  TLDR,
  MeasureInfo,
  CandidateInfo,
  CandidateHeadshots,
  JudgeInfo,
  VoterGuideFAQ,
  BgWhiteWrapper,
  mar24_contests,
  Carousel,
  React
};
import React from 'react';
import { twMerge } from 'tailwind-merge';

const Profile = ({ children, name, role, className = '' }) => {
  return (
    <div className={twMerge('not-prose profile flex flex-row gap-4', className)}>
      <div className="w-14 h-14 my-auto flex-shrink-0 overflow-hidden isolate rounded-full">
        {children}
      </div>
      <div className="flex flex-col gap-.5 justify-center [&_a]:text-brand-blue-4">
        {name && (
          <span className="name text-left text-base font-bold capitalize text-brand-blue-5">
            {name}
          </span>
        )}
        {role && (
          <span className="role text-left text-sm font-medium text-gray-500 tracking-wide">
            {role}
          </span>
        )}
      </div>
    </div>
  );
};

export default Profile;

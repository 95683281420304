import React from 'react';
import slugify from 'react-slugify';

const Person = ({ key, name, title, description }) => {
  const anchor = slugify(name);
  const link = `#${anchor}`;
  return (
    <>
      <div key={key}>
        <div className="space-y-4">
          <div className="text-lg leading-6 font-medium space-y-1">
            <h3 className="!mt-2 !mb-0">
              <a
                style={{
                  fontWeight: 'inherit',
                  color: 'inherit',
                }}
                className="no-underline hover:underline"
                href={link}
              >
                {name}
              </a>
            </h3>
            <p className="text-gray-400">{title}</p>
          </div>
          {description ? (
            <div className="text-lg">
              <p className="text-gray-500">{description}</p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Person;

import { bluePalette, goodPalette, badPalette, reversedGoodPalette, reversedBadPalette, extendedGoodPalette, strongSupport, weakSupport, weakOppose, strongOppose } from "../../../../src/utils/pulsePalette.js";
import direction from "../../../../src/images/icons/direction.svg";
import ambulance from "../../../../src/images/icons/ambulance.svg";
import policecar from "../../../../src/images/icons/police-car.svg";
import transit from "../../../../src/images/icons/transit.svg";
import noentry from "../../../../src/images/icons/no-entry.svg";
import { H1 } from "../../../../src/components/layout/headings";
import { SaveAsIcon } from '@heroicons/react/outline';
import { OldLineChart } from "../../../../src/components/pulse/lineChart";
import * as React from 'react';
export default {
  bluePalette,
  goodPalette,
  badPalette,
  reversedGoodPalette,
  reversedBadPalette,
  extendedGoodPalette,
  strongSupport,
  weakSupport,
  weakOppose,
  strongOppose,
  direction,
  ambulance,
  policecar,
  transit,
  noentry,
  H1,
  SaveAsIcon,
  OldLineChart,
  React
};
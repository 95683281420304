import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const NoIcon = ({ alt, ...props }) => {
  const { No } = useStaticQuery(
    graphql`
      query {
        No: file(relativePath: { eq: "icons/icon-no.svg" }) {
          publicURL
        }
      }
    `
  );
  return <img className="inline !my-0" {...props} src={No.publicURL} alt="no" />;
};

export default NoIcon;

import { TLDR } from "../../../../src/components/election/tldr.js";
import { MeasureInfo } from "../../../../src/components/election/measure.js";
import { CandidateInfo, CandidateHeadshots, JudgeInfo } from "../../../../src/components/election/candidate_info.js";
import { VoterGuideFAQ } from "../../../../src/components/election/voter-guide-faq.js";
import BgWhiteWrapper from "../../../../src/components/election/bgWhiteWrapper.js";
import { nov24_contests } from "../../../../src/data/data-nov-2024.js";
import * as React from 'react';
export default {
  TLDR,
  MeasureInfo,
  CandidateInfo,
  CandidateHeadshots,
  JudgeInfo,
  VoterGuideFAQ,
  BgWhiteWrapper,
  nov24_contests,
  React
};
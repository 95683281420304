import { TLDR } from '../../../../src/components/election/tldr';
import { CandidateInfo, CandidateHeadshots } from '../../../../src/components/election/candidate_info';
import BgWhiteWrapper from '../../../../src/components/election/bgWhiteWrapper';
import { mar24_contests } from '../../../../src/data/data-march-2024.js';
import { SuperButton } from '../../../../src/components/layout/buttons';
import * as React from 'react';
export default {
  TLDR,
  CandidateInfo,
  CandidateHeadshots,
  BgWhiteWrapper,
  mar24_contests,
  SuperButton,
  React
};
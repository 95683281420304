import React, { Fragment } from 'react';
import RankIcon from '../../src/components/icons/RankIcon';
import YesIcon from '../../src/components/icons/YesIcon';
import NoIcon from '../../src/components/icons/NoIcon';

const link = (text, url) => <a href={url}>{text}</a>;
const np = <span>No&nbsp;position</span>;

const ranked = (candidate, rank) => (
  <div className="flex gap-1">
    <RankIcon rank={rank} />
    {candidate}
  </div>
);

// taken from https://stackoverflow.com/a/55857792
export const br = (string) => {
  return string.split(' ').map((item, index) => {
    return index === 0 ? item : [<br key={index} />, item];
  });
};

const endorsed = (candidate) => (
  <div className="flex items-center gap-1">
    <YesIcon />
    <span>{candidate}</span>
  </div>
);

const good = (content) => (
  <div className="flex items-center gap-1">
    ✅<span>{content}</span>
  </div>
);

const mid = (content) => (
  <div className="flex items-center gap-1">
    🤷<span>{content}</span>
  </div>
);

const bad = (content) => (
  <div className="flex items-center gap-1">
    ❌<span>{content}</span>
  </div>
);

export const nov24_d1_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: endorsed(br('Marjan Philhour')),
    accessor: 'marjan',
  },
  {
    Header: 'Connie Chan (Incumbent)',
    accessor: 'connie',
  },
  {
    Header: br('Jen Nossokoff'),
    accessor: 'jen',
  } /*
  {
    Header: br('Jeremiah Boehner'),
    accessor: 'jeremiah',
  },*/,
];

export const nov24_positions_d1 = [
  {
    issue: 'Mandated treatment for fentanyl addicts',
    marjan: good(
      link('Yes', 'https://drive.google.com/file/d/1Sta2f9RNzfSQK9YKaahn9trJsBNxw6p3/view')
    ),
    jen: bad(link('No', 'https://richmondsunsetnews.com/2024/02/07/commentary-jen-nossokoff-7/')),
    jeremiah: good(
      link('Yes', 'https://drive.google.com/file/d/1rom9gHJ-R1NBLBOd_yt6TXF62yaoBEf4/view')
    ),
    connie: bad(np),
  },
  {
    issue: 'Arrest fentanyl dealers',
    marjan: good(
      link(
        'Yes',
        'https://www.votemarjan.com/why-marjan-is-running#:~:text=drug%20markets%20by-,arresting%20dealers,-and%20public%20users'
      )
    ),
    jen: mid(
      link('Diversion', 'https://drive.google.com/file/d/1GAux9-4bU7L2knVeKtXUJVbf6ffGoIYH/view')
    ),
    jeremiah: good(
      link('Yes', 'https://drive.google.com/file/d/1rom9gHJ-R1NBLBOd_yt6TXF62yaoBEf4/view')
    ),
    connie: bad(np),
  },
  {
    issue: 'Enforce anti-tent laws',
    marjan: good(
      link(
        'Yes',
        'https://www.votemarjan.com/why-marjan-is-running#:~:text=Remove%20tents%20and%20dangerous%20tent%20encampments'
      )
    ),
    jen: bad(np),
    jeremiah: bad(np),
    connie: bad(np),
  },
  {
    issue: 'Fully staff police',
    marjan: good(
      link(
        'Fully fund & staff',
        'https://www.votemarjan.com/why-marjan-is-running#:~:text=Fully%20fund%20and%20staff'
      )
    ),
    jen: mid(
      link('Mixed', 'https://drive.google.com/file/d/1GAux9-4bU7L2knVeKtXUJVbf6ffGoIYH/view')
    ),
    jeremiah: good(
      link('Yes', 'https://drive.google.com/file/d/1rom9gHJ-R1NBLBOd_yt6TXF62yaoBEf4/view')
    ),
    connie: bad(
      link(
        'Defund the police',
        'https://sfstandard.com/2024/04/01/san-francisco-supervisor-richmond-district-chan-philhour-crime/#:~:text=Chan%20said%2C%20%E2%80%9CDefinitely%2C%20it%20is%20time%20%5Bto%5D%20dismantle%20and%20restructure%20our%20police%20department.%E2%80%9D'
      )
    ),
  },
];

export const nov24_d3_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: endorsed(br('Danny Sauter')),
    accessor: 'danny',
  },
  {
    Header: br('Moe Jamil'),
    accessor: 'moe',
  },
  {
    Header: br('Sharon Lai'),
    accessor: 'sharon',
  } /*
  {
    Header: br('Matthew Susk'),
    accessor: 'matt',
  },*/,
];

export const nov24_positions_d3 = [
  {
    issue: 'Mandated treatment for fentanyl addicts',
    danny: good(
      link('Yes', 'https://drive.google.com/file/d/1UMoyi4Bud7B6elaLsYDr4HC2lrUfIALV/view')
    ),
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1Tk5H3vGG6sds5HE_UNHkzUlD88L8wWsn/view')
    ),
    moe: bad(np),
    sharon: bad(np),
  },
  {
    issue: 'Fully staff police',
    danny: good(
      link('Yes', 'https://drive.google.com/file/d/1UMoyi4Bud7B6elaLsYDr4HC2lrUfIALV/view')
    ),
    matt: good(link('Yes', 'https://www.votesusk.com/issues/safe-streets')),
    moe: good(
      link('Yes', 'https://drive.google.com/file/d/1kFpjMaIrqEeiMYqTzdTk1jjFccBqXWX1/view')
    ),
    sharon: good(
      link('Yes', 'https://drive.google.com/file/d/1Hzzho-0XCFEFmAjz981IamuOP3PQUSG3/view')
    ),
  },
  {
    issue: 'Build homes for all incomes',
    danny: good(
      link('Yes', 'https://drive.google.com/file/d/1UMoyi4Bud7B6elaLsYDr4HC2lrUfIALV/view')
    ),
    matt: mid(
      link(
        'Along transit corridors',
        'https://drive.google.com/file/d/1Tk5H3vGG6sds5HE_UNHkzUlD88L8wWsn/view'
      )
    ),
    moe: bad(
      link(
        'No, just fill vacancies',
        'https://drive.google.com/file/d/1kFpjMaIrqEeiMYqTzdTk1jjFccBqXWX1/view'
      )
    ),
    sharon: mid(
      link(
        'Focus on low-income',
        'https://www.sharonlaisf.com/issues#:~:text=2.-,HOUSING,-As%20someone%20who'
      )
    ),
  },
  {
    issue: 'Clean streets via enforcing anti-tent laws',
    danny: good(
      link(
        'Yes',
        'https://missionlocal.org/2024/08/district-3-candidates-on-last-weeks-homeless-sweeps/'
      )
    ),
    matt: good(
      link(
        'Yes',
        'https://missionlocal.org/2024/08/district-3-candidates-on-last-weeks-homeless-sweeps/'
      )
    ),
    moe: good(
      link(
        'Yes',
        'https://missionlocal.org/2024/08/district-3-candidates-on-last-weeks-homeless-sweeps/'
      )
    ),
    sharon: mid(
      link(
        'Unclear',
        'https://missionlocal.org/2024/08/district-3-candidates-on-last-weeks-homeless-sweeps/'
      )
    ),
  },
  {
    issue: 'Build citywide bike network',
    danny: good(
      link('Yes', 'https://drive.google.com/file/d/1UMoyi4Bud7B6elaLsYDr4HC2lrUfIALV/view')
    ),
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1Tk5H3vGG6sds5HE_UNHkzUlD88L8wWsn/view')
    ),
    moe: mid(
      link(
        'Need community input',
        'https://drive.google.com/file/d/1kFpjMaIrqEeiMYqTzdTk1jjFccBqXWX1/view'
      )
    ),
    sharon: good(
      link('Yes', 'https://drive.google.com/file/d/1Hzzho-0XCFEFmAjz981IamuOP3PQUSG3/view')
    ),
  },
];

export const nov24_d5_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: endorsed(br('Bilal Mahmood')),
    accessor: 'bilal',
  },
  {
    Header: 'Dean Preston (Incumbent)',
    accessor: 'dean',
  } /*
  {
    Header: br('Scotty Jacobs'),
    accessor: 'scotty',
  },
  {
    Header: br('Autumn Looijen'),
    accessor: 'autumn',
  },
  {
    Header: br('Allen Jones'),
    accessor: 'allen',
  },*/,
];

export const nov24_positions_d5 = [
  {
    issue: 'Build homes for all incomes',
    bilal: good(
      link('Yes', 'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view')
    ),
    autumn: good(
      link('Yes', 'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view')
    ),
    scotty: good(
      link('Yes', 'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view')
    ),
    dean: bad(link('No', 'https://nimby.report/preston')),
    allen: bad(
      link('No', 'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view')
    ),
  },
  {
    issue: 'Fully staff police',
    bilal: good(
      link('Yes', 'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view')
    ),
    autumn: good(
      link('Yes', 'https://drive.google.com/file/d/13Mbhn4vpsgIAfp0vCOAS5NQAdOMULfgk/view')
    ),
    scotty: good(
      link(
        'Yes, and more',
        'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view'
      )
    ),
    dean: bad(link('Defund the police', 'https://growsf.org/people/dean-preston/#public-safety')),
    allen: mid(
      link('Unclear', 'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view')
    ),
  },
  /*
  {
    issue: 'Mandated treatment for fentanyl addicts',
    bilal: bad(
      link(
        'No',
        'https://missionlocal.org/2024/06/d5-sf-supervisor-candidates-set-themselves-apart/'
      )
    ),
    autumn: good(
      link('Yes', 'https://drive.google.com/file/d/13Mbhn4vpsgIAfp0vCOAS5NQAdOMULfgk/view')
    ),
    scotty: good(
      link('Yes', 'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view')
    ),
    dean: bad(
      link(
        'No',
        'https://www.sfchronicle.com/sf/article/sf-drug-crisis-breed-preston-criticism-18150703.php'
      )
    ),
    allen: bad(
      link('No', 'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view')
    ),
  },*/
  {
    issue: 'Arrest fentanyl dealers',
    bilal: good(
      link('Yes', 'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view')
    ),
    autumn: good(
      link('Yes', 'https://drive.google.com/file/d/13Mbhn4vpsgIAfp0vCOAS5NQAdOMULfgk/view')
    ),
    scotty: good(
      link('Yes', 'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view')
    ),
    dean: bad(link('No', 'https://growsf.org/dumpdean/#reason-14')),
    allen: good(
      link('Yes', 'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view')
    ),
  },
  {
    issue: 'Plan for meeting state housing goals?',
    bilal: good(
      link(
        'Speed up permitting, reform DBI, fewer discretionary permits, reduce bureaucracy',
        'https://drive.google.com/file/d/1LpdT4oZJys1rExDyoFogm9ufJUx5aTCg/view'
      )
    ),
    autumn: bad(
      link('No', 'https://drive.google.com/file/d/13Mbhn4vpsgIAfp0vCOAS5NQAdOMULfgk/view')
    ),
    scotty: bad(
      link('No', 'https://drive.google.com/file/d/1PiBzzhCa_FH0ta6sxUWoVKSQSbXnvCWV/view')
    ),
    dean: bad('No'),
    allen: mid(
      link('Reduce fees', 'https://drive.google.com/file/d/1fWLqpKwX7y9Ajyv02JgesjZ1h5v9SAQp/view')
    ),
  },
];

export const nov24_d7_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: endorsed(br('Matthew Boschetto')),
    accessor: 'matt',
  },
  {
    Header: 'Myrna Melgar (Incumbent)',
    accessor: 'myrna',
  } /*
  {
    Header: br('Stephen Martin-Pinto'),
    accessor: 'stephen',
  },
  {
    Header: br('Edward Yee'),
    accessor: 'ed',
  },*/,
];

export const nov24_positions_d7 = [
  {
    issue: 'Fully staff police',
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view')
    ),
    myrna: good(
      link('Yes', 'https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view')
    ),
    stephen: good(
      link('Yes', 'https://drive.google.com/file/d/1CkVZ6-xHH9pk0vV2mbds9OjQt9mFYQU6/view')
    ),
    ed: bad(np),
  },
  {
    issue: 'Mandated treatment for fentanyl addicts',
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view')
    ),
    myrna: bad(
      link(
        'No',
        'https://missionlocal.org/2024/02/meet-the-candidates-all-2024-district-7-supervisor-answers/#:~:text=Prop.%20F%3A%20No.%20Drug%20screening%20for%20public%20assistance%20recipients.'
      )
    ),
    stephen: good(
      link(
        'Yes',
        'https://missionlocal.org/2024/02/meet-the-candidates-all-2024-district-7-supervisor-answers/#:~:text=Prop.%20F%3A%C2%A0Yes.%C2%A0It%20is%20not%20unreasonable%20to%20ask%20that%20recipients%20of%20benefits%20be%20sober%20or%20try%20to%20become%20sober.'
      )
    ),
    ed: bad(np),
  },
  {
    issue: 'Arrest fentanyl dealers',
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view')
    ),
    myrna: good(
      link('Yes', 'https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view')
    ),
    stephen: good(
      link('Yes', 'https://drive.google.com/file/d/1CkVZ6-xHH9pk0vV2mbds9OjQt9mFYQU6/view')
    ),
    ed: bad(np),
  },
  {
    issue: 'Build homes for all incomes',
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view')
    ),
    myrna: mid(link('Sometimes', 'https://growsf.org/people/myrna-melgar/#housing')),
    stephen: mid(
      link(
        'In some places',
        'https://drive.google.com/file/d/1CkVZ6-xHH9pk0vV2mbds9OjQt9mFYQU6/view'
      )
    ),
    ed: bad(np),
  },
  {
    issue: 'Discretionary Review reform',
    matt: good(
      link('Yes', 'https://drive.google.com/file/d/1aSrYI4cD3_LdAIxMsiL022QO0N2tNpg3/view')
    ),
    myrna: good(
      link('Yes', 'https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view')
    ),
    stephen: bad(
      link('No', 'https://drive.google.com/file/d/1CkVZ6-xHH9pk0vV2mbds9OjQt9mFYQU6/view')
    ),
    ed: bad(np),
  },
  {
    issue: 'Clean streets via enforcing anti-tent laws',
    matt: good(link('Yes', 'https://www.matt4supervisor.com/priorities')),
    myrna: bad(np),
    stephen: good(link('Yes', 'https://www.stephenmartinpinto.com/my-platform')),
    ed: bad(np),
  },
];

export const d7_myrna_questionnaires_header = [
  {
    Header: 'Question',
    accessor: 'question',
  },
  {
    Header: (
      <a href="https://drive.google.com/file/d/1GLBGXZ9NTXB2xeLXVUTkyuDvJYfnNYfL/view?usp=drive_link">
        Myrna Melgar's
        <br />
        Original Answers
      </a>
    ),
    accessor: 'orig',
  },
  {
    Header: (
      <a href="https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view?usp=drive_link">
        Myrna Melgar's
        <br />
        New Answers
      </a>
    ),
    accessor: 'new',
  },
];

export const d7_myrna_questionnaires_answers = [
  {
    question: (
      <b>Do you support policies commonly referred to as “defund the police”? Why or why not?'</b>
    ),
    orig: 'Yes, I think our Police force has been responsible for responding to too many societal ills that are not necessarily law enforcement appropriate, such as mental illness or homelessness. I think shifting these activities to other departments will free up police to deal with crime. I also admire the model of the Department of Public Safety in Sunnyvale, which is an integrated Police and Fire Department with trained EMTs',
    new: 'I think our police force is responsible for addressing too many social issues that are not necessarily appropriate for law enforcement, such as responding to crises for those dealing with mental illness or homelessness. Shifting these activities to other departments will allow police to deal with crime.',
  },
  {
    question: (
      <b>
        Please explain why you <u>did</u> or <u>did not</u> support the recall of DA Chesa Boudin.
        If you were ineligible to vote in that election, please explain how you would have voted.
      </b>
    ),
    orig: 'I opposed ALL the recalls. I think that when we have difficult times, recalls can be destabilizing and expensive.',
    new: 'I did not take a position on the recall of the DA. I have concerns about recalls as a tool and wanted to leave it up to the voters to decide.',
  },
  {
    question: (
      <b>
        Did you support the recall of Board of Education members Collins, López, and Moliga? Please
        explain why you <u>did</u> or <u>did not</u> support the recall of each member.
      </b>
    ),
    orig: 'I opposed ALL the recalls. I think that when we have difficult times, recalls can be destabilizing and expensive. The school board members would have served only 6 additional months before their reelection.',
    new: 'I did not take a position on the recall of the School Board members, again due to my concerns over the use of recalls and my desire to allow the voters to express their opinions.',
  },
  {
    question: (
      <b>
        Should it be the policy of San Francisco to build a citywide protected bike lane network?
        Why or why not?
      </b>
    ),
    orig: 'YES!!!',
    new: 'YES!!! We should do everything we can to foster a city that allows for accessible, green transportation alternatives, and a protected bike lane network would do wonders to help foster more residents’ use of emissions-free transit options.',
  },
  {
    question: <b>What do you dislike the most about San Francisco?</b>,
    orig: 'The weird quirk in our culture that even though we see ourselves as so liberal, we are actually quite conservative.',
    new: 'The political infighting and finger pointing that has stalled our ability to make progress on the issues plaguing our community.',
  },
  {
    question: 'Link to questionnaire',
    orig: link(
      'Original answers',
      'https://drive.google.com/file/d/1GLBGXZ9NTXB2xeLXVUTkyuDvJYfnNYfL/view?usp=drive_link'
    ),
    new: link(
      'New answers',
      'https://drive.google.com/file/d/184bsB3KxQdCFUiuo6oSxczn375N057kp/view?usp=drive_link'
    ),
  },
];

export const nov24_d9_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: endorsed(br('Trevor Chandler')),
    accessor: 'trevor',
  },
  {
    Header: br('Roberto Hernandez'),
    accessor: 'roberto',
  },
  {
    Header: br('Jackie Fielder'),
    accessor: 'jackie',
  } /*
  {
    Header: br('Jaime Gutierrez'),
    accessor: 'jaime',
  },
  {
    Header: br('Julian Bermudez'),
    accessor: 'julian',
  },
  {
    Header: br('H. Brown'),
    accessor: 'brown',
  },*/,
];

export const nov24_positions_d9 = [
  {
    issue: 'Mandated treatment for fentanyl addicts',
    trevor: good(
      link('Yes', 'https://drive.google.com/file/d/12N-cKDUQtqz8jFSgjG8w_OrjXus1Txau/view')
    ),
    roberto: bad(
      link(
        'No',
        'https://missionlocal.org/2024/04/hernandez-mannys-and-d9-candidate/#:~:text=steadfast%20opponent%20of%20the%20recently%20approved%20Proposition%20F%2C%20which%20requires%20cash%2Dassistance%20recipients%20to%20undergo%20screening%20for%20illegal%20substance%20dependency%20and%20participate%20in%20treatment'
      )
    ),
    jackie: bad(link('No', 'https://www.jackieforsf.com/overdosementalhealth')),
    jaime: good(
      link('Yes', 'https://drive.google.com/file/d/1KQDyh1oIolzh78S56pWK5Sct_QvGDMex/view')
    ),
    julian: bad(
      link('No', 'https://drive.google.com/file/d/1oAV4KYR7GVU4pFmzh_R_BW1uQfCWoclO/view')
    ),
    brown: link(
      bad(
        'No, legalize all drugs',
        'https://drive.google.com/file/d/1anDrTyzP13mA36aZiQ01G98KwQDRfMHy/view'
      )
    ),
  },
  {
    issue: 'Build homes for all incomes',
    trevor: good(
      link('Yes', 'https://drive.google.com/file/d/12N-cKDUQtqz8jFSgjG8w_OrjXus1Txau/view')
    ),
    roberto: mid(
      link(
        'Mostly low-income, some mixed-income',
        'https://missionlocal.org/2024/04/what-the-d9-candidates-have-to-say-about-housing/#:~:text=has%20not%20left.-,Roberto%20Hernandez,-CEO%2C%20Cultura%20y'
      )
    ),
    jackie: mid(
      link(
        'Mostly low-income, some mixed-income',
        'https://missionlocal.org/2024/04/what-the-d9-candidates-have-to-say-about-housing/#:~:text=2021%20to%20present.-,Jackie%20Fielder,-Former%20educator%20at'
      )
    ),
    jaime: bad(
      link(
        'Only low-income',
        'https://missionlocal.org/2024/04/what-the-d9-candidates-have-to-say-about-housing/#:~:text=since%20Oct.%201991.-,Jaime%20Gutierrez,-Transit%20supervisor%20for'
      )
    ),
    julian: mid(
      link('Unclear', 'https://drive.google.com/file/d/1oAV4KYR7GVU4pFmzh_R_BW1uQfCWoclO/view')
    ),
    brown: bad(np),
  },
  {
    issue: 'Arrest fentanyl dealers',
    trevor: good(
      link('Yes', 'https://drive.google.com/file/d/12N-cKDUQtqz8jFSgjG8w_OrjXus1Txau/view')
    ),
    roberto: bad(np),
    jackie: bad(np),
    jaime: good(
      link('Yes', 'https://drive.google.com/file/d/1KQDyh1oIolzh78S56pWK5Sct_QvGDMex/view')
    ),
    julian: link('No', 'https://drive.google.com/file/d/1oAV4KYR7GVU4pFmzh_R_BW1uQfCWoclO/view'),
    brown: bad(np),
  },
  {
    issue: 'Community policing approach',
    trevor: good(
      link(
        'Yes',
        'https://www.trevor4sf.com/_files/ugd/2e229e_aa8f0f64547b468684f98aaf18a9ab48.pdf'
      )
    ),
    roberto: good(
      link(
        'Yes',
        'https://www.roberto4sf.com/issues#:~:text=community%2Dbased%20public%20safety%20plan'
      )
    ),
    jackie: bad(link('No, only non-police options', 'https://www.jackieforsf.com/publicsafety')),
    jaime: mid(np),
    julian: mid(np),
    brown: mid(np),
  },
  {
    issue: 'Fully staff police',
    trevor: good(
      link('Yes', 'https://drive.google.com/file/d/12N-cKDUQtqz8jFSgjG8w_OrjXus1Txau/view')
    ),
    roberto: bad(np),
    jackie: bad(
      link(
        'Defund the police',
        'https://www.teenvogue.com/story/police-donations-lawmakers#:~:text=We%20need%20to%20defund%20the%20police'
      )
    ),
    jaime: good(
      link('Yes', 'https://drive.google.com/file/d/1KQDyh1oIolzh78S56pWK5Sct_QvGDMex/view')
    ),
    julian: bad(
      link('No', 'https://drive.google.com/file/d/1oAV4KYR7GVU4pFmzh_R_BW1uQfCWoclO/view')
    ),
    brown: mid(
      link('Unclear', 'https://drive.google.com/file/d/1anDrTyzP13mA36aZiQ01G98KwQDRfMHy/view')
    ),
  },
  {
    issue: 'Clean streets via enforcing anti-tent laws',
    trevor: good(
      link(
        'Yes',
        'https://x.com/trevor4sf/status/1693743952464416855?s=46&t=Hf67Kt5LK_FX6K2CLD3dGA'
      )
    ),
    roberto: bad(
      link(
        'No',
        'https://missionlocal.org/2024/07/meet-the-district-9-candidates-do-you-support-tent-sweeps-throughout-the-city/#:~:text=Rather%20than%20clearing%20encampments'
      )
    ),
    jackie: bad(
      link(
        'No',
        'https://missionlocal.org/2024/07/meet-the-district-9-candidates-do-you-support-tent-sweeps-throughout-the-city/#:~:text=read%20more%20here-,Jackie%20Fielder,-Job%3A%20Nonprofit'
      )
    ),
    jaime: good(
      link(
        'Yes',
        'https://missionlocal.org/2024/07/meet-the-district-9-candidates-do-you-support-tent-sweeps-throughout-the-city/#:~:text=read%20more%20here-,Jaime%20Gutierrez,-Job%3A%20Transit'
      )
    ),
    julian: bad(
      link(
        'No',
        'https://missionlocal.org/2024/07/meet-the-district-9-candidates-do-you-support-tent-sweeps-throughout-the-city/'
      )
    ),
    brown: bad(
      link(
        np,
        'https://missionlocal.org/2024/07/meet-the-district-9-candidates-do-you-support-tent-sweeps-throughout-the-city/'
      )
    ),
  },
];

export const nov24_d11_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: endorsed(br('Michael Lai')),
    accessor: 'michael',
  },
  {
    Header: br('Chyanne Chen'),
    accessor: 'chen',
  },
  {
    Header: br('Ernest Jones'),
    accessor: 'ej',
  },
  {
    Header: br('Adlah Chisti'),
    accessor: 'adlah',
  } /*
  {
    Header: br('Jose Morales'),
    accessor: 'jose',
  },*/,
];

export const nov24_positions_d11 = [
  {
    issue: 'Build homes for all incomes',
    michael: good(
      link('Yes', 'https://drive.google.com/file/d/1uVkKjcI8Rxe06Bnnc-rVV2Ahr6w5WxnV/view')
    ),
    ej: mid(link('Primarily low-income', 'https://www.ejforsf.com/issues')),
    chen: bad(
      link(
        'Subsidized only',
        'https://www.chyannechen.com/issues#:~:text=housing%20for%20low%20to%20moderate%20income%20families'
      )
    ),
    adlah: good(
      link('Yes', 'https://drive.google.com/file/d/1Ps_fgQttujs1Rqyc2NJasNMsU-xalzA5/view')
    ),
    jose: bad(
      link(
        'Low-income only',
        'https://drive.google.com/file/d/1h7obFQKulp_wO9DOCLhmYC4P-A87qDe0/view'
      )
    ),
  },
  {
    issue: 'Fully staff police',
    michael: good(
      link('Yes', 'https://drive.google.com/file/d/1uVkKjcI8Rxe06Bnnc-rVV2Ahr6w5WxnV/view')
    ),
    ej: good(link('Yes', 'https://drive.google.com/file/d/1vUUy8Rf804k6Aoh44U5Fc8MvpVASNDgv/view')),
    chen: bad(np),
    adlah: bad(
      link('No', 'https://drive.google.com/file/d/1Ps_fgQttujs1Rqyc2NJasNMsU-xalzA5/view')
    ),
    jose: bad(link('No', 'https://drive.google.com/file/d/1h7obFQKulp_wO9DOCLhmYC4P-A87qDe0/view')),
  } /*
  {
    issue: 'Mandated treatment for fentanyl addicts',
    michael: bad(np),
    ej: bad(
      link(
        'No (Opposed Prop F)',
        'https://missionloca.s3.amazonaws.com/mission/wp-content/uploads/2024/03/Ernest-_E.J._-Jones-week-4.pdf'
      )
    ),
    chen: bad(np),
    adlah: bad(np),
    jose: mid(
      link('Unclear', 'https://drive.google.com/file/d/1h7obFQKulp_wO9DOCLhmYC4P-A87qDe0/view')
    ),
  },*/,
  {
    issue: 'Arrest fentanyl dealers',
    michael: good(
      link('Yes', 'https://drive.google.com/file/d/1uVkKjcI8Rxe06Bnnc-rVV2Ahr6w5WxnV/view')
    ),
    ej: good(link('Yes', 'https://drive.google.com/file/d/1vUUy8Rf804k6Aoh44U5Fc8MvpVASNDgv/view')),
    chen: bad(np),
    adlah: bad(np),
    jose: good(
      link('Yes', 'https://drive.google.com/file/d/1h7obFQKulp_wO9DOCLhmYC4P-A87qDe0/view')
    ),
  },
];

export const nov24_mayor_header = [
  {
    Header: 'Issue',
    accessor: 'issue',
  },
  {
    Header: 'London Breed',
    accessor: 'breed',
  },
  {
    Header: 'Mark Farrell',
    accessor: 'farrell',
  },
  {
    Header: 'Daniel Lurie',
    accessor: 'lurie',
  },
  {
    Header: 'Aaron Peskin',
    accessor: 'peskin',
  },
  {
    Header: 'Ahsha Safai',
    accessor: 'safai',
  },
];

export const nov24_mayor_tldr = [
  {
    issue: 'Building more homes',
    breed: link(
      'Build more everywhere',
      'https://www.londonformayor.com/issues#:~:text=Housing,-The%20City%E2%80%99s%20inability'
    ),
    farrell: link(
      'Mostly downtown',
      'https://www.markfarrell.com/housing/#:~:text=Housing%20Supply%20Through-,Targeted%20Upzoning,-Across%20San%20Francisco'
    ),
    lurie: link(
      'Near transit & major streets',
      'https://daniellurie.com/priorities/housing/#:~:text=main%20corridors%2C%20near%20transit%2C%20and%20corner%20lots'
    ),
    peskin: link('Only taxpayer subsidized', 'https://www.aaron2024.com/'),
    safai: np,
  },
  {
    issue: 'Fully staff police',
    breed: link(
      'Yes',
      'https://www.londonformayor.com/issues#:~:text=Record%2C%20Results%20%26%C2%A0Vision-,Public%20Safety,-San%20Francisco%20can'
    ),
    farrell: link('Yes', 'https://www.markfarrell.com/public-safety/'),
    lurie: link('Yes', 'https://daniellurie.com/priorities/public-safety/'),
    peskin: np,
    safai: link(
      'Yes',
      'https://www.ahshaformayor.com/priorities/improve-public-safety-in-every-neighborhood#:~:text=Ensure%20we%20fully%20fund%20public%20safety'
    ),
  },
  {
    issue: 'Mandated treatment for fentanyl addicts',
    breed: link(
      'Yes',
      'https://www.londonformayor.com/issues#:~:text=Drugs%20%26%20Street%20Conditions'
    ),
    farrell: link(
      'Yes',
      'https://www.markfarrell.com/fentanyl-overdose-crisis/#:~:text=Mandate%20treatment%2Dfocused%20detention%20and%20a%20connection%20to%20services'
    ),
    lurie: link(
      'Yes',
      'https://daniellurie.com/priorities/mental-health-drug-crisis/#:~:text=Mandate%20treatment%20on%20demand%20or%20face%20arrest'
    ),
    peskin: np,
    safai: link(
      'No',
      'https://www.ahshaformayor.com/priorities/address-our-mental-health-and-addiction-crises'
    ),
  },
  {
    issue: 'Arrest opioid dealers',
    breed: link(
      'Yes',
      'https://www.londonformayor.com/issues#:~:text=Drugs%20%26%20Street%20Conditions'
    ),
    farrell: link(
      'Yes',
      'https://www.markfarrell.com/fentanyl-overdose-crisis/#:~:text=Mandate%20treatment%2Dfocused%20detention%20and%20a%20connection%20to%20services'
    ),
    lurie: link(
      'Yes',
      'https://daniellurie.com/priorities/mental-health-drug-crisis/#:~:text=Mandate%20treatment%20on%20demand%20or%20face%20arrest'
    ),
    peskin: np,
    safai: link(
      'No',
      'https://www.ahshaformayor.com/priorities/address-our-mental-health-and-addiction-crises'
    ),
  },
  {
    issue: 'Build citywide bike network',
    breed: link(
      'Yes',
      'https://drive.google.com/file/d/19TWzQf4Qccu3Kk4NyUOZHNabNTTkuAxe/view?usp=drive_link'
    ),
    farrell: link(
      'Yes',
      'https://drive.google.com/file/d/1DFLiVs5fiSAAL_cBTfCrCjW-QMDPFSIC/view?usp=drive_link'
    ),
    lurie: link(
      'Yes',
      'https://drive.google.com/file/d/1apBrHGloS9S_qOL6Lr7GGoGAzJv7DTGe/view?usp=drive_link'
    ),
    peskin: np,
    safai: np,
  },
  {
    issue: 'Build more homeless shelters',
    breed: link(
      'Yes',
      'https://www.londonformayor.com/issues#:~:text=than%20London%20Breed.-,Homelessness,-Homelessness%20can%20be'
    ),
    farrell: link(
      'Yes',
      'https://drive.google.com/file/d/1DFLiVs5fiSAAL_cBTfCrCjW-QMDPFSIC/view?usp=drive_link'
    ),
    lurie: link(
      'Yes (2,500)',
      'https://daniellurie.com/priorities/shelter/#:~:text=Build%201%2C500%20shelter%20beds%20in%206%20months'
    ),
    peskin: link('Yes (2,000)', ''),
    safai: link('Yes (2,600)', 'https://www.ahshaformayor.com/endstreethomelessness'),
  },
  {
    issue: 'Enforce anti-tent laws',
    breed: link(
      'Yes',
      'https://www.londonformayor.com/issues#:~:text=than%20London%20Breed.-,Homelessness,-Homelessness%20can%20be'
    ),
    farrell: link(
      'Yes',
      'https://www.markfarrell.com/street-conditions/#:~:text=Clear%20all%20large%20tent%20encampments'
    ),
    lurie: link(
      'Yes',
      'https://daniellurie.com/priorities/shelter/#:~:text=in%20order%20to-,clear%20encampments,-and%20create%20path'
    ),
    peskin: np,
    safai: link(
      'Yes',
      'https://www.ahshaformayor.com/endstreethomelessness#:~:text=Enforce%20%E2%80%9CNo%20Camping%E2%80%9D%20Orders%20with%20Neighborhood%20Support'
    ),
  },
  {
    issue: 'City Hall experience',
    breed: '11 years',
    farrell: '7 years',
    lurie: 'None',
    peskin: '17 years',
    safai: '7 years',
  },
];

export const prop_c_2024_header = [
  {
    Header: 'Ethics Commission',
    accessor: 'ethics',
  },
  {
    Header: 'District Attorney',
    accessor: 'districtAttorney',
  },
  {
    Header: 'City Attorney',
    accessor: 'cityAttorney',
  },
  {
    Header: 'City Services Auditor',
    accessor: 'cityServicesAuditor',
  },
  {
    Header: '(Proposed) Inspector General',
    accessor: 'inspectorGeneral',
  },
];

export const prop_c_2024_data = [
  {
    ethics: 'Created by Prop K in 1993',
    districtAttorney: 'Established in the City Charter',
    cityAttorney: 'Established in the City Charter',
    cityServicesAuditor: 'Created by Prop F in 2003',
    inspectorGeneral: 'On the ballot as Prop C in 2024',
  },
  {
    ethics: 'Oversee campaign finance, governmental ethics, and conflicts of interests.',
    districtAttorney: 'Investigate, prosecute, and litigate criminal cases.',
    cityAttorney: 'Represent the city in legal proceedings.',
    cityServicesAuditor:
      'Performance audits, fraud, waste, and abuse, whistleblower program, actionable advice.',
    inspectorGeneral: 'Investigate fraud, waste, or abuse.',
  },
  {
    ethics: (
      <Fragment>
        <a href="https://careers.sf.gov/role/?id=743999821629427#:~:text=Through%20its%2033%2Dmember%20staff%2C%20the%20Ethics%20Commission">
          33 people
        </a>{' '}
        across Administration, Compliance, Policy, Data Analysis, Audits, and Enforcement.
      </Fragment>
    ),
    districtAttorney: (
      <Fragment>
        <a href="https://www.linkedin.com/company/san-francisco-district-attorney-s-office">
          Approximately 250-500
        </a>{' '}
        lawyers, and staff, a subset of which would address cases of fraud, waste, or abuse.
      </Fragment>
    ),
    cityAttorney: (
      <Fragment>
        <a href="https://careers.sf.gov/role/?id=3743990004802146#:~:text=About%20the%20Office&text=With%20over%20300%20talented%2C%20dedicated,Supervisors%2C%20officials%2C%20and%20departments.">
          Over 300 employees
        </a>{' '}
        work for the City Attorney's office, across multiple teams.
      </Fragment>
    ),
    cityServicesAuditor: (
      <Fragment>
        <a href="https://www.sf.gov/sites/default/files/2022-12/CSA%20Workplan%20FY22-23%20final.pdf">
          76 full time positions
        </a>
        , across auditors, analysts, managers, and operations staff.
      </Fragment>
    ),
    inspectorGeneral:
      "1 Inspector General, with support staff at the discretion of the Controller's Office.",
  },
  {
    ethics: 'Issues fines, and refers to the City or District Attorney.',
    districtAttorney: 'Pursues criminal legal cases.',
    cityAttorney: 'Pursues civil legal cases.',
    cityServicesAuditor: 'Refers to the City or District Attorney.',
    inspectorGeneral: 'Can refer to another department.',
  },
];

/* Here's a table for Prop M:

| Tax or Tax Classification | The Way it is Now | What Changes if Prop M passes | Why this Change |
| --- |  --- |  --- |  --- |
| Gross Receipts Tax | 0.053% to 1.008% based on sales in SF, payroll expenses, or both. | 0.1% to 3.716% based more on sales, less on payroll. | Given the rise in remote work, focusing on sales more than payroll is better for the city's fiscal stability. |
| Number of business types for gross receipts and homeless gross receipts | 14 | 7 | Consolidates the number of business types for greater simplicity. |
| Businesses Exempt from Gross Receipts Tax | Up to $2.19M | Up to $5M | Reduces the tax burden on small businesses. |
| Homelessness Gross Receipts Tax | 0.175% to 0.69% for SF businesses with gross receipts of $50M+ | 0.162% to 1.64% for SF businesses with gross receipts of $25M+ | Ensures stable funding for SF's homeless initiatives. |
| Overpaid Executive Gross Receipts Tax | 0.1% to 0.6% | 0.02% to 0.129% | TKTK |
| Business Registration Fees | $47 to $45,150 based on business type and gross receipts. | $55 to $60,000 | Increases business registration fees to balance tax decreases elsewhere. |
| Administrative Office Tax  | 3.04% to 5.44% for large businesses (instead of these other taxes) | 2.97% to 3.694% | Reduces the administrative office tax.  |
| Large Business Registration Fees (Taxed Annually) | $19,682 to $45,928 | $500 to $35,000 | Changes how annual registration fees are applied to be more equitable. |
| Tax Credits | Tax credit for opening a physical location in designated areas of the city,  | Creates new tax credits for businesses paying stadium operator taxes, grocery retailers, and new lessees in newly constructed buildings | Incentivizes occupying new construction, promotes stadium use and grocery retail.  |
*/

export const prop_m_2024_header = [
  {
    Header: 'Tax or Tax Classification',
    accessor: 'tax',
  },
  {
    Header: 'The Way it is Now',
    accessor: 'current',
  },
  {
    Header: 'What Changes if Prop M passes',
    accessor: 'changes',
  },
  {
    Header: 'Why this Change',
    accessor: 'why',
  },
];

export const prop_m_2024_data = [
  {
    tax: 'Gross Receipts Tax',
    current: '0.053% to 1.008% based on sales in SF, payroll expenses, or both.',
    changes: '0.1% to 3.716% based more on sales, less on payroll.',
    why: 'Given the rise in remote work, focusing on sales more than payroll is better for the city’s fiscal stability.',
  },
  {
    tax: 'Number of business types for gross receipts and homeless gross receipts',
    current: '14',
    changes: '7',
    why: 'Consolidates the number of business types for greater simplicity.',
  },
  {
    tax: 'Businesses Exempt from Gross Receipts Tax',
    current: 'Up to $2.19M',
    changes: 'Up to $5M',
    why: 'Reduces the tax burden on small businesses.',
  },
  {
    tax: 'Homelessness Gross Receipts Tax',
    current: '0.175% to 0.69% for SF businesses with gross receipts of $50M+',
    changes: '0.162% to 1.64% for SF businesses with gross receipts of $25M+',
    why: 'Ensures most stable funding for SF’s homeless initiatives by broadening the base of payers.',
  },
  {
    tax: 'Overpaid Executive Gross Receipts Tax',
    current: '0.1% to 0.6%',
    changes: '0.02% to 0.129%',
    why: 'Stabilizes funding for city services by incorporating the majority of these tax collections in the broader business tax base.',
  },
  {
    tax: 'Business Registration Fees',
    current: '$47 to $45,150 based on business type and gross receipts.',
    changes: '$55 to $60,000',
    why: 'Increases business registration fees to partially offset tax decreases elsewhere.',
  },
  {
    tax: 'Tax Credits',
    current: 'Tax credit for opening a physical location in designated areas of the city.',
    changes:
      'Creates new tax credits for businesses paying stadium operator taxes, grocery retailers, and new lessees in newly constructed buildings.',
    why: 'Incentivizes occupying new construction, promotes stadium use and grocery retail. ',
  },
];
import React from 'react';

export const board = [
  {
    name: 'Marjan Philhour',
    title: 'Board of Supervisors, District 1',
  },
    {
    name: 'Danny Sauter',
    title: 'Board of Supervisors, District 3',
  },
  {
    name: 'Vallie Brown',
    title: 'Board of Supervisors, District 5',
  },
  {
    name: 'Myrna Melgar/Joel Engardio/Emily Murase',
    title: 'Board of Supervisors, District 7',
  },
  {
    name: 'No Position',
    title: 'Board of Supervisors, District 9',
  },
  {
    name: 'Ahsha Safaí',
    title: 'Board of Supervisors, District 11',
  },
];

export const sfProps = [
  {
    name: '✅ Yes on Prop A',
    title: 'Health and Homelessness, Parks, and Streets Bond',
  },
  {
    name: '❌ No on Prop B',
    title: 'Department of Sanitation and Streets, Sanitation and Streets Commission, and Public Works Commission',
  },
  {
    name: '✅ Yes on Prop C',
    title: 'Removing Citizenship Requirements for Members of City Bodies',
  },
  {
    name: '🤷🏾‍♀️ No position on Prop D',
    title: 'Sheriff Oversight',
  },
  {
    name: '✅ Yes on Prop E',
    title: 'Police Staffing',
  },
  {
    name: '✅ Yes on Prop F',
    title: 'Business Tax Overhaul',
  },
  {
    name: '✅ Yes on Prop G',
    title: 'Youth Voting in Local Elections',
  },
  {
    name: '✅ Yes on Prop H',
    title: 'Neighborhood Commercial Districts and City Permitting',
  },
  {
    name: '❌ No on Prop I',
    title: 'Real Estate Transfer Tax',
  },
  {
    name: '✅ Yes on Prop J',
    title: 'Parcel Tax for San Francisco Unified School District',
  },
  {
    name: '✅ Yes on Prop K',
    title: 'Affordable Housing Authorization',
  },
  {
    name: '❌ No on Prop L',
    title: 'Business Tax Based on Comparison of Top Executive’s Pay to Employees’ Pay',
  },
  {
    name: '✅ Yes on Measure RR',
    title: 'Caltrain Sales Tax',
  },
];

export const bart = [
  {
    name: 'Lateefah Simon',
    title: 'Bart Board District 7',
  },
  {
    name: 'Bevan Dufty',
    title: 'Bart Board District 9',
  },
]

export const boe = [
  {
    name: 'Matt Alexander',
  },
  {
    name: 'Alida Fisher',
  },
  {
    name: 'Jenny Lam',
  },
  {
    name: 'Michelle Parker',
  },
];

export const trustees = [
  {
    name: 'Aliya Chisti',
  },
  {
    name: 'Victor Olivieri',
  },
  {
    name: 'Jeanette Quick',
  },
  {
    name: 'Tom Temprano',
  },
];

export const measures = [
  { name: '✅ Yes on Prop 14', title: 'Borrowing for Stem Cell Research' },
  { name: '✅ Yes on Prop 15', title: 'Schools and Communities First (Split roll)' },
  { name: '✅ Yes on Prop 16', title: 'Repeals Prop 209, ending the ban on affirmative action' },
  {
    name: '✅ Yes on Prop 17',
    title: 'Free the Vote, grants the right to vote to people on parole',
  },
  {
    name: '✅ Yes on Prop 18',
    title: 'Allows 17 year olds to vote, if they turn 18 by the general election',
  },
  { name: '✅ Yes on Prop 19', title: 'Property Tax Breaks and Wildfire Fund' },
  { name: '❌ No on Prop 20', title: 'Tougher on parole, property crimes' },
  { name: '✅ Yes on Prop 21', title: 'Rent & vacancy control' },
  { name: '🤷🏾‍♀️ No position on Prop 22', title: 'Uber/Lyft classification' },
  { name: '🤷🏾‍♀️ No position on Prop 23', title: 'Dialysis Regulation' },
  { name: '❌ No on Prop 24', title: 'Consumer Privacy Law' },
  { name: '✅ Yes on Prop 25', title: 'Upholds SB10 which ends cash bail  ' },
];

export const stateCandidates = [
  { name: 'Scott Wiener', title: 'CA State Senate, District 11' },
  { name: 'David Chiu', title: 'CA State Assembly, District 17' },
  { name: 'Phil Ting', title: 'CA State Assembly, District 19' },
];

export const federalCandidates = [
  { name: 'Joe Biden & Kamala Harris', title: 'United States President and Vice President' },
  { name: 'Nancy Pelosi', title: 'US Representative, District 12' },
  { name: 'Jackie Speier', title: 'US Representative, District 14' },
];
